import Snackbar from "@mui/material/Snackbar";
//import MuiAlert from '@material-ui/lab/Alert';
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AlertMessage = (props: any) => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [state, setState] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;

  useEffect(() => {
    if (props.open !== undefined) {
      setOpen(props.open);
      setSeverity(props.severity);
    }
  }, [props]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      autoHideDuration={3500}
      onClose={props.handleClose}
      TransitionComponent={Transition}
    >
      <Alert
        severity={props.severity}
        variant="filled"
        onClose={props.handleClose}
        elevation={1}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
