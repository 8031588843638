import React from 'react'
import Navbar from '../../front/NavbarI';
import Footer from '../../front/FooterI';
import flechai  from '../../../img/flechai.png';

export default function Solicitud7() {
    return (
        <div className="grey-background">
            <Navbar />
            <br />
            <br />
            <div className="d-block d-sm-block d-md-none p-0 m-0 mb-5">
                <div className="card card-monto">
                    <div className="container">
                        <h6 className="text-credit text-end p-2">
                            Monto solicitado:
                        </h6>
                        <h2 className="text-light-blue text-end"> $1,200</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                        <div className="card card-monto">
                            <div className="container">
                                <h6 className="text-credit text-end p-2">
                                    Monto solicitado:
                                </h6>
                                <h2 className="text-light-blue text-end"> $1,200</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="text-center">
                        <h3 className="sol1-tittle">Solicitud de préstamo</h3>
                        <p>Por favor, rellena los siguientes campos con tu información</p>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>

            {/* STEPS */}
            <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                <div className="d-flex justify-content-center mt-4 container">
                    <button className="circle-step-blue">
                        <h5 className="numbre-step">1</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
                    <button className="circle-step-blue">
                        <h5 className="numbre-step">2</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
                    <button className="circle-step-blue">
                        <h5 className="numbre-step">3</h5>
                    </button>
                </div>
            </div>
            <div className="d-block d-sm-block d-md-none p-0 m-0 ">
                <div className="d-flex justify-content-center mt-4 container">
                    <button className="circle-step-blue">
                        <h5 className="numbre-step">1</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaa</div>
                    <button className="circle-step-blue">
                        <h5 className="numbre-step">2</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaa</div>
                    <button className="circle-step-blue ">
                        <h5 className="numbre-step">3</h5>
                    </button>
                </div>
            </div>
            
            {/* CARDS */}
            <div className="d-flex justify-content-center mt-4 container">
                <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
                    <div className="container">
                        <div className="text-center">
                            <h3 className="text-strong-blue">Términos y condiciones <br />
                                de autorización de consulta</h3>
                        </div>
                        <div className="d-flex justify-content-center mt-5">
                            <div className="form-check">
                                <input className="form-check-input checkbox-terms" type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label text-check" htmlFor="flexCheckDefault">
                                    Acepto <a href='#' className="text-light-blue">Términos y Condiciones</a> y cláusula de medios electrónicos tales como NIP.
                                </label>
                            </div>
                        </div>
                        <div className="container mt-5">
                            <h5 className="text-strong-blue">Autorización para solicitar reportes de crédito</h5>
                            <p>Autorizo expresamente a PROBENSO GROUP S.A. S.O.F.O.M. E.N.R, para que lleve a cabo investigación sobre mi comportamiento crediticio en las sociedades de información crediticia (SIC) que estime conveniente. Conozco la naturaleza y alcance de la información que se solicitará, del uso que se le dará y que se podrán realizar consultas periódicas de mi historial crediticio.
                                <br />
                                <br />
                                Consiento que esta autorización tenga una vigencia de 3 años contando a partir de hoy, y en su caso mientras mantengamos relación jurídica. Acepto que este documento quede bajo propiedad de PROBENSO GROUP S.A. S.O.F.O.M. E.N.R, para efectos de control y cumplimiento del articulo 28 de la LRSIC.</p>
                        </div>
                        <form>
                            <hr className="mt-4 mb-4" />
                            <div className="text-center mt-5">
                                <p>Te enviamos un código NIP vía SMS</p>
                                <p className="sol1-tittle">Código NIP*</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="p-2">
                                    <input type="text" className="form-control input-number " id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                                <div className="p-2">
                                    <input type="text" className="form-control input-number" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                                <div className="p-2">
                                    <input type="text" className="form-control input-number" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                                <div className="p-2">
                                    <input type="text" className="form-control input-number" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                                <div className="p-2">
                                    <input type="text" className="form-control input-number" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                                <div className="p-2">
                                    <input type="text" className="form-control input-number" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-4">
                                </div>
                                <div className="col-4 text-end">
                                    <a href="#" className="link-secondary link-tittle">Reenviar el código</a>
                                </div>
                                <div className="col-4">
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-5">
                                <a href='/solicitud/personal/8' className="btn btn-shadow-3">
                                    Autorizo
                                </a>
                            </div>
                        </form>
                        <div className="d-flex justify-content-start mt-5 mb-4">
                            <a href='/solicitud/personal/6' className="btn btn-shadow-3">
                            <img src={flechai} alt="PRADO'S" className="img-fluid mrirht" width="10" />
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
