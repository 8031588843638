import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider }         from "react-redux";
import { Store, persistor } from "./store";
import { PersistGate }      from "redux-persist/integration/react";
import reportWebVitals from './reportWebVitals';

const LoaderGate = () => <p></p>;
ReactDOM.render(
  <React.StrictMode>
     <Provider store={Store}>
      <PersistGate loading={<LoaderGate />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
