import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ApiClient, { Prospective } from "../../../services/apiclient";
import Navbar from "../../front/NavbarI";
import Footer from "../../front/FooterI";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import * as Yup from "yup";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import AlertMessage from "../../alert.component";
import { UserContext } from "./../../../context/UserProvider";
import { motion } from "framer-motion";
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ValidacionPersonal() {
  const query = useQuery();
  const history = useHistory();
  const apiClient = ApiClient.getInstance();
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [date, setDate] = useState(null);
  const [shown, setShown] = React.useState(false);
  const [phoneChange, setPhoneChange] = React.useState(false);
  const [newPhone, setNewPhone] = React.useState("");
  const [sendCode, setSendCode] = React.useState({
    phone: "",
    send: false,
  });
  const [fromLogin, setFromLogin] = React.useState(false);
  const [login, setLogin] = React.useState(false);
  const { userMain, updateUser } = React.useContext(UserContext);
  const [phoneStatus, setPhoneStatus] = React.useState(true);

  const [ssnValues, setValue] = React.useState({
    val1: "",
    val2: "",
    val3: "",
    val4: "",
    val5: "",
    val6: "",
  });

  function handleChangeDate(event: any) {
    console.log(event.target.value);
    let date = event.target.value.split("/");
    formik.setFieldValue(
      "dateOfBirth",
      date[2] + "-" + date[0] + "-" + date[1]
    );
  }
  function handleChangePhone(event: any) {
    formik.setFieldValue("phone", event.target.value.toString());
  }
  function handleChangePhoneBlur(event: any) {
    if (!formik.errors.phone) {
      apiClient.validatePhone(formik.values.phone).then((data) => {
        if (data.error) {
          setOpenMessage(true);
          setMessage(
            "Lo sentimos, el telefono ingresado ya se encuentra registrado, si ya tienes una cuenta, inicia sesión"
          );
          setSeverity("error");
          return;
        }
      });
    }
  }
  function changePhone(event: any) {
    setPhoneChange(false);
    //sendMessage sms
  }
  function handleClosePhone() {
    formik.setFieldValue("phone", sendCode.phone);
    setPhoneChange(false);
  }
  const handleBlur = (e) => console.log(e.target.value);

  function leftPad(str: string, len: number, ch = "0"): string {
    len = len - str.length + 1;
    return len > 0 ? new Array(len).join(ch) + str : str;
  }
  function handleChangeCheck(event: any) {
    formik.setFieldValue("attached1", !formik.values.attached1);
    const nextSibling = document.querySelector(
      "#attached2"
    ) as HTMLElement | null;
    nextSibling.focus();
  }
  function handleChangeCheck2(event: any) {
    formik.setFieldValue("attached2", !formik.values.attached2);
    const nextSibling = document.querySelector(
      "#attached3"
    ) as HTMLElement | null;
    nextSibling.focus();
  }
  function validateSendCode() {}

  function handleBlurCheck() {
    console.log(formik.values.attached1, formik.values.attached2);
    if (!formik.errors.attached1 && !formik.errors.attached2) {
    }
  }
  function handleClickCheck() {
    console.log(formik.values.attached1, formik.values.attached2);
  }
  function handleBlurPassword(event: any) {
   
    if (!formik.errors.confirmPassword) {
      apiClient
        .signup(formik.values.phone, formik.values.password, "personal")
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          localStorage.setItem("cxdc", formik.values.phone);
          localStorage.setItem("fwcd", formik.values.password);
          if (data.error) {
            setIsLoading(false);
            //localStorage.clear();
            setOpenMessage(true);
            setMessage(
              data.status >= 500 ? "Error del servidor" : data.error.message
            );
            setSeverity("error");
            setLogin(false);

            return;
          }
          setLogin(true);
          setPhoneStatus(false);
          localStorage.setItem("userId", data.user);
        })
        .catch((err) => {
          setIsLoading(false);
          setOpenMessage(true);
          setMessage("Error en el servidor");
          setSeverity("error");
        });
    }
  }

  const formik = useFormik({
    initialValues: {
      firstName: "",
      secondName: "",
      lastFName: "",
      lastMName: "",
      dateOfBirth: "",
      state: "",
      gender: "",
      email: "",
      phone: "",
      attached1: false,
      attached2: false,
      attached3: false,
      password: "",
      confirmPassword: "",
      val1: "",
      val2: "",
      val3: "",
      val4: "",
      val5: "",
      val6: "",
      userId: localStorage.getItem("prspect"),
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Tienes que ingresar la calle."),
      lastFName: Yup.string().required("Tienes que ingresar la calle."),
      lastMName: Yup.string().required("Tienes que ingresar la calle."),
      dateOfBirth: Yup.string().required("Tienes que ingresar la calle."),
      //state: Yup.string().required("Tienes que ingresar la calle."),
      email: Yup.string().required("Tienes que ingresar la calle."),
      phone: Yup.string()
        .required("Tienes que ingresar la calle.")
        .matches(
          /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/
        ),
      attached1: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "Field must be checked"),
      attached2: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "Field must be checked"),
      password: Yup.string().required("Tienes que ingresar la calle."),
      confirmPassword: Yup.string()
        .required("Please confirm your password")
        .oneOf([Yup.ref("password"), null], "Passwords don't match."),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      let code =
        formik.values.val1 +
        formik.values.val2 +
        formik.values.val3 +
        formik.values.val4 +
        formik.values.val5 +
        formik.values.val6;
      apiClient
        .activateAccountSMS(code)
        .then((data) => {
          setOpenMessage(true);
          setMessage("Tu cuenta ha sido activada");
          console.log(
            localStorage.getItem("cxdc"),
            localStorage.getItem("fwcd")
          );
          if (localStorage.getItem("cxdc") && localStorage.getItem("fwcd")) {
            console.log(data);
            apiClient
              .login(localStorage.getItem("cxdc"), localStorage.getItem("fwcd"))
              .then((res) => res.json())
              .then(async (a) => {
                localStorage.removeItem("cxdc");
                localStorage.removeItem("fwcd");
                if (a.error) {
                  localStorage.clear();
                  setOpenMessage(true);
                  const statusCode = a.error?.status || a.error.statusCode;
                  setMessage(
                    statusCode === 401
                      ? "Telefono o contraseña invalidos"
                      : a.error.message
                  );
                  setSeverity("error");

                  // if(statusCode === 403)
                  //   return history.push("/step2");
                  //   else return history.push("/login");
                }

                localStorage.setItem("TOKEN", a.token);
                localStorage.setItem("USER_ID", a.userId);

                apiClient.token = a.token;
                apiClient.userId = a.userId;
                if (a.fullName) {
                  updateUser({
                    ...userMain,
                    nombre: a.fullName || a.fullName,
                    telefono: a.phone,
                  });
                } else {
                  updateUser({
                    ...userMain,
                    telefono: a.phone,
                  });
                }
                try {
                  await apiClient.termsConditions({
                    publicidad: formik.values.attached3,
                  });
                } catch (error) {
                  setIsLoading(false);
                  setOpenMessage(true);
                  setMessage("Ha ocurrido un error al guardar los datos.");
                  setSeverity("error");
                  return;
                }
                const data = {
                  firstName: formik.values.firstName,
                  secondName: formik.values.secondName,
                  lastFName: formik.values.lastFName,
                  lastMName: formik.values.lastMName,
                  birthDate: formik.values.dateOfBirth,
                  email: formik.values.email,
                  sex: formik.values.gender,
                };
                apiClient
                  .createUserDetails(data)
                  .then((res) => res.json())
                  .then((data) => {
                    if (data.error) {
                      setOpenMessage(true);
                      setMessage(data.error.message);
                      setSeverity("error");
                    }
                    const fullName = `${formik.values.firstName} ${formik.values.secondName} ${formik.values.lastFName} ${formik.values.lastMName}`;
                    updateUser({
                      ...userMain,
                      nombre: fullName,
                    });
                    return history.push("/prestamo-personal/?from=init");
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    setOpenMessage(true);
                    setMessage(
                      "Favor de verificar que la informacion sea correcta."
                    );
                    setSeverity("error");
                  });
              });
          }
          //return history.push("/login");
        })
        .catch((reason) => {
          setOpenMessage(true);
          setMessage(reason.message);
          setSeverity("error");
        });
    },
  });
  const handleClose = () => {
    setOpenMessage(false);
  };
  function handleChangeCode(e) {
    console.log(e.target);
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 6) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=val-${parseInt(fieldIndex, 10) + 1}]`
        ) as HTMLElement | null;

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      } else if (parseInt(fieldIndex, 10) === 6) {
        formik.handleSubmit();
      }
    }
    formik.setFieldValue([`val${fieldIndex}`].toString(), value);
  }

  const checkFromRoute = async () => {
    if (localStorage.getItem("userLocal")) {
      var userLocal = JSON.parse(localStorage.getItem("userLocal"));
      setAmount(parseInt(userLocal.preAmount));
    }
    const from = query.get("from");
    if (from === "Login") {
      formik.setFieldValue("phone", localStorage.getItem("cxdc"));
      formik.setFieldValue("password", localStorage.getItem("fwcd"));
      formik.setFieldValue("confirmPassword", localStorage.getItem("fwcd"));
      setFromLogin(true);
      setPhoneStatus(false);
    }
  };
  useEffect(() => {
    checkFromRoute();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
      className="grey-background"
    >
      <Navbar />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <br />
      <br />
      <div className="d-block d-sm-block d-md-none p-0 m-0 mb-5">
        <div className="card card-monto">
          <div className="container">
            <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
            <h2 className="text-light-blue text-end">
              {" "}
              {amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div className="d-none d-sm-none d-md-block p-0 m-0 ">
            <div className="card card-monto">
              <div className="container">
                <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
                <h2 className="text-light-blue text-end">
                  {" "}
                  {amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="text-center">
            <h3 className="sol1-tittle">Solicitud de préstamo</h3>
            <p>Por favor, rellena los siguientes campos con tu información</p>
          </div>
        </div>
        <div className="col-3"></div>
      </div>

      {/* STEPS */}
      <div className="d-none d-sm-none d-md-block p-0 m-0 ">
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step-blue">
            <h5 className="numbre-step">1</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </div>
      <div className="d-block d-sm-block d-md-none p-0 m-0 ">
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step">
            <h5 className="numbre-step">1</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </div>
      {/* CARDS */}

      <div className="d-flex justify-content-center mt-4 container">
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <div className="container">
            {fromLogin === false ? (
              <div className="row">
                <div className="col-6 col-sm-6">
                  <h6 className="text-grey">
                    Antes de continuar, vamos a validar tu número de teléfono.{" "}
                    <br />
                    <br /> <br />{" "}
                  </h6>
                </div>
                <div className="col-6 col-sm-6">
                  <label htmlFor="phone" className="form-label sol1-tittle">
                    Número de celular *
                  </label>
                  <input
                    type="number"
                    className={
                      formik.errors.phone
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="phone"
                    aria-describedby="emailHelp"
                    onChange={handleChangePhone}
                    value={formik.values.phone}
                    onBlur={handleChangePhoneBlur}
                  />
                  {formik.errors.phone && (
                    <div className="invalid-feedback">
                      Ingresa un teléfono válido
                    </div>
                  )}
                </div>
                <div className="col-6 col-sm-6">
                  <label htmlFor="password" className="form-label sol1-tittle">
                    Contraseña *
                  </label>
                  <input
                    type={shown ? "text" : "password"}
                    className={
                      formik.errors.password
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="password"
                    aria-describedby="emailHelp"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    autoComplete="false"
                  />
                </div>

                <div className="col-6 col-sm-6">
                  <label
                    htmlFor="confirmPassword"
                    className="form-label sol1-tittle"
                  >
                    Confirma contraseña *
                  </label>
                  <input
                    type={shown ? "text" : "password"}
                    className={
                      formik.errors.confirmPassword
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="confirmPassword"
                    aria-describedby="emailHelp"
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                    onBlur={handleBlurPassword}
                  />
                  {formik.errors.confirmPassword &&
                    formik.values.confirmPassword.length >=
                      formik.values.password.length &&
                    formik.values.password.length > 0 && (
                      <div className="invalid-feedback">
                        Las contraseñas no coinciden
                      </div>
                    )}
                  <a href="/login">
                    <span className="pt-0 rBlack">¿Ya tienes una cuenta?</span>
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}

            <hr className="mt-4 mb-4" />
            <p className="txt-blue-strong">
              Rellena los campos vacíos con tus datos
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="firstName"
                      className="form-label sol1-tittle"
                    >
                      Nombre (s) *
                    </label>
                    <input
                      type="input"
                      id="firstName"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.firstName
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled={phoneStatus}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="lastMName"
                      className="form-label sol1-tittle"
                    >
                      Apellido materno *
                    </label>
                    <input
                      type="input"
                      className={
                        formik.errors.lastMName
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="lastMName"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.lastMName}
                      onBlur={formik.handleBlur}
                      disabled={phoneStatus}
                    />
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="secondName"
                      className="form-label sol1-tittle"
                    >
                      Segundo nombre *
                    </label>
                    <input
                      type="input"
                      className="form-control"
                      id="secondName"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.secondName}
                      onBlur={formik.handleBlur}
                      disabled={phoneStatus}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="lastFName"
                      className="form-label sol1-tittle"
                    >
                      Apellido paterno *
                    </label>
                    <input
                      type="input"
                      className={
                        formik.errors.lastFName
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="lastFName"
                      aria-describedby="emailHelp" 
                      onChange={formik.handleChange}
                      value={formik.values.lastFName}
                      onBlur={formik.handleBlur}
                      disabled={phoneStatus}
                    />
                  </div>
                </div>
              </div>
              <hr className="mt-4 mb-4" />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="birthDate"
                      className="form-label sol1-tittle"
                    >
                      Fecha de nacimiento *
                    </label>
                    <input
                      type="date"
                      className={
                        formik.errors.dateOfBirth && formik.touched.dateOfBirth
                          ? "form-control  is-invalid"
                          : "form-control"
                      }
                      id="dateOfBirth"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.dateOfBirth}
                      onBlur={formik.handleBlur}
                      disabled
                    />

                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <DatePicker
                          disableFuture
                          //label="Fecha nacimiento"
                          openTo="day"
                          toolbarPlaceholder="dff"
                          toolbarFormat="//"
                          views={["month", "day", "year"]}
                          value={date}
                          onChange={(newValue) => {
                            setDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="medium"
                              color="primary"
                              name="dateOfBirth"
                              onChange={handleChangeDate}
                              value={formik.values.dateOfBirth}
                              onBlur={handleBlur}
                              disabled={phoneStatus}
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider> */}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label sol1-tittle">
                      Correo electrónico *
                    </label>
                    <input
                      type="email"
                      className={
                        formik.errors.email
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="email"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      disabled={phoneStatus}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="gender" className="form-label sol1-tittle">
                      Género *
                    </label>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className={
                          formik.errors.gender
                            ? " form-check-input is-invalid"
                            : "form-check-input"
                        }
                        type="radio"
                        name="gender"
                        id="gender"
                        value="femenino"
                        checked={formik.values.gender === "femenino"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={phoneStatus}
                      />
                      <label className="form-check-label" htmlFor="gender">
                        Mujer
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className={
                          formik.errors.gender
                            ? " form-check-input is-invalid"
                            : "form-check-input"
                        }
                        type="radio"
                        name="gender"
                        id="gender"
                        value="masculino"
                        checked={formik.values.gender === "masculino"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={phoneStatus}
                      />
                      <label className="form-check-label" htmlFor="gender">
                        Hombre
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-4 mb-4" />
              <div className="row">
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label sol1-tittle">Número de celular *</label>
                                        <input 
                                            type="number" 
                                            className={formik.errors.phone ? "form-control  is-invalid" : "form-control"}
                                            id="phone" 
                                            aria-describedby="emailHelp"
                                            onChange={ handleChangePhone}
                                            value={formik.values.phone}
                                            onBlur={handleChangePhoneBlur}
                                            />
                                            {formik.errors.phone && (
                                                <div className="invalid-feedback">
                                                Ingresa un teléfono válido
                                            </div>
                                            )}
                                            
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label sol1-tittle">Contraseña *</label>
                                        <input 
                                            type={shown ? "text" : "password"}
                                            className={formik.errors.password ? "form-control  is-invalid" : "form-control"}
                                            id="password" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            onBlur={formik.handleBlur}
                                            autoComplete="false"
                                            />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="confirmPassword" className="form-label sol1-tittle">Confirma contraseña *</label>
                                        <input 
                                            type={shown ? "text" : "password"}
                                            className={formik.errors.confirmPassword ? "form-control  is-invalid" : "form-control"}
                                            id="confirmPassword" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.confirmPassword}
                                            onBlur={handleBlurPassword}
                                            />
                                            {formik.errors.confirmPassword && formik.values.confirmPassword.length   >=  formik.values.password.length  && (formik.values.password.length > 0) && (
                                                <div className="invalid-feedback">
                                                Las contraseñas no coinciden
                                            </div>
                                            )}
                                    </div>
                                </div> */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="container">
                    <h6 className="text-grey">
                      Antes de continuar, debemos validar tu número. <br />
                      Da click aquí una vez que hayas puesto tu celular.{" "}
                    </h6>
                    <div className="form-check">
                      <input
                        className={
                          formik.errors.attached1
                            ? "form-check-input checkbox-terms  is-invalid"
                            : "form-check-input checkbox-terms"
                        }
                        type="checkbox"
                        //value=""
                        id="attached1"
                        //checked={formik.values.attached1}
                        onChange={formik.handleChange}
                        disabled={
                          formik.errors.password ||
                          formik.errors.confirmPassword
                            ? true
                            : false
                        }
                        onBlur={formik.handleBlur}
                      />
                      <label
                        className="form-check-label text-check"
                        htmlFor="attached1"
                      >
                        Acepto el Aviso de Privacidad
                      </label>
                      {formik.errors.attached1 &&
                        !formik.errors.confirmPassword && (
                          <div className="invalid-feedback">
                            Debes aceptar el aviso de privacidad
                          </div>
                        )}
                    </div>
                    <div className="form-check">
                      <input
                        className={
                          formik.errors.attached2
                            ? "form-check-input checkbox-terms  is-invalid"
                            : "form-check-input checkbox-terms"
                        }
                        type="checkbox"
                        //value=""
                        id="attached2"
                        //checked={formik.values.attached2}
                        onChange={formik.handleChange}
                        disabled={
                          formik.errors.password ||
                          formik.errors.confirmPassword
                            ? true
                            : false
                        }
                        onBlur={formik.handleBlur}
                      />

                      <label
                        className="form-check-label text-check"
                        htmlFor="attached2"
                      >
                        Acepto las obligaciones del Contrato de licencia
                      </label>
                      {formik.errors.attached2 &&
                        !formik.errors.confirmPassword && (
                          <div className="invalid-feedback">
                            Debes aceptar el contrato de licencia
                          </div>
                        )}
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input checkbox-terms"
                        type="checkbox"
                        value=""
                        id="attached3"
                        checked={formik.values.attached3}
                        onChange={formik.handleChange}
                        disabled={
                          formik.errors.password ||
                          formik.errors.confirmPassword
                            ? true
                            : false
                        }
                        onBlur={formik.handleBlur}
                      />
                      <label
                        className="form-check-label text-check"
                        htmlFor="flexCheckDefault"
                      >
                        Acepto recibir noticias, promociones e información
                        acerca de mi cuenta
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-5">
                <p>
                  Por favor, ingresa el código que te enviamos a tu celular:
                </p>
                <p className="sol1-tittle">Código</p>
                <p className="sol1-tittle">{Object.keys(formik.errors)}</p>
              </div>
              <div className="d-flex justify-content-center">
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number "
                    id="val-1"
                    aria-describedby="emailHelp"
                    name="val-1"
                    onChange={handleChangeCode}
                    maxLength={1}
                    value={
                      Object.keys(formik.errors).length > 0
                        ? ""
                        : formik.values.val1
                    }
                    disabled={
                      Object.keys(formik.errors).length > 0 ? true : false
                    }
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-2"
                    aria-describedby="emailHelp"
                    name="val-2"
                    onChange={handleChangeCode}
                    maxLength={1}
                    value={
                      Object.keys(formik.errors).length > 0
                        ? ""
                        : formik.values.val2
                    }
                    disabled={
                      Object.keys(formik.errors).length > 0 ? true : false
                    }
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-3"
                    aria-describedby="emailHelp"
                    name="val-3"
                    onChange={handleChangeCode}
                    maxLength={1}
                    value={
                      Object.keys(formik.errors).length > 0
                        ? ""
                        : formik.values.val3
                    }
                    disabled={
                      Object.keys(formik.errors).length > 0 ? true : false
                    }
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-4"
                    aria-describedby="emailHelp"
                    name="val-4"
                    onChange={handleChangeCode}
                    maxLength={1}
                    value={
                      Object.keys(formik.errors).length > 0
                        ? ""
                        : formik.values.val4
                    }
                    disabled={
                      Object.keys(formik.errors).length > 0 ? true : false
                    }
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-5"
                    aria-describedby="emailHelp"
                    name="val-5"
                    onChange={handleChangeCode}
                    maxLength={1}
                    value={
                      Object.keys(formik.errors).length > 0
                        ? ""
                        : formik.values.val5
                    }
                    disabled={
                      Object.keys(formik.errors).length > 0 ? true : false
                    }
                  />
                </div>
                <div className="p-2">
                  <input
                    type="string"
                    className="form-control input-number"
                    id="val-6"
                    aria-describedby="emailHelp"
                    name="val-6"
                    onChange={handleChangeCode}
                    maxLength={1}
                    value={
                      Object.keys(formik.errors).length > 0
                        ? ""
                        : formik.values.val6
                    }
                    disabled={
                      Object.keys(formik.errors).length > 0 ? true : false
                    }
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 text-center">
                  <a href="#" className="link-secondary link-tittle">
                    Reenviar el código
                  </a>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-5"></div>
            </form>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Dialog
        open={phoneChange}
        //onClose={handleClosePhone}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Seguro que deseas cambiar el número de teléfono?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Al cambiar el número de teléfono, te enviaremos un nuevo sms con un
            código para validar tu identidad
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePhone}>Cancelar</Button>
          <Button onClick={changePhone} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </motion.div>
  );
}
