import React, { useEffect, useState } from "react";
import AlertMessage from "../../../alert.component";
import ApiClient from "../../../../services/apiclient";
import { Popup, Position, ToolbarItem } from "devextreme-react/ui/popup";
import notify from "devextreme/ui/notify";
import ScrollView from "devextreme-react/scroll-view";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import actividadesEconomicas from "../../../credit-forms/utils/actividadesEconomicas";
import esfera_logo from "../../../../img/esfera_percapita.png";
import flechad from "../../../../img/flechaD.png";
import logo from "../../../../img/1.png";
import logo2 from "../../../../img/2.png";
import logo3 from "../../../../img/3.png";
import logo4 from "../../../../img/4.png";
import "../informacion/informacion.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import logo_balnco from "../../../../img/logo_blanco.png";
import { PreTramiteCreditoContext } from "../../../../context/PreTramiteCreditoProvider";
import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";
import { motion } from "framer-motion";
import {validateString} from "../../../../utils/StringValidator"

export default function DatosLaborales({
  userData,
  setUserData,
  nextStep,
  setCurrentPage,
  productName,
  prevStep,
}) {
  const [openMessage, setOpenMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const apiClient = ApiClient.getInstance();
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  function screen(width) {
    return width < 700 ? "sm" : "lg";
  }

  const onsubmitForm = (values: any, actions: any) => {
    setLoading(true);

    if (!values.antiquityMonth) {
      values.antiquityMonth = 0;
    }

    setUserData(values);
    console.log(values);
    apiClient
      .createPABSloan(values)
      .then((data) => {
        apiClient
          .setProductName(productName)
          .then((data) => {
            console.log(data);
            nextStep();
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => {
        console.log("error", err);
        if (err === "Sin permiso") {
          // setOpenMessage(true);
          // setMessage('Se necesita activar el permiso de Geolocalizacion para poder continuar.');
          // setSeverity('error');
          setPopupVisibility(true);
        } else {
          setOpenMessage(true);
          setMessage(
            "No se puede continuar al siguiente paso, verifique la informacion."
          );
          setSeverity("error");
        }
        /*setUserData({...userData, address: {
        street: "",
        interiorNumber: "",
        exteriorNumber: "",
        suburb: "",
        postalCode: "",
        city: "",
        state: "",
        country: "",
        homePhone: "",
        antiquity: 0,
        propertyType: ""
      }})*/
        setLoading(false);
        /*setTimeout(
        () => setCurrentPage(1),
        4000
    );*/
      });
  };

  const validationSchema = Yup.object().shape({
    laborData: Yup.object({
      companyName: Yup.string().required("Requerido"),
      salary: Yup.number().required("Requerido"),
      jobPosition: Yup.string().required("Requerido"),
      antiquity: Yup.number().nullable().required("Requerido"),
      antiquityMonth: Yup.number().nullable().required("Requerido"),
      economicActivity: Yup.string().required("Requerido"),
      phone: Yup.string().required("Requerido"),
    }),
  });
  useEffect(() => {
    if (localStorage.getItem("userLocal")) {
      var userLocal = JSON.parse(localStorage.getItem("userLocal"));
      setAmount(parseInt(userLocal.preAmount));
    }
  }, []);
  const handleClose = () => {
    setOpenMessage(false);
  };
  const [isPopupVisible, setPopupVisibility] = useState(false);

  const {
    values,
    errors,
    touched,
    isSubmitting,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      companyName: "",
      salary: 0,
      jobPosition: "",
      antiquity: "0",
      antiquityMonth: "0",
      economicActivity: "",
      phone: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("Requerido"),
      salary: Yup.number().required("Requerido"),
      jobPosition: Yup.string().required("Requerido"),
      antiquity: Yup.number().nullable().required("Requerido"),
      antiquityMonth: Yup.number().nullable().required("Requerido"),
      economicActivity: Yup.string().required("Requerido"),
      phone: Yup.string().required("Requerido"),
    }),

    onSubmit: (values) => {
      setIsLoading(true);
      if (!values.antiquityMonth) {
        values.antiquityMonth = "0";
      }
      let labor = {
        companyName: values.companyName,
        salary: values.salary,
        jobPosition: values.jobPosition,
        antiquity: parseInt(values.antiquity),
        antiquityMonth: parseInt(values.antiquityMonth),
        economicActivity: values.economicActivity,
        phone: values.phone,
      };
      userData.laborData = labor;
      setUserData(userData);
      apiClient
        .createPABSloan(userData)
        .then((data) => {
          apiClient
            .setProductName(productName)
            .then((data) => {
              console.log(data);
              if (localStorage.getItem("preCreditoLocal")) {
                var credito = JSON.parse(
                  localStorage.getItem("preCreditoLocal")
                );
                if (
                  credito.idProcessMarketing !== "" &&
                  credito.typeLoanMarketing === "Titulo"
                ) {
                  var dd = {
                    processName: "labor-form-finished",
                  };
                  updatePreCredito({
                    ...preCredito,
                    stepMarketing: "labor-form-finished",
                  });
                  updateMarketing(credito.idProcessMarketing, dd);
                }
              }
              nextStep();
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => {
          console.log("error", err);
          setIsLoading(false);
          if (err === "Sin permiso") {
            setPopupVisibility(true);
          } else {
            setOpenMessage(true);
            setMessage(
              "No se puede continuar al siguiente paso, verifique la informacion."
            );
            setSeverity("error");
          }
          setLoading(false);
        });
    },
  });

  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };

  useEffect(() => {
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "labor-form-init" &&
        credito.typeLoanMarketing === "Titulo"
      )
        var data = {
          processName: "labor-form-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "labor-form-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);
  useEffect(()=> {
    const preinfo = async()=> {
      await apiClient.getUserPreInfo().then((data) => {
        if (!data.error) {
            setFieldValue("companyName", data.companyName);
            setFieldValue("salary", data.salary);
            setFieldValue("jobPosition", data.jobPosition);
            setFieldValue("phone", data.homePhone);
            setFieldValue("antiquity", data.antiquity);
            setFieldValue("economicActivity", data.economicActivity);
        }
      });
    }
    preinfo();
  }, [])
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <div className="d-flex justify-content-center">
        <ResponsiveBox singleColumnScreen="sm" screenByWidth={screen}>
          <Popup
            width={800}
            height={400}
            visible={isPopupVisible}
            showTitle={false}
          >
            <ScrollView width="100%" height="100%">
              <div className="text-center">
                <h5>Para poder continuar necesitas permitir el acceso</h5>
                <h5> a la Geolocalizacion</h5>
                <h5>Sigue los siguientes pasos para poder continuar.</h5>
                <p>Dirigete en la parte superior izquierda de tu navegador. </p>
                <img style={{ width: 320 }} src={logo} />
                <br></br>
                <p>Haz click sobre el candado(informacion de la pagina). </p>
                <img style={{ width: 320 }} src={logo2} />
                <br></br>
                <p>Ubicate en la seccion de "Ubicacion". </p>
                <br></br>
                <img style={{ width: 320 }} src={logo3} />

                <br></br>
                <p>Y selecciona la opcion de "Permitir". </p>
                <br></br>
                <img style={{ width: 320 }} src={logo4} />

                <br></br>
                <p>Actualiza la pagina para guadar cambios. </p>

                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={() => setPopupVisibility(false)}
                  disabled={loading}
                >
                  Cerrar
                </button>
              </div>
            </ScrollView>
          </Popup>
        </ResponsiveBox>
      </div>

      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div className="grey-background">
        <motion.div
          initial={{ y: "50%", opacity: 0, scale: 0.5 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: "50%", transition: { duration: 0.8 } }}
          transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
          className="d-block d-sm-block d-md-none p-0 m-0 mb-5"
        >
          <div className="card card-monto">
            <div className="container">
              <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
              <h2 className="text-light-blue text-end">
                {" "}
                {amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h2>
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={{ y: "50%", opacity: 0, scale: 0.5 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: "50%", transition: { duration: 0.8 } }}
          transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
          className="row"
        >
          <div className="col-3">
            <div className="d-none d-sm-none d-md-block p-0 m-0 ">
              <div className="card card-monto">
                <div className="container">
                  <h6 className="text-credit text-end p-2">
                    Monto solicitado:
                  </h6>
                  <h2 className="text-light-blue text-end">
                    {" "}
                    {amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="text-center">
              <h3 className="sol1-tittle">Solicitud de préstamo</h3>
              <p>Información extra</p>
            </div>
          </div>
          <div className="col-3"></div>
        </motion.div>
        {/* STEPS */}
        <motion.div
          initial={{ y: "50%", opacity: 0, scale: 0.5 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: "50%", transition: { duration: 0.8 } }}
          transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
          className="d-flex justify-content-center"
        >
          <img
            src={esfera_logo}
            alt="PRADO'S"
            className="img-fluid"
            width="60"
          />
        </motion.div>
        <motion.div
          initial={{ y: "50%", opacity: 0, scale: 0.5 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: "50%", transition: { duration: 0.8 } }}
          transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
          className="text-center mt-3    "
        >
          <h6 className="txt-blue-strong2">DATOS LABORALES</h6>
        </motion.div>
        {/* CARDS */}
        <motion.div
          initial={{ y: "50%", opacity: 0, scale: 0.5 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: "50%", transition: { duration: 0.8 } }}
          transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
          className="d-flex justify-content-center mt-4 container"
        >
          <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
            <div className="container">
              <p className="txt-blue-strong">
                Por favor, completa la siguiente información
              </p>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="companyName"
                        className="form-label sol1-tittle"
                      >
                        Nombre la compañía en la que trabajas
                      </label>
                      <input
                        type="input"
                        className={
                          errors.companyName
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        id="companyName"
                        value={values.companyName}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue(
                            "companyName",
                            validateString(value.toUpperCase())
                          );
                        }}
                        onBlur={handleBlur}
                        aria-describedby="emailHelp"
                      />
                      {errors.companyName && touched.companyName && (
                        <div className="invalid-feedback">
                          Nombre compania es requerido
                        </div>
                      )}
                    </div>

                    <div className="mb-3">
                      <label
                        htmlFor="salary"
                        className="form-label sol1-tittle"
                      >
                        Sueldo mensual*
                      </label>
                      <input
                        type="number"
                        className={
                          errors.salary
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        id="salary"
                        value={values.salary}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        aria-describedby="emailHelp"
                      />
                      {errors.salary && touched.salary && (
                        <div className="invalid-feedback">
                          Salario es requerido
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="jobPosition"
                        className="form-label sol1-tittle"
                      >
                        Puesto actual de trabajo*{" "}
                      </label>
                      <input
                        type="input"
                        className={
                          errors.jobPosition && touched.jobPosition
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        id="jobPosition"
                        value={values.jobPosition}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue(
                            "jobPosition",
                            validateString(value.toUpperCase())
                          );
                        }}
                        onBlur={handleBlur}
                        aria-describedby="emailHelp"
                      />
                      {errors.jobPosition && touched.jobPosition && (
                        <div className="invalid-feedback">
                          Puesto es requerido
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="phone" className="form-label sol1-tittle">
                        Teléfono de contacto
                      </label>
                      <input
                        type="input"
                        className={
                          errors.phone && touched.phone
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        id="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        aria-describedby="emailHelp"
                      />
                      {errors.phone && touched.phone && (
                        <div className="invalid-feedback">
                          Teléfono es requerido
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label sol1-tittle"
                      >
                        ¿Cuánto tiempo llevas trabajando ahí?
                      </label>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                          <select
                            className={
                              errors.antiquity && touched.antiquity
                                ? "form-select slect-blue text-light is-invalid"
                                : "form-select slect-blue text-light"
                            }
                            aria-label="Default select example"
                            id="antiquity"
                            name="antiquity"
                            value={values.antiquity}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                      <option value="">Seleccione una opción</option>
                      <option value="0">Menos de 6 meses</option>
                      <option value="1">1 año</option>
                      <option value="2">2 años</option>
                      <option value="3">3 años</option>
                      <option value="4">4 años</option>
                      <option value="5">5 años</option>
                      <option value="6">
                        Mas de 6 años
                      </option>
                          </select>
                          {errors.antiquity && touched.antiquity && (
                            <div className="invalid-feedback">
                              Antiguedad es requerido
                            </div>
                          )}
                        </div>
                        {/* <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                          <select
                            className={
                              errors.antiquityMonth && touched.antiquityMonth
                                ? "form-select slect-blue text-light is-invalid"
                                : "form-select slect-blue text-light"
                            }
                            aria-label="Default select example"
                            id="antiquityMonth"
                            name="antiquityMonth"
                            value={values.antiquityMonth}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option selected disabled value="">
                              Mes
                            </option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                          </select>
                          {errors.antiquityMonth && touched.antiquityMonth && (
                            <div className="invalid-feedback">
                              Antiguedad es requerido
                            </div>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="mb-4">
                      <label
                        htmlFor="economicActivity"
                        className="form-label sol1-tittle"
                      >
                        Actividad económica
                      </label>
                      <br />
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <select
                          className={
                            errors.economicActivity && touched.economicActivity
                              ? "form-select slect-blue text-light is-invalid"
                              : "form-select slect-blue text-light"
                          }
                          aria-label="Default select example"
                          id="economicActivity"
                          name="economicActivity"
                          value={values.economicActivity}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option selected disabled value="">
                            Seleccione una opción
                          </option>
                          {actividadesEconomicas.map((actividad: any) => (
                            <option key={actividad} value={actividad}>
                              {actividad}
                            </option>
                          ))}
                        </select>
                        {errors.economicActivity && (
                            <div className="invalid-feedback">
                              Actividad económica es requerido
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mt-4 mb-4" />
                <div className="row">
                  {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div className="d-flex justify-content-center mt-5 mb-4">
                                    <a href='/solicitud/personal/9' className="btn btn-shadow-2">
                                        <img src={flechai} alt="PRADO'S" className="img-fluid mrirht" width="10" />
                                        Regresar
                                    </a>
                                </div>
                            </div> */}
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="d-flex justify-content-center mt-5 mb-4">
                      <button 
                        type="submit" 
                        className="btn btn-shadow-2"
                        disabled={!isValid}
                        >
                        Continuar
                        <img
                          src={flechad}
                          alt="PRADO'S"
                          className="img-fluid mleft"
                          width="10"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </motion.div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid p-3 img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
      </Backdrop>
    </motion.div>
  );
}
