import { combineReducers } from "redux";

import personalCreditReducer from "./personalCreditReducer";
import navReducer from "./navReducer";

const rootReducer = combineReducers({
	personalCreditReducer,
	navReducer,
});

export default rootReducer;