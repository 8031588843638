import React, { useEffect, useState } from 'react';

export const UserCambaceoContext = React.createContext();

const UserCambaceoProvider = props => {
    const [userVentas, setUserVentas] = useState({
        userId: '',
        nombre: '',
      });
    
      const updateUserVentas = valor => {
        setUserVentas(valor);
        localStorage.setItem('userVenta', JSON.stringify(valor));
      };
    
      useEffect(() => {
        if (localStorage.getItem('userVenta')) {
          const userLocal = JSON.parse(localStorage.getItem('userVenta'));
          setUserVentas(userLocal);
        }
      }, []);
    
      return (
        <UserCambaceoContext.Provider value={{userVentas, updateUserVentas}}>
          {props.children}
        </UserCambaceoContext.Provider>
      );
    };
    
    UserCambaceoProvider.propTypes = {};
    
    export default UserCambaceoProvider;