import React,{ useEffect, useState} from 'react'
import credito_actual from '../../../../img/credito_actual.png';
import ApiClient, { Payment } from "../../../../services/apiclient";
import { useHistory } from "react-router-dom";
import moment from "moment";
import logo2 from '../../../../img/logo.png';
import {motion} from "framer-motion";
import DataTable from './table';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarExport, GridToolbarContainer  } from '@mui/x-data-grid';


const columns: GridColDef[] = [

  { field: 'Fecha', 
    headerName: 'Fecha',
    sortable: false,
    filterable:false,
    disableColumnMenu: true,
    align:'center',
    flex:0.1,
    minWidth:100,
    headerClassName: 'super-app-theme--header',

},
  {
    field: 'Numero',
    headerName: 'Número de pago',
    sortable: false,
    disableColumnMenu: true,
    align:'center',
    flex:0.1,
    minWidth:140,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'Concepto',
    headerName: 'Concepto',
    sortable: false,
    disableColumnMenu: true,
    align:'center',
    flex:0.1,
    minWidth:115,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'Cantidad',
    headerName: 'Cantidad a pagar',
    sortable: false,
    disableColumnMenu: true,
    align:'center',
    flex:0.1,
    minWidth:145,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'Total',
    headerName: 'Total acumulado',
    sortable: false,
    disableColumnMenu: true,
    align:'center',
    flex:0.1,
    minWidth:138,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'Estatus',
    headerName: 'Estatus',
    sortable: false,
    disableColumnMenu: true,
    align:'center',
    flex:0.1,
    minWidth:145,
    headerClassName: 'super-app-theme--header',
  },
];
export default function Credito({amount, status}) {
    const apiClient = ApiClient.getInstance();
    const [nextPayment, setNextPayment] = useState("");
    const [nextTotal, setNextTotal] = useState(0);
    const [loanAmmount, setLoanAmmount] = useState(0);
    const [loanPayments, setLoanPayments] = useState(0);
    const [amortizacion, setAmortizacion] = useState<Payment[]>([]);
    const [payed, setPayed] = useState(0);
    const [total, setTotal] = useState(0);
    const [contrato, setcontrato] = useState("");
    const [rows,setRow] = useState([]);
    const history = useHistory();
        const getEstatusLoan = async () => {
            apiClient
              .getValidationTerms()
              .then((resp) => {
                const {
                  acceptedTermAndPrivacy,
                  userDetails,
                  loanActive,
                  acceptedContract,
                } = resp;
                console.log("Dashboard - getValidationTerms: ", resp);
                if (loanActive == true && acceptedContract == true) {
                  apiClient
                    .getActiveCredit()
                    .then((data) => {
                      console.log(data);
                      setcontrato(data.creditType);
                      apiClient
                        .getTablaAmortizacion(data.creditType, data.id)
                        .then((table) => {
                          console.log(table);
                          const date = new Date(table[0].paymentDate);
                          setNextPayment(date.toLocaleDateString());
                          setNextTotal(table[0].amountPayable);
                          setLoanAmmount(table[0].cumulativeTotal);
        
                          const payments = table.filter(
                            (payment) => payment.paidOut === false
                          );
                          setLoanPayments(payments.length);
                          //setAmortizacion(table);
                          let amountTotal = 0;
                          table.forEach(payment => amountTotal += payment.amountPayable)
                          let a :Payment[] = [];
                          let payable = amountTotal
                          table.forEach(payment => {
                            payable = payable - payment.amountPayable
                            a.push({
                              amountPayable: payment.amountPayable,
                              capitalPayment: payment.capitalPayment,
                              commission: payment.commission,
                              concept: payment.concept,
                              cumulativeTotal: payable,
                              //cumulativeTotal: payment.cumulativeTotal,
                              id: payment.id,
                              interestAmount: payment.interestAmount,
                              ivaCommission: payment.ivaCommission,
                              nominaLoanId: payment.nominaLoanId,
                              paidOut: payment.paidOut,
                              paymentDate: payment.paymentDate,
                              paymentNumber: payment.paymentNumber,
                              personalLoanId: payment.personalLoanId,
                              userId: payment.userId,
                          })
                          }
                          
                          )
                          setTotal(amountTotal);
                          const rowsss = a.map((row) => (
                            createData(row.id,moment(row.paymentDate).format("DD/MM/YYYY"), row.paymentNumber, row.concept, row.amountPayable, row.cumulativeTotal.toFixed(2),row.paidOut === true ? "Pagado" : "Pendiente de pago")
                            ));
                            setRow(rowsss)
                          setPayed(
                            table.filter((payment) => payment.paidOut === true).length
                          );
                          setAmortizacion(a)
                        })
                        .catch((err) => console.error(err));
                    })
                    .catch((err) => {
                      history.push("/");
                    });
                } else if (!acceptedContract) {
                  //history.push("/proceso-credito");
                }
              })
              .catch((err) => {
                if (err.message.includes("Entity not found: User with id")) {
                  localStorage.clear();
                  history.push("/login");
                }
        
                if (err.status === 401) {
                  localStorage.clear();
                  history.push("/login");
                }
              });
          };
        
          useEffect(() => {
            getEstatusLoan();
          }, []);
          function createData(ID,Fecha, Numero, Concepto, cantidad, total, Estatus) {
            let Cantidad = '$' + cantidad;
            let Total = '$' + total;
          return { ID,Fecha, Numero, Concepto, Cantidad, Total, Estatus };
        }
          const renderRows = amortizacion.map((row) => (
            <tr key={row.id}>
              <td>{moment(row.paymentDate).format("DD/MM/YYYY")}</td>
              <td>{row.paymentNumber}</td>
              <td>{row.concept}</td>
              <td>${row.amountPayable.toFixed(2)}</td>
              <td>${row.cumulativeTotal.toFixed(2)}</td>
              <td>{row.paidOut === true ? "Pagado" : "Pendiente de pago"}</td>
            </tr>
          ));
          
          

    return (
      <motion.div
      initial={{  opacity:0 }}
        animate={{opacity:1 }}
        exit={{opacity:0}}
        transition={{ ease: "easeInOut", duration: 0.6 }}
         className="col-md-8 col-lg-10 d-md-block grey-background p-0 m-0">
          {status === "IN_PROGRESS" ? (
                            <div className="mt-5 mb-5 text-center">
                            <h5 className="txt-blue-lig">¡Estamos analizando tu solicitud de crédito!</h5>
                                    <h3 className="text-strong-blue">
                                        ¡No te preocupes! Estamos trabajando arduamente para determinar la
                                        viabilidad de tu solicitud por
                                        <br />
                                        {amount && (
                                        <b>
                                            {" "}
                                            ${" "}
                                            {amount
                                            .toFixed(2)
                                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                                            MXN.
                                        </b>
                                        )}
                                    </h3>
                                <img src={logo2} alt="PRADO'S" className="img-fluid mt-5" width="200" />
                            </div>
                            ) : (
                        <div className="container mt-5 ">
                            <div className="row">
                                <div className=" col-sm-12 col-md-12 col-lg-4 d-flex">
                                    <img src={credito_actual} alt="PRADO'S" className="img-fluid p-2 img-client mb-4" width="80" />
                                    <h3 className="text-strong-blue mt-4">Mi crédito actual</h3>
                                </div>
                                <div className=" col-sm-12 col-md-12 col-lg-8">
                                    <div className="d-flex justify-content-center">
                                        <div className="card card-monto-cuenta p-2">
                                            <div className="container text-center">
                                                <h6 className="text-credit">
                                                    Préstamo total:
                                                </h6>
                                                <h2 className="text-light-blue"> {amount.toLocaleString("en-US", {
                                                                                        style: "currency",
                                                                                        currency: "USD",
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2,
                                                                                    })}</h2>
                                            </div>
                                        </div>
                                        <div className="card card-monto-cuenta p-2">
                                            <div className="container text-center">
                                                <h6 className="text-credit">
                                                    Pagos pendientes:
                                                </h6>
                                                <h2 className="text-light-blue">{loanPayments}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-3 mb-1 text-center">
                                <h4 className="text-strong-blue">Desglose de pagos</h4>
                            </div>
                            {/* <div className="container mb-5 table-responsive">
                                <table className="table table-light table-striped">
                                    <thead>
                                        <tr className="text-center text-strong-blue">
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Número</th>
                                            <th scope="col">Concepto</th>
                                            <th scope="col">Cantidad a pagar</th>
                                            <th scope="col">Monto acumulado</th>
                                            <th scope="col">Estatus</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                    {renderRows}
                                    </tbody>
                                </table>
                            </div> */}
                            <div style={{ display: 'flex'}}>
                  <div style={{ flexGrow: 1 }}>
                  <DataTable
                  rows={rows}
                  columns={columns}
                  
                />
                  </div>
                </div>                                                       

                        </div>
                            )}
                    </motion.div>
    )
}