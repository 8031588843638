import React,{useState, useEffect} from 'react'
import Fab from '@mui/material/Fab';
import PersonIcon from '@mui/icons-material/Person';
import CurtainsClosedIcon from '@mui/icons-material/CurtainsClosed';
import { UserCambaceoContext } from "../context/UserCambaceoProvider";

export const FloatUser = () => {
    const { userVentas, updateUserVentas } = React.useContext(UserCambaceoContext);
    function closeSession() {
        updateUserVentas({
            ...userVentas,
            nombre: null,
            userId: null,
          });
          return  window.location.replace(`https://percapita.mx/ventas?type=logout`);
          //return  window.location.replace(`https://percapita.mx/ventas/logout`);
    }
    return (
    <>
        {userVentas.nombre !== null && userVentas.nombre !== '' && userVentas.nombre !== 'null'?  
              <div className="float">
              <Fab variant="extended" color="primary" aria-label="add">
                  <PersonIcon sx={{ mr: 1 }} />
                  {userVentas.nombre}
              </Fab>
              <Fab variant="extended" color="warning" aria-label="add" onClick={closeSession}>
                  <CurtainsClosedIcon sx={{ mr: 1 }} />
                  Salir
              </Fab>
        
              </div>
            : ''}

    </>

    )
}
