import React,{useState, useEffect} from 'react'
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { UserCambaceoContext } from "../context/UserCambaceoProvider";
import logo_balnco from "../img/logo_blanco.png";
import { useHistory, useParams } from "react-router-dom";
export const LoginCambaceo = () => {
    const { userVentas, updateUserVentas } = React.useContext(UserCambaceoContext);
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams<any>();

    useEffect(() => {
        if(params.type === 'login'){
            updateUserVentas({
                ...userVentas,
                nombre: params.nameUser,
                userId: params.userId,
              });
              return  window.location.replace(`https://percapita.mx`);
        } else {
            updateUserVentas({
                ...userVentas,
                nombre: null,
                userId: null,
              });
              return  window.location.replace(`https://percapita.mx`);
        }
        
    },[])
    return (
        <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
        >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid p-3 img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
        </Backdrop>

    )
}
