import  Navbar  from '../../front/NavbarI';
import  Footer  from '../../front/FooterI';
import logo_pabs from '../../../img/logo_pabs.png';
import { useFormik } from "formik";
import * as Yup from "yup";
import React, {useEffect, useState} from 'react'
import {  useHistory } from "react-router-dom";
import ApiClient, { Prospective } from "../../../services/apiclient";
import { UserContext } from "./../../../context/UserProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import {motion} from "framer-motion";
export default function Pabs_2() {
    const history = useHistory();
    const apiClient = ApiClient.getInstance();
    const[amount,setAmount] = useState(0);
    const[isLoading,setIsLoading] = useState(false);
    const [openMessage,setOpenMessage] = useState(false);
    const[message,setMessage] = useState("");
    const[severity,setSeverity] = useState("success");
    const[date,setDate] = useState(null);
    const [shown, setShown] = React.useState(false);
    const[phoneChange,setPhoneChange] = React.useState(false);
    const[newPhone,setNewPhone] = React.useState("");
    const[sendCode,setSendCode] = React.useState({
        phone:"",
        send:false
    });
    const [login,setLogin] = React.useState(false);
    const { userMain, updateUser } = React.useContext(UserContext);
    const [ciudades, setCiudades] = useState([]);
    function handleChangeDate(event: any) {
        console.log(event.target.value);
        let date = event.target.value.split('/');
        formik.setFieldValue('dateOfBirth',date[2]+'-'+date[0]+'-'+date[1]);
    }
    function handleChangePhone(event: any) {
        formik.setFieldValue('phone',event.target.value.toString());
    }
    function handleChangePhoneBlur(event: any) {
        if(sendCode.phone !== ""){ // se envío sms
            //sendSms
            if(sendCode.phone !== formik.values.phone){
                setPhoneChange(true)   
            }
        } 
    }
   
     const handleBlur = (e) => console.log(e.target.value);
      function handleBlurPassword(event:any){
        if(!formik.errors.confirmPassword){
            apiClient.signup(formik.values.phone, formik.values.password, "titulo")
                    .then(res => res.json())
                    .then(data => {
                        console.log(data)
                        localStorage.setItem('cxdc',formik.values.phone)
                        localStorage.setItem('fwcd',formik.values.password)
                        if (data.error) {
                        setIsLoading(false);
                        //localStorage.clear();
                        setOpenMessage(true);
                        setMessage(data.status >= 500 ? 'Error del servidor' : data.error.message);
                        setSeverity('error');
                        setLogin(false);
                        return;
                        }
                        setLogin(true)
                        localStorage.setItem('userId',data.user)
                    }).catch(err => {
                        setIsLoading(false);
                        setOpenMessage(true);
                        setMessage('Error en el servidor');
                        setSeverity('error');
                    });       
            }
      }

    const formik = useFormik({
        initialValues: {
          firstName: "",
          secondName: "",
          lastFName: "",
          lastMName: "",
          dateOfBirth: "",
          state: "",
          gender: "",
          email: "",
          phone: "",
          attached1: false,
          attached2: false,
          attached3: false,
          password: "",
          confirmPassword: "",
          val1: "",
          val2: "",
          val3: "",
          val4: "",
          val5: "",
          val6: "",
          userId:localStorage.getItem("prspect"),
          contract:"",
          city:"",
        },
        validationSchema: Yup.object({
          firstName: Yup.string().required("Tienes que ingresar la calle."),
          lastFName: Yup.string().required("Tienes que ingresar la calle."),
          lastMName: Yup.string().required("Tienes que ingresar la calle."),
          dateOfBirth: Yup.string().required("Tienes que ingresar la calle."),
          //state: Yup.string().required("Tienes que ingresar la calle."),
          email: Yup.string().required("Tienes que ingresar la calle."),
          phone: Yup.string().required("Tienes que ingresar la calle.").matches(/^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/),
          attached1: Yup.boolean().required("The terms and conditions must be accepted.").oneOf([true], 'Field must be checked'),
          attached2: Yup.boolean().required("The terms and conditions must be accepted.").oneOf([true], 'Field must be checked'),
          password: Yup.string().required("Tienes que ingresar la calle."),
          confirmPassword: Yup.string()
          .required("Please confirm your password")
          .oneOf([Yup.ref('password'), null], "Passwords don't match."),
          contract: Yup.string().max(9).required("Ingresa el titulo"),
          city: Yup.string().required("Tienes que ingresar la calle."),
        }),
        onSubmit: values => {

            setIsLoading(true)
            let code = formik.values.val1 + formik.values.val2 + formik.values.val3 + formik.values.val4 + formik.values.val5 + formik.values.val6;
            apiClient.activateAccountSMS(code).then(data => {
                setOpenMessage(true);
                setMessage('Tu cuenta ha sido activada');
                console.log(localStorage.getItem('cxdc') , localStorage.getItem('fwcd'))
                if(localStorage.getItem('cxdc') && localStorage.getItem('fwcd')){
                  console.log(data)
                apiClient
                .login(localStorage.getItem('cxdc'),localStorage.getItem('fwcd'))
                .then((res) => res.json())
                .then(async (a) => {
                  localStorage.removeItem('cxdc')
                  localStorage.removeItem('fwcd')
                  if (a.error) {
                    localStorage.clear();
                    setOpenMessage(true);
                    const statusCode = a.error?.status || a.error.statusCode;
                    setMessage(statusCode === 401 ? 'Telefono o contraseña invalidos' : a.error.message);
                    setSeverity("error");
          
                    // if(statusCode === 403)
                    //   return history.push("/step2");
                    //   else return history.push("/login");
                  }
          
                  localStorage.setItem("TOKEN", a.token);
                  localStorage.setItem("USER_ID", a.userId);
          
                  apiClient.token = a.token;
                  apiClient.userId = a.userId;
                  if (a.fullName) {
                    updateUser({
                      ...userMain,
                      nombre: a.fullName || a.fullName,
                      telefono: a.phone,
                    });
                  } else {
                    updateUser({
                      ...userMain,
                      telefono: a.phone,
                    });
                  }
                    try {
                        await apiClient.termsConditions({ publicidad: formik.values.attached3 });
                
                    } catch (error) {
                        setIsLoading(false);
                        setOpenMessage(true);
                        setMessage("Ha ocurrido un error al guardar los datos.");
                        setSeverity("error");
                        return;
                    }
                        const data = {
                            firstName: formik.values.firstName,
                            secondName: formik.values.secondName,
                            lastFName: formik.values.lastFName,
                            lastMName: formik.values.lastMName,
                            birthDate: formik.values.dateOfBirth,
                            email: formik.values.email,
                            sex: formik.values.gender
                        }
                        apiClient
                        .createUserDetails(data)
                        .then((res) => res.json())
                        .then((data) => {
                            if (data.error) {
                            setOpenMessage(true);
                            setMessage(data.error.message);
                            setSeverity("error");
                            }
                            const fullName = `${formik.values.firstName} ${formik.values.secondName} ${formik.values.lastFName} ${formik.values.lastMName}`;
                            updateUser({
                            ...userMain,
                            nombre: fullName,
                            });
                            return history.push("/solicitud-datos3");
                        })
                        .catch((err) => {
                            setIsLoading(false);
                            setOpenMessage(true);
                            setMessage("Favor de verificar que la informacion sea correcta.");
                            setSeverity("error");
                        });

                })
                }
                //return history.push("/login");
              }).catch(reason => {
                setOpenMessage(true);
                setMessage(reason.message);
                setSeverity('error');
              });
        }
      });
      function handleChangeCode(e) {
        console.log(e.target)
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
  
        // Check if they hit the max character length
        if (value.length >= maxLength) {
          // Check if it's not the last input field
          if (parseInt(fieldIndex, 10) < 6) {
            // Get the next input field
            const nextSibling = document.querySelector(
              `input[name=val-${parseInt(fieldIndex, 10) + 1}]`
            ) as HTMLElement | null;
  
            // If found, focus the next field
            if (nextSibling !== null) {
              nextSibling.focus();
            }
          } else if(parseInt(fieldIndex, 10) === 6){
            formik.handleSubmit();
          }
        }
          formik.setFieldValue([`val${fieldIndex}`].toString(), value)
      }

      useEffect(() => {
        apiClient
          .getCityWithoutLogin()
          .then((ciudades) => {
            setCiudades(ciudades);
          })
          .catch((err) => {
            console.error(err);
          });
          
      }, []);
    return (
        <motion.div
        initial={{  opacity:0 }}
          animate={{opacity:1 }}
          exit={{opacity:0}}
          transition={{ ease: "easeInOut", duration: 0.6 }}
           className="grey-background">
            <Navbar />
            <br />
            <div className="d-block d-sm-block d-md-none p-0 m-0 mb-1">
                <div className="card card-monto">
                    <div className="container">
                        <h6 className="text-credit text-end p-1">
                            Monto solicitado:
                        </h6>
                        <h2 className="text-light-blue text-end"> $1,200</h2>
                    </div>
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-3">
                    <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                        <div className="card card-monto">
                            <div className="container">
                                <h6 className="text-credit text-end p-1">
                                    Monto solicitado:
                                </h6>
                                <h2 className="text-light-blue text-end"> $1,200</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="text-center">
                        <img src={logo_pabs} alt="PRADO'S" className="img-fluid mb-4" width="70" />
                        <h3 className="sol1-tittle">Bienvenido a tu solicitud <br />
                            de préstamo PABS</h3>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
            {/* STEPS */}
            <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                <div className="d-flex justify-content-center mt-4 container">
                    <button className="circle-step-blue">
                        <h5 className="numbre-step">1</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
                    <button className="circle-step">
                        <h5 className="numbre-step">2</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
                    <button className="circle-step">
                        <h5 className="numbre-step">3</h5>
                    </button>
                </div>
            </div>
            <div className="d-block d-sm-block d-md-none p-0 m-0 ">
                <div className="d-flex justify-content-center mt-4 container">
                    <button className="circle-step">
                        <h5 className="numbre-step-blue">1</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaa</div>
                    <button className="circle-step">
                        <h5 className="numbre-step">2</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaa</div>
                    <button className="circle-step">
                        <h5 className="numbre-step">3</h5>
                    </button>
                </div>
            </div>
            {/* CARDS */}
            <div className="d-flex justify-content-center mt-2 container">
                <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
                    <div className="container">
                        <p className="txt-blue-strong">Rellena los campos vacíos con los datos de tu domicilio</p>
                        <form>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="city" className="form-label sol1-tittle">Ciudad donde solicitaste tu titulo*</label>
                                        <select 
                                            className="form-select slect-blue text-light" 
                                            aria-label="Default select example"
                                            id="city"
                                            name="city"
                                            onChange={formik.handleChange}
                                            value={formik.values.city}
                                            onBlur={formik.handleBlur}
                                        >
                                            <option selected>Ciudad</option>
                                            {ciudades.map((ciudad) => (
                                                <option key={ciudad.id} value={ciudad.name}>
                                                {ciudad.nameDescription}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="firstName" className="form-label sol1-tittle">Nombre (s)*</label>
                                        <input 
                                            type="input" 
                                            className="form-control" 
                                            id="firstName" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.firstName}
                                            onBlur={formik.handleBlur}
                                             />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="contract" className="form-label sol1-tittle">Número de contrato PABS*</label>
                                        <input 
                                            type="input" 
                                            className={formik.errors.contract ? " form-control is-invalid" : "form-control"} 
                                            id="contract" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.contract}
                                            onBlur={formik.handleBlur}
                                            maxLength={9}
                                             />
                                        {formik.errors.attached2 && (
                                                <div className="invalid-feedback">
                                                Debes ingresar un contrato
                                            </div>
                                            )}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="secondName" className="form-label sol1-tittle">Segundo nombre</label>
                                        <input 
                                            type="input" 
                                            className="form-control" 
                                            id="secondName" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.secondName}
                                            onBlur={formik.handleBlur}
                                             />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="lastFName" className="form-label sol1-tittle">Apellido paterno*</label>
                                        <input 
                                            type="input" 
                                            className="form-control" 
                                            id="lastFName" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.lastFName}
                                            onBlur={formik.handleBlur}
                                             />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="lastMName" className="form-label sol1-tittle">Apellido materno*</label>
                                        <input 
                                            type="input" 
                                            className="form-control" 
                                            id="lastMName" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.lastMName}
                                            onBlur={formik.handleBlur}
                                             />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-1">
                                        <label htmlFor="birthDate" className="form-label sol1-tittle">Fecha de nacimiento *</label>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={3}>
                                                <DatePicker 
                                                disableFuture                                                
                                                //label="Fecha nacimiento"
                                                openTo="day"
                                                toolbarPlaceholder="dff"
                                                toolbarFormat="//"
                                                views={[  'month', 'day', 'year']}
                                                value={date}
                                                onChange= {(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                renderInput={
                                                     (params) => 
                                                        <TextField 
                                                            {...params}
                                                            size="medium"
                                                            color="primary"
                                                            
                                                            name="dateOfBirth"
                                                            onChange={handleChangeDate}
                                                            value={formik.values.dateOfBirth}
                                                            onBlur={handleBlur}
                                              />}
                                                />
                                            </Stack>
                                            </LocalizationProvider>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-4">
                                        <label htmlFor="gender" className="form-label sol1-tittle">Género *</label>
                                        <br />
                                        <div className="form-check form-check-inline">
                                        <input 
                                            className={formik.errors.gender ? " form-check-input is-invalid" : "form-check-input"}
                                                type="radio" 
                                                name="gender" 
                                                id="gender" 
                                                value="femenino"
                                                checked={formik.values.gender === "femenino" }
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur}
                                                />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Mujer</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                        <input 
                                                className={formik.errors.gender ? " form-check-input is-invalid" : "form-check-input"}
                                                type="radio" 
                                                name="gender" 
                                                id="gender" 
                                                value="masculino"
                                                checked={formik.values.gender === "masculino" }
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur}
                                                />
                                            <label className="form-check-label" htmlFor="inlineRadio2">Hombre</label>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div className="row"> 
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label sol1-tittle">Correo electrónico*</label>
                                        <input 
                                            type="email" 
                                            className={formik.errors.email ? " form-control  is-invalid" : "form-control"}
                                            id="email" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            onBlur={formik.handleBlur}
                                             />
                                    </div>
                                </div>
                                    
                            </div>
                            <hr className="mt-4 mb-4" />
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label sol1-tittle">Numero de celular *</label>
                                        <input 
                                            type="number" 
                                            className={formik.errors.phone ? "form-control  is-invalid" : "form-control"}
                                            id="phone" 
                                            aria-describedby="emailHelp"
                                            onChange={ handleChangePhone}
                                            value={formik.values.phone}
                                            onBlur={handleChangePhoneBlur}
                                            />
                                            {formik.errors.phone && (
                                                <div className="invalid-feedback">
                                                Ingresa un teléfono válido
                                            </div>
                                            )}
                                            
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label sol1-tittle">Contraseña *</label>
                                        <input 
                                            type={shown ? "text" : "password"}
                                            className={formik.errors.password ? "form-control  is-invalid" : "form-control"}
                                            id="password" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            onBlur={formik.handleBlur}
                                            autoComplete="false"
                                            />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="confirmPassword" className="form-label sol1-tittle">Confirma contraseña *</label>
                                        <input 
                                            type={shown ? "text" : "password"}
                                            className={formik.errors.confirmPassword ? "form-control  is-invalid" : "form-control"}
                                            id="confirmPassword" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.confirmPassword}
                                            onBlur={handleBlurPassword}
                                            />
                                            {formik.errors.confirmPassword && formik.values.confirmPassword.length   >=  formik.values.password.length  && (formik.values.password.length > 0) && (
                                                <div className="invalid-feedback">
                                                Las contraseñas no coinciden
                                            </div>
                                            )}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="container">
                                    <h6 className="text-grey">Antes de continuar, debemos validar tu número. <br />
                                            Da click aquí una vez que hayas puesto tu celular. </h6>
                                        <div className="form-check">
                                            <input 
                                                className={formik.errors.attached1 ? "form-check-input checkbox-terms  is-invalid" : "form-check-input checkbox-terms"}
                                                type="checkbox" 
                                                //value="" 
                                                id="attached1" 
                                                //checked={formik.values.attached1}
                                                onChange={formik.handleChange}
                                                disabled={formik.errors.password || formik.errors.confirmPassword ? true : false}
                                                onBlur={formik.handleBlur}
                                                />
                                            <label className="form-check-label text-check" htmlFor="attached1">
                                                Acepto el Aviso de Privacidad
                                            </label>
                                            {formik.errors.attached1 && !formik.errors.confirmPassword &&(
                                                <div className="invalid-feedback">
                                                Debes aceptar el aviso de privacidad
                                            </div>
                                            )}
                                            
                                        </div>
                                        <div className="form-check">
                                            <input 
                                                 className={formik.errors.attached2 ? "form-check-input checkbox-terms  is-invalid" : "form-check-input checkbox-terms"}
                                                type="checkbox" 
                                                //value="" 
                                                id="attached2"
                                                //checked={formik.values.attached2}
                                                onChange={formik.handleChange}
                                                disabled={formik.errors.password || formik.errors.confirmPassword ? true : false}
                                                onBlur={formik.handleBlur}
                                                 />

                                            <label className="form-check-label text-check" htmlFor="attached2">
                                                Acepto las obligaciones del Contrato de licencia
                                            </label>
                                            {formik.errors.attached2  && !formik.errors.confirmPassword && (
                                                <div className="invalid-feedback">
                                                Debes aceptar el contrato de licencia
                                            </div>
                                            )}
                                        </div>
                                        <div className="form-check">
                                            <input 
                                                className="form-check-input checkbox-terms" 
                                                type="checkbox" 
                                                value="" 
                                                id="attached3"
                                                checked={formik.values.attached3}
                                                onChange={formik.handleChange}
                                                disabled={formik.errors.password || formik.errors.confirmPassword ? true : false}
                                                onBlur={formik.handleBlur}
                                                 />
                                            <label className="form-check-label text-check" htmlFor="flexCheckDefault">
                                                Acepto recibir noticias, promociones e información acerca de mi cuenta
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-4">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Confirma tu contraseña</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Numero de celular *</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="container">
                                        <h6 className="text-grey">Antes de continuar, debemos validar tu número. <br />
                                            Da click aquí una vez que hayas puesto tu celular. </h6>
                                        <div className="form-check">
                                            <input className="form-check-input checkbox-terms" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label text-check" htmlFor="flexCheckDefault">
                                                Acepto el Aviso de Privacidad
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input checkbox-terms" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label text-check" htmlFor="flexCheckDefault">
                                                Acepto las obligaciones del Contrato de licencia
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input checkbox-terms" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label text-check" htmlFor="flexCheckDefault">
                                                Acepto recibir noticias, promociones e información acerca de mi cuenta
                                            </label>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-start mt-5 mb-4">
                                        <a href='/pabs/3' className="btn btn-shadow">
                                            Enviar código
                                        </a>
                                    </div>
                                </div>
                            </div> */}
                            <div className="text-center mt-1">
                                <p>Por favor, ingresa el código que te enviamos a tu celular:</p>
                                <p className="sol1-tittle">Código</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="p-1">
                                    <input 
                                        type="string" 
                                        className="form-control input-number " 
                                        id="val-1" 
                                        aria-describedby="emailHelp"
                                        name="val-1"
                                        onChange={handleChangeCode}
                                        maxLength={1}
                                        value={Object.keys(formik.errors).length > 0 ? '' : formik.values.val1} 
                                        disabled={Object.keys(formik.errors).length > 0  ? true : false}/>
                                </div>
                                <div className="p-2">
                                    <input 
                                        type="string" 
                                        className="form-control input-number" 
                                        id="val-2" 
                                        aria-describedby="emailHelp"
                                        name="val-2"
                                        onChange={handleChangeCode}
                                        maxLength={1}
                                        value={Object.keys(formik.errors).length > 0 ? '' : formik.values.val2}
                                        disabled={Object.keys(formik.errors).length > 0  ? true : false}/>
                                        
                                </div>
                                <div className="p-2">
                                    <input 
                                        type="string" 
                                        className="form-control input-number" 
                                        id="val-3" 
                                        aria-describedby="emailHelp"
                                        name="val-3"
                                        onChange={handleChangeCode}
                                        maxLength={1}
                                        value={Object.keys(formik.errors).length > 0 ? '' : formik.values.val3}
                                        disabled={Object.keys(formik.errors).length > 0 ? true : false}
                                         />
                                </div>
                                <div className="p-2">
                                    <input 
                                        type="string" 
                                        className="form-control input-number" 
                                        id="val-4" 
                                        aria-describedby="emailHelp"
                                        name="val-4"
                                        onChange={handleChangeCode}
                                        maxLength={1}
                                        value={Object.keys(formik.errors).length > 0 ? '' : formik.values.val4}
                                        disabled={Object.keys(formik.errors).length > 0 ? true : false}
                                         />
                                </div>
                                <div className="p-2">
                                    <input 
                                        type="string" 
                                        className="form-control input-number" 
                                        id="val-5" 
                                        aria-describedby="emailHelp"
                                        name="val-5"
                                        onChange={handleChangeCode}
                                        maxLength={1}
                                        value={Object.keys(formik.errors).length > 0 ? '' : formik.values.val5}
                                        disabled={Object.keys(formik.errors).length > 0 ? true : false}
                                         />
                                </div>
                                <div className="p-2">
                                    <input 
                                        type="string" 
                                        className="form-control input-number" 
                                        id="val-6" 
                                        aria-describedby="emailHelp"
                                        name="val-6"
                                        onChange={handleChangeCode}
                                        maxLength={1}
                                        value={Object.keys(formik.errors).length > 0 ? '' : formik.values.val6}
                                        disabled={Object.keys(formik.errors).length > 0 ? true : false}
                                        
                                         />
                                         
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </motion.div>
    )
}
