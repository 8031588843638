import React, { useEffect, useState } from "react";

export const PreTramiteCreditoContext = React.createContext();

const PreTramiteCreditoProvider = (props) => {
  const [preCredito, setCredito] = useState({
    amount: 0,
    paymentStatus: "0", // (0 = nuevo, 1 = en proceso, 2 = aprobado, 3 = Rechazado)
    term: "0",
    way_to_payment: "semanal",
    amount_payment: "0",
    step: "",
    firstName: "",
    secondName: "",
    lastFName: "",
    lastMName: "",
    dateOfBirth: "",
    state: "",
    gender: "",
    email: "",
    phone: "",
    attached1: false,
    attached2: false,
    idProcessMarketing: "",
    stepMarketing: "",
    typeLoanMarketing: "",
  });

  const updatePreCredito = (valor) => {
    setCredito(valor);
    localStorage.setItem("preCreditoLocal", JSON.stringify(valor));
  };

  const deletePreCredito = () => {
    setCredito({
      amount: 0,
      paymentStatus: "0", // (0 = nuevo, 1 = en proceso, 2 = aprobado, 3 = Rechazado)
      term: "0",
      way_to_payment: "semanal",
      amount_payment: "0",
      step: "",
      firstName: "",
      secondName: "",
      lastFName: "",
      lastMName: "",
      dateOfBirth: "",
      state: "",
      gender: "",
      email: "",
      phone: "",
      attached1: false,
      attached2: false,
    });
    localStorage.removeItem("preCreditoLocal");
  };

  useEffect(() => {
    if (localStorage.getItem("preCreditoLocal")) {
      const creditoLocal = JSON.parse(localStorage.getItem("preCreditoLocal"));
      setCredito(creditoLocal);
    }
  }, []);

  return (
    <PreTramiteCreditoContext.Provider
      value={{ preCredito, updatePreCredito, deletePreCredito }}
    >
      {props.children}
    </PreTramiteCreditoContext.Provider>
  );
};

PreTramiteCreditoProvider.propTypes = {};

export default PreTramiteCreditoProvider;
