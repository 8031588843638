import React, { useEffect, useState} from 'react'
import user_cuenta from '../../../../img/user_cuenta.png';
import Cuentacel from '../../../../img/cuenta_cel.png';
import Cuentawhats from '../../../../img/cuenta_whats.png';
import Cuentamail from '../../../../img/cuenta_mail.png';
import Cuentadireccion from '../../../../img/cuenta_direccion.png';
import ApiClient, {ChangeData} from "../../../../services/apiclient";
import AlertMessage from "../../../alert.component";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserContext } from "./../../../../context/UserProvider";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import logo_balnco from '../../../../img/logo_blanco.png';
import {motion} from "framer-motion";

export default function Datos() {
    const { userMain, updateUser } = React.useContext(UserContext);
    const apiClient = ApiClient.getInstance();
    const[isLoading,setIsLoading] = useState(false);
    const [openMessage,setOpenMessage] = useState(false);
    const[message,setMessage] = useState("");
    const[severity,setSeverity] = useState("success");

    const { 
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm
    
        }= useFormik({
        initialValues: {
          name: userMain.nombre,
          phone1: userMain.telefono,
          phone2: "",
          city: "",
          state: "",
          message: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Tienes que ingresar la calle."),
            phone2: Yup.string().required("Tienes que ingresar la calle."),
            city: Yup.string().required("Tienes que ingresar la calle."),
            state: Yup.string().required("Tienes que ingresar la calle."),
            message: Yup.string().required("Tienes que ingresar la calle."),
        }),
        onSubmit: values => {
            setIsLoading(true)
            const data : ChangeData = {
                name: values.name,
                phone: values.phone1,
                phone2:values.phone2,
                city:values.city,
                state: values.state,
                message: values.message,
              }
              console.log(data)

              apiClient
              .saveData(data)
              .then((res) => res.json())
              .then((data) => {
                  console.log(data)
                  if (data.error) {
                      setIsLoading(false);
                     setOpenMessage(true);
                     setMessage(data.error.message);
                     setSeverity("error");
                  }
                  setIsLoading(false);
                  setOpenMessage(true);
                  setMessage("Datos enviados correctamente, a la brevedad nos comunicaremos contígo");
                  resetForm();
              })
        }    
        })
        const getData = () => {
            apiClient.getDataChange()
            .then((data) => {
                console.log(data)
            })
        }
        useEffect(() => {
            getData();
            
        }, [])

    function stringToColor(string: string) {
            let hash = 0;
            let i;
          
            /* eslint-disable no-bitwise */
            for (i = 0; i < string.length; i += 1) {
              hash = string.charCodeAt(i) + ((hash << 5) - hash);
            }
          
            let color = '#';
          
            for (i = 0; i < 3; i += 1) {
              const value = (hash >> (i * 8)) & 0xff;
              color += `00${value.toString(16)}`.slice(-2);
            }
            /* eslint-enable no-bitwise */
          
            return color;
          }
          const handleClose = (event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpenMessage(false);
          };
          
    function stringAvatar(name: string) {
            return {
              sx: {
                bgcolor: stringToColor(name),        
              },
              children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
              
            };
          }

    return (
        <motion.div
      initial={{  opacity:0 }}
        animate={{opacity:1 }}
        exit={{opacity:0}}
        transition={{ ease: "easeInOut", duration: 0.6 }}
        className="col-md-3 col-lg-10 d-md-block grey-background p-0 m-0">
            <AlertMessage
              open={openMessage}
              message={message}
              handleClose={handleClose}
              severity={severity}
            />
                        <div className="container-fluid">
                            <div className="mt-5 mb-5 text-center ">
                            <div className="p-3 d-flex justify-content-center">
                            <Stack direction="row" spacing={3}>
                                <Avatar {...stringAvatar(userMain.nombre)} alt={userMain.nombre}
                               />
                                </Stack>
                                {/* <img src={foto_perfil} alt="PRADO'S" className="img-fluid" width="150" /> */}
                            </div>
                                
                                <h1 className="txt-blue-lig">¿Necesitas actualizar</h1>
                                <h1 className="text-strong-blue">Tu información personal?</h1>
                                <h5>Comunícate con nosotros, con gusto te ayudaremos</h5>
                                <div className="mt-5">
                                    <form onSubmit={handleSubmit}>
                                    <div className="row container text-start">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 ">
                                            <div className="container mt-4">
                                                <div className="d-flex">
                                                    <img src={Cuentacel} alt="PRADO'S" className="img-fluid p-2 img-client" width="50" />
                                                    <h6 className="mt-3">33 3616-8059</h6>
                                                </div>
                                                <div className="d-flex">
                                                    <img src={Cuentawhats} alt="PRADO'S" className="img-fluid p-2 img-client" width="50" />
                                                    <h6 className="mt-3">331 520 6918</h6>
                                                </div>
                                                <div className="d-flex">
                                                    <img src={Cuentamail} alt="PRADO'S" className="img-fluid p-2 img-client" width="50" />
                                                    <h6 className="mt-3">contacto@percapita.mx</h6>
                                                </div>
                                                <div className="row">
                                                    <div className="col-3 text-center">
                                                        <img src={Cuentadireccion} alt="PRADO'S" className="img-fluid" width="35" />
                                                    </div>
                                                    <div className="col">
                                                        <h6 className="mt-3">Av. Miguel Hidalgo y Costilla 1333, Col Americana, Zona Centro, 44100 Guadalajara, Jal.</h6>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                            <div className="mb-2">
                                                <label htmlFor="name" className="form-label label-cuenta">NOMBRE</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    value={values.name}
                                                    disabled
                                                    id="name" 
                                                    aria-describedby="emailHelp" />
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                    <div className="mb-2">
                                                        <label htmlFor="phone1" className="form-label label-cuenta">TELEFONO</label>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            id="phone1" 
                                                            value={values.phone1}
                                                            disabled
                                                            aria-describedby="emailHelp" />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label htmlFor="city" className="form-label label-cuenta">CIUDAD</label>
                                                        <input 
                                                            type="text" 
                                                            className={errors.city && touched.city ? "form-control  is-invalid" : "form-control"}
                                                            id="city" 
                                                            value={values.city}
                                                            aria-describedby="emailHelp"
                                                            onChange={handleChange}
                                                            />
                                                            {errors.city && touched.city &&(
                                                                <div className="invalid-feedback">
                                                                Elige una ciudad
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                    <div className="mb-2">
                                                        <label htmlFor="phone2" className="form-label label-cuenta">TELEFONO</label>
                                                        <input 
                                                            type="text" 
                                                            className={errors.phone2 && touched.phone2 ? "form-control  is-invalid" : "form-control"}
                                                            id="phone2" 
                                                            value={values.phone2}
                                                            onChange={handleChange}
                                                            aria-describedby="emailHelp"
                                                            placeholder="Teléfono alternativo"
                                                             />
                                                            {errors.phone2 && touched.phone2 &&(
                                                                <div className="invalid-feedback">
                                                                Ingresa una ciudad
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="mb-2">
                                                        <label htmlFor="state" className="form-label label-cuenta">ESTADO</label>
                                                        <select 
                                                        className={errors.state ? " form-select slect-blue text-light is-invalid" : "form-select slect-blue text-light"}
                                                        aria-label="Default select example"
                                                        id="state"
                                                        name="state"
                                                        value={values.state}
                                                        onChange={handleChange}
                                                        >
                                                            <option value="" disabled>Seleccione un estado</option>
                                                            <option value="Aguascalientes">Aguascalientes</option>
                                                            <option value="Baja California">Baja California</option>
                                                            <option value="Baja California Sur">
                                                                Baja California Sur
                                                            </option>
                                                            <option value="Campeche">Campeche</option>
                                                            <option value="Chiapas">Chiapas</option>
                                                            <option value="Chihuahua">Chihuahua</option>
                                                            <option value="Coahuila de Zaragoza">
                                                                Coahuila de Zaragoza
                                                            </option>
                                                            <option value="Colima">Colima</option>
                                                            <option value="Colima">Distrito Federal</option>
                                                            <option value="Durango">Durango</option>
                                                            <option value="Guanajuato">Guanajuato</option>
                                                            <option value="Guerrero">Guerrero</option>
                                                            <option value="Hidalgo">Hidalgo</option>
                                                            <option value="Jalisco">Jalisco</option>
                                                            <option value="México">México</option>
                                                            <option value="Michoacán de Ocampo">
                                                                Michoacán de Ocampo
                                                            </option>
                                                            <option value="Morelos">Morelos</option>
                                                            <option value="Nayarit">Nayarit</option>
                                                            <option value="Nuevo León">Nuevo León</option>
                                                            <option value="Oaxaca">Oaxaca</option>
                                                            <option value="Puebla">Puebla</option>
                                                            <option value="Querétaro">Querétaro</option>
                                                            <option value="Quintana Roo">Quintana Roo</option>
                                                            <option value="San Luis Potosí">San Luis Potosí</option>
                                                            <option value="Sinaloa">Sinaloa</option>
                                                            <option value="Sonora">Sonora</option>
                                                            <option value="Tabasco">Tabasco</option>
                                                            <option value="Tamaulipas">Tamaulipas</option>
                                                            <option value="Tlaxcala">Tlaxcala</option>
                                                            <option value="Veracruz de Ignacio de la Llave">
                                                                Veracruz de Ignacio de la Llave
                                                            </option>
                                                            <option value="Yucatán">Yucatán</option>
                                                            <option value="Zacatecas">Zacatecas</option>
                                                        </select>
                                                        {errors.state && touched.state &&(
                                                                <div className="invalid-feedback">
                                                                Elige una estado
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="message" className="form-label label-cuenta">MENSAJE</label>
                                                <textarea 
                                                    className={errors.message && touched.message ? "form-control  is-invalid" : "form-control"}
                                                    id="message" 
                                                    placeholder="Expon los datos que deseas cambiar ..."
                                                    value={values.message}
                                                    aria-describedby="emailHelp" 
                                                    onChange={handleChange}
                                                    >

                                                </textarea>
                                                {errors.message && touched.message &&(
                                                    <div className="invalid-feedback">
                                                        Agrega un mensaje
                                                    </div>
                                                )}                                            
                                            </div>
                                            <div className="mt-3 text-center" >
                                                <button  className="btn btn-primary btn-conoce-azul" type="submit"  >
                                                    <h6 className="rBlack txt-azul-marino pt-1">Enviar</h6>
                                                </button>
                                                </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-2"></div>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.appBar + 1 }}
                open={isLoading}                          
            >               
            <img src={logo_balnco} alt="PRADO'S" className="img-fluid p-3 img-client" width="300" />
            <CircularProgress color="primary" size={80} />                
            </Backdrop> 
                    </motion.div>
                    
    )
}