import React, { useEffect, useState } from "react";
import "../../../css/style.css";
import logo from "../../../img/logo.png";
import { TramiteCreditoContext } from "../../../context/TramiteCreditoProvider";
import { UserContext } from "../.././../context/UserProvider";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import logo_pabs2 from "../../../img/logo_pabs.png";
import icono_mujer from "../../../img/icono_mujer.png";
import Navbar from '../../front/NavbarI';
import Footer from '../../front/FooterI';
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "800px",

  maxHeight: "500px",
  background: "radial-gradient(circle at top left, #005593, #36c9d0)",
  boxShadow: 29,
  overflowY: "auto",
};
export default function LoginWithoutOption() {
  const { userMain, updateUser } = React.useContext(UserContext);
  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);
  const [creditoE, setCreditoE] = useState(null);
  const setCredit = (credit) => {
    updateUser({
      ...userMain,
      creditType: credit,
    });
    if(credit === "personal"){
      return history.push("/Personal?from=Login")
    } else if(credit === "titulo"){
      return history.push("/Titulo?from=Login")
    }
  };
  const history = useHistory();
  const [open2, setOpen2] = React.useState(true);
  const handleClose = () => setOpen2(false);
  return (
    
    <div className="blue-background-mess">
    <Navbar />
    <Modal
        keepMounted
        open={true}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* //<ScrollView width="100%" height="100%"> */}
        <Grid container spacing={2} sx={style}>
          <div className="modal-content">
            <div className="d-flex justify-content-end p-1  ">
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-center mb-3">
                <img
                  src={logo}
                  alt="PRADO'S"
                  width="220"
                  className="img-fluid"
                />
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center">
                    <img
                      src={logo_pabs2}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      ¿Cuentas con un <br /> título PABS?{" "}
                    </h4>
                    <h5 className="rLight mt-4">
                      Tu crédito ya está <br />
                      Pre-autorizado
                    </h5>

                    <div className="mt-4">
                      <a
                        className="btn btn-primary btn-conoce-azul"
                        onClick={() => setCredit("titulo")}
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                    <div className="mt-4">
                      <p className="rLight mt-4">
                        ¿Qué es un Título PABS? <br />
                        <a
                          href="https://www.pabsmr.org/que-es-un-titulo-pabs/"
                          target="_blank"
                          style={{ color: "white" }}
                        >
                          Click aqui
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center">
                    <img
                      src={icono_mujer}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Quiero un <br /> crédito personal{" "}
                    </h4>
                    <h5 className="rLight mt-4">
                      Tu crédito en <br />
                      menos de 24 hrs
                    </h5>

                    <div className="mt-4">
                      <a
                        className="btn btn-primary btn-conoce-azul"
                        onClick={() => setCredit("personal")}
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Modal>
    <Footer />
</div>
  );
}