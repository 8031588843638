import React, { useEffect, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import logo_balnco from "../../../img/logo_blanco.png";

const style = {
  background: "radial-gradient(circle at top left, #005593, #36c9d0)",
};
const style2 = {
  borderRadius: "15px",
  padding: "12px",
};
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AlertAccount = (props: any) => {
  const [open, setOpen] = useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (props.open !== undefined) {
      setOpen(props.open);
    }
  }, [props]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      //onClose={submit}
      //fullScreen={fullScreen}
      maxWidth={maxWidth}
      aria-describedby="alert-dialog-slide-description"
      sx={style2}
    >
      <DialogContent sx={style}>
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-center mb-1">
              <img
                src={logo_balnco}
                alt="PRADO'S"
                className="img-fluid p-2 img-client mt-3 mb-2"
                width="200"
              />
            </div>
            <div className="text-white text-center">
              <h3 className="mt-2 text-tittle">¡Felicidades!</h3>
              <h4 className="mt-2">
                Ya tienes <span className="text-blue-light">una cuenta</span>
              </h4>
              <div className="d-flex justify-content-center mt-3 mb-2">
                <button className="btn btn-continue mt-2" onClick={handleClose}>
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AlertAccount;
