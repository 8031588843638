import React, { useEffect } from "react";
import checked from "../../../../../assets/circle-checked.png";
import { Field, Formik, Form, useFormik, ErrorMessage } from "formik";
import ApiClient, { PabsPayment } from "../../../../services/apiclient";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import logo_balnco from "../../../../img/logo_blanco.png";
import fondo_1 from "../../../../img/fondo_1.png";
import fondo_2 from "../../../../img/fondo_2.png";
import paso_1 from "../../../../img/paso_1.png";
import paso_2 from "../../../../img/paso_2.png";
import logo_pabs from "../../../../img/logo_pabs.png";
import { motion } from "framer-motion";
import { PreTramiteCreditoContext } from "../../../../context/PreTramiteCreditoProvider";

function Aceptado({
  nextStep,
  loanAmount,
  userData,
  setUserData,
  productName,
  setProductName,
  setProductAmount,
}) {
  const apiClient = ApiClient.getInstance();
  const history = useHistory();

  const [options, setOptions] = useState<PabsPayment[]>([]);
  const [cantidades, setCantidades] = useState([]);
  const [upper, setUpper] = useState(0);
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );

  // Redirect to /Veriff if user is not verified
  // useEffect(() => {
  //   const checkUserVeriffValidated = async () => {

  //     const isValidatedRes = await apiClient.checkUserVeriffValidated();
  //     if(isValidatedRes.veriffValidated !== true) {
  //         history.push('/veriff?from=PABS');
  //         return;
  //     }

  //   };
  //   const timeout = setTimeout(() => {
  //     checkUserVeriffValidated();
  //   }, 500);

  //   return () => {
  //     clearTimeout(timeout);
  //   }
  // }, []);
  const cantidadesServer = async () => {
    const data = await apiClient
      .getProducts(
        parseInt(localStorage.getItem("upper")),
        parseInt(localStorage.getItem("lower"))
      )
      //   console.log(data)
      //   const arr1 = getUniqueListBy(data, 'capital')
      //   setOptions(data);
      //   setCantidades(arr1);
      //   console.log(cantidades)
      .then((data: any) => {
        console.log(data);
        const arr1 = getUniqueListBy(data, "capital");
        setOptions(data);
        setCantidades(arr1);
      });
  };

  useEffect(() => {
    cantidadesServer();
    console.log(cantidades);
    // apiClient.getProducts(parseInt(localStorage.getItem("upper")), parseInt(localStorage.getItem("lower"))).then((data: any) => {

    //   const arr1 = getUniqueListBy(data, 'capital')
    //   setOptions(data);
    //   setCantidades(arr1);
    // })
  }, []);

  function getUniqueListBy(arr, key): any {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const onsubmitForm = (values: any, actions: any) => {
    setProductName(values.amount);

    const amount0 = values.amount.split("/");
    const amount1 = parseInt(amount0[0]);

    setUserData({
      ...values,
      amount: amount1,
    });

    nextStep();
  };
  const [cantidad, setCantidad] = useState();
  const [filtroOpciones, setFiltroOpciones] = useState([]);
  const onchangeProduct = (e) => {
    setCantidad(e.target.value);
    setFieldValue("cantidad", e.target.value);
    setProductAmount(e.target.value);
    const search = options.filter((value) => value.capital == e.target.value);

    setFiltroOpciones(search);
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      cantidad: "",
      amount: "",
    },
    onSubmit: (values) => {
      setProductName(values.amount);
      const amount0 = values.amount.split("/");
      const amount1 = parseInt(amount0[0]);
      userData.amount = amount1;
      // setUserData({
      // ...values,
      // amount: amount1
      // })
      if (localStorage.getItem("preCreditoLocal")) {
        var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
        if (
          credito.idProcessMarketing !== "" &&
          credito.typeLoanMarketing === "Titulo"
        ) {
          var dd = {
            processName: "config-credit-finished",
          };
          updatePreCredito({
            ...preCredito,
            stepMarketing: "config-credit-finished",
          });
          updateMarketing(credito.idProcessMarketing, dd);
        }
      }
      setUserData(userData);
      nextStep();
    },
  });

  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };

  useEffect(() => {
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "credit-configuration-init" &&
        credito.typeLoanMarketing === "Titulo"
      )
        var data = {
          processName: "credit-configuration-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "credit-configuration-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <form onSubmit={handleSubmit}>
        {/* <Formik initialValues={{
        ...userData, cantidad: "", amount:""
        }} onSubmit={onsubmitForm}>
        {({ handleSubmit, errors, touched, values, handleChange }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >   */}
        <div className="blue-background-mess">
          <div className="d-none d-sm-none d-md-block p-0 m-0 ">
            <div className="row">
              <motion.div
                initial={{ y: "50%", opacity: 0, scale: 0.5 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                exit={{ y: "50%", transition: { duration: 0.8 } }}
                transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
                className="col-2"
              >
                <img
                  src={fondo_2}
                  alt="PRADO'S"
                  className="img-fluid img-top-sol8"
                  width="250"
                />
              </motion.div>
              <div className="col-8">
                <div className="d-flex justify-content-center mb-1">
                  <img
                    src={logo_balnco}
                    alt="PRADO'S"
                    className="img-fluid p-2 img-client mt-5 mb-4"
                    width="240"
                  />
                </div>
                {/* <div className="text-white text-center">
                            <h1 className="mt-1 text-tittle">¡Felicidades!</h1>
                            <h4 className="mt-2">Tu crédito ya está Pre-autorizado <br />
                                hasta <span className="text-blue-light">{parseInt(localStorage.getItem('upper')).toLocaleString("en-US", {
                                                                                        style: "currency",
                                                                                        currency: "USD",
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2,
                                                                                    })
                                                                                    }</span></h4>
                        </div> */}
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-2"></div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                    <motion.div
                      initial={{ y: "50%", opacity: 0, scale: 0.5 }}
                      animate={{ y: 0, opacity: 1, scale: 1 }}
                      exit={{ y: "50%", transition: { duration: 0.8 } }}
                      transition={{
                        ease: "easeInOut",
                        duration: 0.8,
                        delay: 0.4,
                      }}
                      className="text-start text-white d-flex mt-3"
                    >
                      <img
                        src={logo_pabs}
                        alt="PRADO'S"
                        className="img-fluid p-2 img-client"
                        width="100"
                      />
                      <h5 className="mt-2">
                        Tu crédito ya está Pre-autorizado <br />
                        hasta{" "}
                        <span className="text-blue-light">
                          {parseInt(
                            localStorage.getItem("upper")
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </h5>
                    </motion.div>
                    <motion.div
                      initial={{ y: "50%", opacity: 0, scale: 0.5 }}
                      animate={{ y: 0, opacity: 1, scale: 1 }}
                      exit={{ y: "50%", transition: { duration: 0.8 } }}
                      transition={{
                        ease: "easeInOut",
                        duration: 0.8,
                        delay: 0.4,
                      }}
                      className="mb-3"
                    >
                      <img
                        src={paso_1}
                        alt="PRADO'S"
                        className="img-fluid p-2 img-client"
                        width="50"
                      />
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label white-tittle"
                      >
                        Elige el monto de tu credito *
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={values.cantidad}
                        onChange={onchangeProduct}
                        id="cantidad"
                        name="cantidad"
                      >
                        <option selected>Seleccione una opcion</option>
                        {cantidades.map((option) => (
                          <option value={option.capital}>
                            $
                            {new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                            }).format(option.capital)}
                          </option>
                        ))}
                      </select>
                    </motion.div>
                    <motion.div
                      initial={{ y: "50%", opacity: 0, scale: 0.5 }}
                      animate={{ y: 0, opacity: 1, scale: 1 }}
                      exit={{ y: "50%", transition: { duration: 0.8 } }}
                      transition={{
                        ease: "easeInOut",
                        duration: 0.8,
                        delay: 0.4,
                      }}
                      className="mb-3"
                    >
                      <img
                        src={paso_2}
                        alt="PRADO'S"
                        className="img-fluid p-2 img-client"
                        width="50"
                      />
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label white-tittle"
                      >
                        Elige el monto de tu pago *
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        id="amount"
                        name="amount"
                        value={values.amount}
                        onChange={handleChange}
                      >
                        <option selected>Seleccione una opcion</option>
                        {filtroOpciones.map((option) => (
                          <option value={option.name}>
                            {option.semanas} pagos semanales de $
                            {new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                            }).format(option.pago_semanal)}{" "}
                            Total a pagar: $
                            {new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                            }).format(option.total_pagado)}
                          </option>
                        ))}
                      </select>
                    </motion.div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-2"></div>
                </div>
                <motion.div
                  initial={{ y: "50%", opacity: 0, scale: 0.5 }}
                  animate={{ y: 0, opacity: 1, scale: 1 }}
                  exit={{ y: "50%", transition: { duration: 0.8 } }}
                  transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
                  className="d-flex justify-content-center mt-2 mb-4"
                >
                  <button className="btn btn-continue-large mt-2" type="submit">
                    CONTINUAR
                  </button>
                </motion.div>
              </div>
              <motion.div
                initial={{ y: "50%", opacity: 0, scale: 0.5 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                exit={{ y: "50%", transition: { duration: 0.8 } }}
                transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
                className="col-2"
              >
                <img
                  src={fondo_1}
                  alt="PRADO'S"
                  className="img-fluid img-top2-sol8"
                  width="260"
                />
              </motion.div>
            </div>
          </div>
          <div className="d-block d-sm-block d-md-none p-0 m-0 ">
            <div className="d-flex justify-content-center mb-1">
              <img
                src={logo_balnco}
                alt="PRADO'S"
                className="img-fluid p-2 img-client mt-5 mb-4"
                width="240"
              />
            </div>
            <div className="text-white text-center container">
              <h1 className="mt-1 text-tittle">¡Felicidades!</h1>
              <h4 className="mt-2">
                Tu crédito ya está Pre-autorizado <br />
                hasta{" "}
                <span className="text-blue-light">
                  {parseInt(localStorage.getItem("upper")).toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </span>
              </h4>

              <div className="mb-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label white-tittle"
                >
                  Elige el monto de tu credito*
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={values.cantidad}
                  onChange={onchangeProduct}
                  id="cantidad"
                  name="cantidad"
                >
                  <option selected>Seleccione una opcion</option>
                  {cantidades.map((option) => (
                    <option value={option.capital}>
                      $
                      {new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                      }).format(option.capital)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label white-tittle"
                  >
                    Elige el monto de tu pago *
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="amount"
                    name="amount"
                    value={values.amount}
                    onChange={handleChange}
                  >
                    <option selected>Seleccione una opcion</option>
                    {filtroOpciones.map((option) => (
                      <option value={option.name}>
                        {option.semanas} pagos semanales de $
                        {new Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 2,
                        }).format(option.pago_semanal)}{" "}
                        Total a pagar: $
                        {new Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 2,
                        }).format(option.total_pagado)}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <select className="form-select" aria-label="Default select example">
                                <option selected>Duración</option>
                            </select>
                        </div> */}
              </div>
              <hr />
              {/* <h4 className="mt-2">Pagos en</h4>
                    <h5>6 meses</h5>
                    <hr />
                    <h4 className="mt-2">Tasa promedio</h4>
                    <h5>42.93%</h5>
                    <hr />
                    <h4 className="mt-2">Tu pago</h4>
                    <h5>$2450</h5>
                    <small>mensual</small>
                    <hr /> */}
              <div className="d-flex justify-content-center mt-2 mb-4">
                <button className="btn btn-continue-large mt-2" type="submit">
                  CONTINUAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* </Form>

        )}
      </Formik> */}
    </motion.div>
  );
}

export default Aceptado;
