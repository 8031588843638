import React, {useEffect, useState} from 'react'
import {  useHistory } from "react-router-dom";
import ApiClient, { Prospective } from "../../../services/apiclient";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useFormik } from "formik";
import * as Yup from "yup";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import AlertMessage from "../../alert.component";
import { UserContext } from "./../../../context/UserProvider";
import Navbar from '../../front/NavbarI';
import Footer from '../../front/FooterI';

export default function Solicitud5() {
    const history = useHistory();
    const apiClient = ApiClient.getInstance();
    const[date,setDate] = useState(null);



    function handleChangeDate(event: any) {
        console.log(event.target.value);
        let date = event.target.value.split('/');
        formik.setFieldValue('dateOfBirth',date[2]+'-'+date[0]+'-'+date[1]);
    }
    const handleBlur = (e) => console.log(e.target.value);

    const formik = useFormik({
    
        initialValues: {
          firstName: "",
          secondName: "",
          lastFName: "",
          lastMName: "",
          dateOfBirth: "",
          state: "",
          nationality: "",
          gender: "",
          email: "",
          phone: "",
          CURP: "",
          RFC: "",
          country: "",
        },
        validationSchema: Yup.object({
          firstName: Yup.string().required("Tienes que ingresar la calle."),
          lastFName: Yup.string().required("Tienes que ingresar la calle."),
          lastMName: Yup.string().required("Tienes que ingresar la calle."),
          dateOfBirth: Yup.string().required("Tienes que ingresar la calle."),
          state: Yup.string().required("Tienes que ingresar la calle."),
          email: Yup.string().required("Tienes que ingresar la calle."),
          phone: Yup.string().required("Tienes que ingresar la calle.").matches(/^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/),
          RFC: Yup.string().required("The terms and conditions must be accepted."),
          CURP: Yup.string().required("The terms and conditions must be accepted."),
          country: Yup.string().required("Tienes que ingresar la calle."),
          nationality: Yup.string().required("Tienes que ingresar la calle."),
        }),
        onSubmit: values => {

        }
      });
    return (
        <div className="grey-background">
            <Navbar />
            <br />
            <br />
            <div className="d-block d-sm-block d-md-none p-0 m-0 mb-5">
                <div className="card card-monto">
                    <div className="container">
                        <h6 className="text-credit text-end p-2">
                            Monto solicitado:
                        </h6>
                        <h2 className="text-light-blue text-end"> $1,200</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                        <div className="card card-monto">
                            <div className="container">
                                <h6 className="text-credit text-end p-2">
                                    Monto solicitado:
                                </h6>
                                <h2 className="text-light-blue text-end"> $1,200</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="text-center">
                        <h3 className="sol1-tittle">Solicitud de préstamo</h3>
                        <p>Por favor, rellena los siguientes campos con tu información</p>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
            {/* STEPS */}
            <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                <div className="d-flex justify-content-center mt-4 container">
                    <button className="circle-step-blue">
                        <h5 className="numbre-step">1</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
                    <button className="circle-step-blue">
                        <h5 className="numbre-step">2</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
                    <button className="circle-step">
                        <h5 className="numbre-step">3</h5>
                    </button>
                </div>
            </div>
            <div className="d-block d-sm-block d-md-none p-0 m-0 ">
                <div className="d-flex justify-content-center mt-4 container">
                    <button className="circle-step-blue">
                        <h5 className="numbre-step">1</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaa</div>
                    <button className="circle-step-blue">
                        <h5 className="numbre-step">2</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaa</div>
                    <button className="circle-step">
                        <h5 className="numbre-step">3</h5>
                    </button>
                </div>
            </div>
            {/* CARDS */}
            <div className="d-flex justify-content-center mt-4 container">
                <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
                    <div className="container">
                        <p className="txt-blue-strong">Rellena los campos vacíos con tus datos</p>
                        <form>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="firstName" className="form-label sol1-tittle">Nombre (s)*</label>
                                        <input 
                                            type="input" 
                                            className={formik.errors.firstName ? " form-control is-invalid" : "form-control"}
                                            id="firstName"
                                            value = {formik.values.firstName} 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                         />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="lastFName" className="form-label sol1-tittle">Apellido paterno *</label>
                                        <input 
                                            type="input" 
                                            className={formik.errors.lastFName ? " form-control is-invalid" : "form-control"}
                                            id="lastFName"
                                            value = {formik.values.lastFName} 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                         />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="secondName" className="form-label sol1-tittle">Segundo nombre *</label>
                                        <input 
                                            type="input" 
                                            className={formik.errors.secondName ? " form-control is-invalid" : "form-control"}
                                            id="secondName"
                                            value = {formik.values.secondName} 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                         />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="lastMName" className="form-label sol1-tittle">Apellido materno *</label>
                                        <input 
                                            type="input" 
                                            className={formik.errors.lastMName ? " form-control is-invalid" : "form-control"}
                                            id="lastMName"
                                            value = {formik.values.lastMName} 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                         />
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-4 mb-4" />
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Fecha de nacimiento *</label>
                                        {/* <div className="row">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-4">
                                                <select className="form-select slect-blue text-light" aria-label="Default select example">
                                                    <option selected>Día</option>
                                                </select>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-4">
                                                <select className="form-select slect-blue text-light" aria-label="Default select example">
                                                    <option selected>Mes</option>
                                                </select>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                                <select className="form-select slect-blue text-light" aria-label="Default select example">
                                                    <option selected>Año</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={3}>
                                                <DatePicker 
                                                disableFuture                                                
                                                //label="Fecha nacimiento"
                                                openTo="day"
                                                toolbarPlaceholder="dff"
                                                toolbarFormat="//"
                                                views={[  'month', 'day', 'year']}
                                                value={date}
                                                onChange= {(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                renderInput={
                                                     (params) => 
                                                        <TextField 
                                                            {...params}
                                                            size="medium"
                                                            color="primary"
                                                            
                                                            name="dateOfBirth"
                                                            onChange={handleChangeDate}
                                                            value={formik.values.dateOfBirth}
                                                            onBlur={handleBlur}
                                              />}
                                                />
                                            </Stack>
                                            </LocalizationProvider>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-4">
                                        <label htmlFor="gender" className="form-label sol1-tittle">Genero *</label>
                                        <br />
                                        <div className="form-check form-check-inline">
                                            <input 
                                            className={formik.errors.gender ? " form-check-input is-invalid" : "form-check-input"}
                                                type="radio" 
                                                name="gender" 
                                                id="gender" 
                                                value="femenino"
                                                checked={formik.values.gender === "femenino" }
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur}
                                                />
                                            <label className="form-check-label" htmlFor="gender">Mujer</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input 
                                                className={formik.errors.gender ? " form-check-input is-invalid" : "form-check-input"}
                                                type="radio" 
                                                name="gender" 
                                                id="gender" 
                                                value="masculino"
                                                checked={formik.values.gender === "masculino" }
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur}
                                                />
                                            <label className="form-check-label" htmlFor="gender">Hombre</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-4 mb-4" />
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="RFC" className="form-label sol1-tittle">RFC*</label>
                                        <input 
                                            type="input" 
                                            className={formik.errors.RFC ? " form-control is-invalid" : "form-control"}
                                            id="RFC"
                                            value = {formik.values.RFC} 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                         />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="CURP" className="form-label sol1-tittle">CURP*</label>
                                        <input 
                                            type="input" 
                                            className={formik.errors.CURP ? " form-control is-invalid" : "form-control"}
                                            id="CURP"
                                            value = {formik.values.CURP} 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                         />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="nationality" className="form-label sol1-tittle">Nacionalidad*</label>
                                        <input 
                                            type="input" 
                                            className={formik.errors.nationality ? " form-control is-invalid" : "form-control"}
                                            id="nationality"
                                            value = {formik.values.nationality} 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                         />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="country" className="form-label sol1-tittle">País de nacimiento*</label>
                                        <select 
                                            className={formik.errors.country ? " form-select slect-blue text-light is-invalid" : "form-select slect-blue text-light"}
                                            aria-label="Default select example"
                                            name="country" 
                                            id="country"
                                            onChange={formik.handleChange}
                                            value={formik.values.country}
                                            onBlur={formik.handleBlur}
                                        >
                                        <option value="" disabled>Seleccione un Pais</option>
                                            <option value="MÉXICO">MÉXICO</option>
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label sol1-tittle">Correo electrónico*</label>
                                        <input 
                                            type="email" 
                                            className={formik.errors.email ? " form-control is-invalid" : "form-control"}
                                            id="email"
                                            value = {formik.values.email} 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                         />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="state" className="form-label sol1-tittle">Estado de nacimiento*</label>
                                        <select 
                                            className={formik.errors.state ? " form-select slect-blue text-light is-invalid" : "form-select slect-blue text-light"}
                                            aria-label="Default select example"
                                            name="state" 
                                            id="state"
                                            onChange={formik.handleChange}
                                            value={formik.values.state}
                                            onBlur={formik.handleBlur}
                                        >
                                        <option value="" disabled>Seleccione un Pais</option>
                                            <option value="Aguascalientes">Aguascalientes</option>
                                            <option value="Baja California">Baja California</option>
                                            <option value="Baja California Sur">
                                                Baja California Sur
                                            </option>
                                            <option value="Campeche">Campeche</option>
                                            <option value="Chiapas">Chiapas</option>
                                            <option value="Chihuahua">Chihuahua</option>
                                            <option value="Coahuila de Zaragoza">
                                                Coahuila de Zaragoza
                                            </option>
                                            <option value="Colima">Colima</option>
                                            <option value="Colima">Distrito Federal</option>
                                            <option value="Durango">Durango</option>
                                            <option value="Guanajuato">Guanajuato</option>
                                            <option value="Guerrero">Guerrero</option>
                                            <option value="Hidalgo">Hidalgo</option>
                                            <option value="Jalisco">Jalisco</option>
                                            <option value="México">México</option>
                                            <option value="Michoacán de Ocampo">
                                                Michoacán de Ocampo
                                            </option>
                                            <option value="Morelos">Morelos</option>
                                            <option value="Nayarit">Nayarit</option>
                                            <option value="Nuevo León">Nuevo León</option>
                                            <option value="Oaxaca">Oaxaca</option>
                                            <option value="Puebla">Puebla</option>
                                            <option value="Querétaro">Querétaro</option>
                                            <option value="Quintana Roo">Quintana Roo</option>
                                            <option value="San Luis Potosí">San Luis Potosí</option>
                                            <option value="Sinaloa">Sinaloa</option>
                                            <option value="Sonora">Sonora</option>
                                            <option value="Tabasco">Tabasco</option>
                                            <option value="Tamaulipas">Tamaulipas</option>
                                            <option value="Tlaxcala">Tlaxcala</option>
                                            <option value="Veracruz de Ignacio de la Llave">
                                                Veracruz de Ignacio de la Llave
                                            </option>
                                            <option value="Yucatán">Yucatán</option>
                                            <option value="Zacatecas">Zacatecas</option>
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label sol1-tittle">Número de teléfono*</label>
                                        <input 
                                            type="input" 
                                            className={formik.errors.phone ? " form-control is-invalid" : "form-control"}
                                            id="phone"
                                            value = {formik.values.phone} 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                         />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-5 mb-4">
                                <button type="submit"  className="btn btn-shadow-2">
                                    Continuar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
