import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import logo_balnco from "../../../img/logo_blanco.png";
import Navbar from "../../front/NavbarI";
import Footer from "../../front/FooterI";
import { DiremovilContext } from "./../../../context/UserDiremovilProvider";
import AlertMessage from "../../alert.component";
import ApiClient from "../../../services/apiclient";
import { PABS_ACTIVE, PERSONAL_ACTIVE } from "../../../utils/config";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";
import { useAnimation, useInView } from "framer-motion";
function LoginDiremovil() {
  const ref = useRef(null);
  const inView = useInView(ref);
  const animation = useAnimation();
  const history = useHistory();

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);

  const { userDiremovil, updateUserDiremovil } = React.useContext(DiremovilContext);

  const [shown, setShown] = React.useState(false);

  const [user, setUser] = useState({
    user: "",
    phone: "",
    password: "",
  });

  const apiClient = ApiClient.getInstance();


  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const userLogin = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoading(true);

    if (user.phone === "") {
      setOpenMessage(true);
      setMessage(
        "El número de teléfono del usuario es requerido. Favor de verificar"
      );
      setSeverity("error");
      setIsLoading(false);
      return;
    }

    if (user.password === "") {
      setOpenMessage(true);
      setMessage("La contraseña del usuario es requerido. Favor de verificar");
      setSeverity("error");
      setIsLoading(false);
      return;
    }

    apiClient
      .loginDiremovil(user.phone, user.password)
      .then((res) => res.json())
      .then(async (data) => {
        if (data.error) {
          localStorage.clear();
          setOpenMessage(true);
          const statusCode = data.error?.status || data.error.statusCode;
          setMessage(
            statusCode === 401
              ? "Telefono o contraseña invalidos"
              : data.error.message
          );
          setSeverity("error");
          setIsLoading(false);
          return;
        }
        updateUserDiremovil({
          ...userDiremovil,
          nombre: data.fullName || data.fullName,
          userId: data.userId,
        });
        return history.push("/solicitud-monto-diremovil")
      });
  };

  const handleClose = () => {
    setOpenMessage(false);
  };

  useEffect(() => {
    if (inView) {
      console.log(1);
      animation.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.8 },
      });
    }
    if (!inView) {
      animation.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
  }, [inView]);

  const switchShown = () => setShown(!shown);
  return (
    <motion.div
      className="blue-background-diremovil"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <Navbar />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div>
        <div className="row">
          <div className="col-2"></div>

          <motion.div animate={animation} className="col-8" ref={ref}>
            <form className="col-12" onSubmit={userLogin}>
              <div className="d-flex justify-content-center mb-1">
                <img
                  src={logo_balnco}
                  alt="PRADO'S"
                  className="img-fluid p-2 img-client mt-5 mb-4"
                  width="240"
                />
              </div>
              <div className="text-white text-center">
                <h4 className="mt-2">Ingresa usuario y contraseña</h4>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-3"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label white-tittle">
                      Número de teléfono
                    </label>
                    <input
                      className="form-control"
                      name="phone"
                      placeholder=""
                      aria-label="Default select example"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="password"
                      className="form-label white-tittle"
                    >
                      Contraseña
                    </label>
                    <input
                      className="form-control"
                      name="password"
                      type={shown ? "text" : "password"}
                      placeholder=""
                      aria-label="Default select example"
                      onChange={handleInputChange}
                    />
                    <a
                      className="a_none_style text-black"
                      href="/reset-password"
                    >
                      ¿Olvidaste tu contraseña?
                    </a>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-3"></div>
              </div>
              <div className="d-flex justify-content-center mt-2 mb-5">
                <button
                  className="btn btn-continue-large mt-5"
                  onClick={userLogin}
                >
                  Iniciar sesión
                </button>
              </div>
            </form>
          </motion.div>

          <div className="col-2"></div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid p-3 img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
      </Backdrop>
      <Footer />
    </motion.div>
  );
}
export default LoginDiremovil;
