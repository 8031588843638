import React, { useState } from "react";
import logo_balnco from "../../../img/logo_blanco.png";
import fondo_1 from "../../../img/fondo_1.png";
import fondo_2 from "../../../img/fondo_2.png";
import Navbar from "../../front/NavbarI";
import Footer from "../../front/FooterI";
import { useHistory } from "react-router-dom";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
const style = {
  background: "radial-gradient(circle at top left, #005593, #36c9d0)",
};
const style2 = {
  //border: "2px solid white",
  borderRadius: "15px",
  padding: "12px",
};
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Solicitud4() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  function redirect() {
    history.push("/prestamo-personal");
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const submit = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="blue-background-mess">
      <Navbar />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={submit}
        //fullScreen={fullScreen}
        maxWidth={maxWidth}
        aria-describedby="alert-dialog-slide-description"
        sx={style2}
      >
        <DialogContent sx={style}>
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center mb-1">
                <img
                  src={logo_balnco}
                  alt="PRADO'S"
                  className="img-fluid p-2 img-client mt-3 mb-2"
                  width="200"
                />
              </div>
              <div className="text-white text-center">
                <h3 className="mt-2 text-tittle">¡Felicidades!</h3>
                <h4 className="mt-2">
                  Ya tienes <span className="text-blue-light">una cuenta</span>
                </h4>
                <div className="d-flex justify-content-center mt-3 mb-2">
                  <button
                    className="btn btn-continue mt-2"
                    onClick={handleClose}
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <div className="d-none d-sm-none d-md-block p-0 m-0 ">
        <div className="row">
          <div className="col-3">
            <img
              src={fondo_2}
              alt="PRADO'S"
              className="img-fluid img-top"
              width="250"
            />
          </div>
          <div className="col-6">
            <div className="d-flex justify-content-center mb-3">
              <img
                src={logo_balnco}
                alt="PRADO'S"
                className="img-fluid p-2 img-client mt-5 mb-4"
                width="240"
              />
            </div>
            <div className="text-white text-center">
              <h1 className="mt-5 text-tittle">¡Felicidades!</h1>
              <h4 className="mt-5">
                Ya tienes <span className="text-blue-light">una cuenta</span>
              </h4>
              <div className="d-flex justify-content-center mt-5 mb-4">
                <button className="btn btn-continue mt-5" onClick={redirect}>
                  Continuarz
                </button>
                <button
                  className="btn btn-continue mt-5"
                  onClick={handleClickOpen}
                >
                  abrir
                </button>
              </div>
            </div>
          </div>
          <div className="col-3">
            <img
              src={fondo_1}
              alt="PRADO'S"
              className="img-fluid img-top2"
              width="260"
            />
          </div>
        </div>
      </div>
      <div className="d-block d-sm-block d-md-none p-0 m-0 ">
        <div className="d-flex justify-content-center mb-3">
          <img
            src={logo_balnco}
            alt="PRADO'S"
            className="img-fluid p-2 img-client mt-5 mb-4"
            width="240"
          />
        </div>
        <div className="text-white text-center">
          <h1 className="mt-5 text-tittle">¡Felicidades!</h1>
          <h4 className="mt-5">
            Ya tienes <span className="text-blue-light">una cuenta</span>
          </h4>
          <div className="d-flex justify-content-center mt-5 mb-4">
            <button className="btn btn-continue mt-5 mb-5" onClick={redirect}>
              Continuar
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
