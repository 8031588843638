import React,{useState, useEffect} from 'react'
import logo_balnco from '../../../img/logo_blanco.png';
import fondo_1 from '../../../img/fondo_1.png';
import fondo_2 from '../../../img/fondo_2.png';
import Navbar from '../../front/NavbarI';
import Footer from '../../front/FooterI';
import {  useHistory } from "react-router-dom";
import { UserContext } from "./../../../context/UserProvider";
import * as Yup from "yup";
import { useFormik} from "formik";
import ApiClient from "../../../services/apiclient";
import AlertMessage from "../../alert.component";
import {motion} from "framer-motion";

export default function ResetPasswordCode() {
    const history = useHistory();
    const apiClient = ApiClient.getInstance();
    const[isLoading,setIsLoading] = useState(false);
    const[date,setDate] = useState(null);
    const [shown, setShown] = React.useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [ciudades, setCiudades] = useState([]);
    const [login,setLogin] = React.useState(false);
    const { userMain, updateUser } = React.useContext(UserContext);
    const [phoneStatus, setPhoneStatus] = React.useState(true)
    const [sendCode,setSendCode] = React.useState(false);
    const [codeValidated, setCodeValidated] = React.useState(false)
    const[code, setCode] = React.useState('')
    function redirect() {
        history.push("/prestamo-personal")
    }
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenMessage(false);
      };
    function handleChangeCode(e) {
    
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        // Check if they hit the max character length
        if (value.length >= maxLength) {
          // Check if it's not the last input field
          if (parseInt(fieldIndex, 10) < 6) {
            // Get the next input field
            const nextSibling = document.querySelector(
              `input[name=val-${parseInt(fieldIndex, 10) + 1}]`
            ) as HTMLElement | null;
    
            // If found, focus the next field
            if (nextSibling !== null) {
              nextSibling.focus();
            }
          } else if(parseInt(fieldIndex, 10) === 6){
            setFieldValue([`val${fieldIndex}`].toString(), value)
            handleSubmitCode(value);
          }
        }
          setFieldValue([`val${fieldIndex}`].toString(), value)
      }
      function handleSubmitCode(value: number){
        let code = values.val1 + values.val2 + values.val3 + values.val4 + values.val5 + value.toString();
        setCode(code)
        apiClient.resetPasswordValue(code).then(res => {
            setOpenMessage(true);
            setMessage('Su codigo es correcto');
            setCodeValidated(true)
      
        }).catch(error => {
          console.log("err",error);
          setOpenMessage(true);
          setMessage('Ocurrio un error al enviar la petición, verifique el codigo de verificación');
          setSeverity('error');
        });;
      }
      const { 
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue
    
        }= useFormik({
        initialValues: {
          password: "",
          confirmPassword: "",
          val1: "",
          val2: "",
          val3: "",
          val4: "",
          val5: "",
          val6: "",
        },
        validationSchema: Yup.object({
          password: Yup.string().required("Tienes que ingresar la calle."),
          confirmPassword: Yup.string()
          .required("Please confirm your password")
          .oneOf([Yup.ref('password'), null], "Passwords don't match."),
        }),
        onSubmit: values => {
            setIsLoading(true)
            let code = values.val1 + values.val2 + values.val3 + values.val4 + values.val5 + values.val6;
            const data = {
                password: values.password
            }
            apiClient.resetPasswordChange(data,code).then(res => {
                setOpenMessage(true);
                setMessage('El password se ha actualizado');
          
                 setTimeout( () => {
                    history.push('/login');
                }, 3000); 
          
              })
              .catch(error => {
                setOpenMessage(true);
                setMessage('Ocurrio un error al enviar la petición');
                setSeverity('error');
              });
            
            // apiClient.activateAccountSMS(code).then(data => {
            //     setOpenMessage(true);
            //     setMessage('Tu cuenta ha sido activada');
            //     console.log(localStorage.getItem('cxdc') , localStorage.getItem('fwcd'))
            //     if(localStorage.getItem('cxdc') && localStorage.getItem('fwcd')){
            //       console.log(data)
            //     apiClient
            //     .login(localStorage.getItem('cxdc'),localStorage.getItem('fwcd'))
            //     .then((res) => res.json())
            //     .then(async (a) => {
            //       localStorage.removeItem('cxdc')
            //       localStorage.removeItem('fwcd')
            //       if (a.error) {
            //         localStorage.clear();
            //         setOpenMessage(true);
            //         const statusCode = a.error?.status || a.error.statusCode;
            //         setMessage(statusCode === 401 ? 'Telefono o contraseña invalidos' : a.error.message);
            //         setSeverity("error");
            //       }
          
            //       localStorage.setItem("TOKEN", a.token);
            //       localStorage.setItem("USER_ID", a.userId);
          
            //       apiClient.token = a.token;
            //       apiClient.userId = a.userId;
                  
            //       if (a.fullName) {
            //         updateUser({
            //           ...userMain,
            //           nombre: a.fullName || a.fullName,
            //           telefono: a.phone,
            //         });
            //       } else {
            //         updateUser({
            //           ...userMain,
            //           telefono: a.phone,
            //         });
            //       }
                       
            //     })
            //     }
            //     //return history.push("/login");
            //   }).catch(reason => {
            //     setOpenMessage(true);
            //     setMessage(reason.message);
            //     setSeverity('error');
            //   });
            
        }
      });
     
    return (
        <motion.div
        initial={{  opacity:0 }}
          animate={{opacity:1 }}
          exit={{opacity:0}}
          transition={{ ease: "easeInOut", duration: 0.6 }}
           className="blue-background-mess">
            <Navbar />
            <AlertMessage
              open={openMessage}
              message={message}
              handleClose={handleClose}
              severity={severity}
            />
            <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                <div className="row">
                    <div className="col-3">
                        <img src={fondo_2} alt="PRADO'S" className="img-fluid img-top" width="250" />
                    </div>
                    <div className="col-6">
                        
                    <form onSubmit={handleSubmit} className="col-12" >
                        <div className="d-flex justify-content-center mb-3">
                            <img src={logo_balnco} alt="PRADO'S" className="img-fluid p-2 img-client mt-5 mb-4" width="240" />
                        </div>
                        <div className="text-white text-center">
                            <h4 className="mt-2 mb-3">Recupera tu contraseña</h4>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">   
                                    <div>
                                    <div className="text-center mt-1">
                                    <p>Por favor, ingresa el código que te enviamos a tu celular:</p>
                                    <p className="sol1-tittle text-white">Código</p>
                                    </div>
                                <div className="d-flex justify-content-center">
                                    <div className="p-2">
                                        <input 
                                            type="string" 
                                            className="form-control input-number " 
                                            id="val-1" 
                                            aria-describedby="emailHelp"
                                            name="val-1"
                                            onChange={handleChangeCode}
                                            maxLength={1}
                                            value={ values.val1} 
                                            />
                                    </div>
                                    <div className="p-2">
                                        <input 
                                            type="string" 
                                            className="form-control input-number" 
                                            id="val-2" 
                                            aria-describedby="emailHelp"
                                            name="val-2"
                                            onChange={handleChangeCode}
                                            maxLength={1}
                                            value={ values.val2}
                                            />
                                            
                                    </div>
                                    <div className="p-2">
                                        <input 
                                            type="string" 
                                            className="form-control input-number" 
                                            id="val-3" 
                                            aria-describedby="emailHelp"
                                            name="val-3"
                                            onChange={handleChangeCode}
                                            maxLength={1}
                                            value={ values.val3}
                                            
                                             />
                                    </div>
                                    <div className="p-2">
                                        <input 
                                            type="string" 
                                            className="form-control input-number" 
                                            id="val-4" 
                                            aria-describedby="emailHelp"
                                            name="val-4"
                                            onChange={handleChangeCode}
                                            maxLength={1}
                                            value={ values.val4}
                                            
                                             />
                                    </div>
                                    <div className="p-2">
                                        <input 
                                            type="string" 
                                            className="form-control input-number" 
                                            id="val-5" 
                                            aria-describedby="emailHelp"
                                            name="val-5"
                                            onChange={handleChangeCode}
                                            maxLength={1}
                                            value={ values.val5}
                                            
                                             />
                                    </div>
                                    <div className="p-2">
                                        <input 
                                            type="string" 
                                            className="form-control input-number" 
                                            id="val-6" 
                                            aria-describedby="emailHelp"
                                            name="val-6"
                                            onChange={handleChangeCode}
                                            maxLength={1}
                                            value={ values.val6}
                                            
                                             />   
                                    </div>
                                </div>
                                </div>
                                {codeValidated === true  ? (
                                    <div>
                                        <div className="mb-3 mt-4">
                                    <input 
                                        className="form-control" 
                                        name="password" 
                                        placeholder="Ingresa tu nueva contraseña" 
                                        aria-label="Default select example" 
                                        value={values.password} 
                                        onChange={handleChange}
                                        />
                                </div>
                                <div className="mb-3 mt-3">
                                    
                                    <input 
                                        className="form-control" 
                                        name="confirmPassword" 
                                        placeholder="Confirma tu contraseña" 
                                        aria-label="Default select example"
                                        value={values.confirmPassword} 
                                        onChange={handleChange}
                                        />
                                </div>
                                <div className="d-flex justify-content-center mt-2 mb-5">
                                    <button className="btn btn-continue-large mt-5" type="submit" >
                                        Continuar
                                    </button>
                                </div>
                                    </div>
                                ) : ''}
                                
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                            </div>
                        </div>
                    </form>
                    </div>
                    <div className="col-3">
                        <img src={fondo_1} alt="PRADO'S" className="img-fluid img-top2" width="260" />
                    </div>
                </div>
            </div>
            <div className="d-block d-sm-block d-md-none p-0 m-0 ">
                <div className="d-flex justify-content-center mb-3">
                    <img src={logo_balnco} alt="PRADO'S" className="img-fluid p-2 img-client mt-5 mb-4" width="240" />
                </div>
                <div className="text-white text-center">
                <form onSubmit={handleSubmit} className="col-12" >
                        <div className="text-white text-center">
                            <h4 className="mt-2 mb-3">Recupera tu contraseña</h4>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">   
                                    <div>
                                    <div className="text-center mt-1">
                                    <p>Por favor, ingresa el código que te enviamos a tu celular:</p>
                                    <p className="sol1-tittle text-white">Código</p>
                                    </div>
                                <div className="d-flex justify-content-center">
                                    <div className="p-2">
                                        <input 
                                            type="string" 
                                            className="form-control input-number " 
                                            id="val-1" 
                                            aria-describedby="emailHelp"
                                            name="val-1"
                                            onChange={handleChangeCode}
                                            maxLength={1}
                                            value={ values.val1} 
                                            />
                                    </div>
                                    <div className="p-2">
                                        <input 
                                            type="string" 
                                            className="form-control input-number" 
                                            id="val-2" 
                                            aria-describedby="emailHelp"
                                            name="val-2"
                                            onChange={handleChangeCode}
                                            maxLength={1}
                                            value={ values.val2}
                                            />
                                            
                                    </div>
                                    <div className="p-2">
                                        <input 
                                            type="string" 
                                            className="form-control input-number" 
                                            id="val-3" 
                                            aria-describedby="emailHelp"
                                            name="val-3"
                                            onChange={handleChangeCode}
                                            maxLength={1}
                                            value={ values.val3}
                                            
                                             />
                                    </div>
                                    <div className="p-2">
                                        <input 
                                            type="string" 
                                            className="form-control input-number" 
                                            id="val-4" 
                                            aria-describedby="emailHelp"
                                            name="val-4"
                                            onChange={handleChangeCode}
                                            maxLength={1}
                                            value={ values.val4}
                                            
                                             />
                                    </div>
                                    <div className="p-2">
                                        <input 
                                            type="string" 
                                            className="form-control input-number" 
                                            id="val-5" 
                                            aria-describedby="emailHelp"
                                            name="val-5"
                                            onChange={handleChangeCode}
                                            maxLength={1}
                                            value={ values.val5}
                                            
                                             />
                                    </div>
                                    <div className="p-2">
                                        <input 
                                            type="string" 
                                            className="form-control input-number" 
                                            id="val-6" 
                                            aria-describedby="emailHelp"
                                            name="val-6"
                                            onChange={handleChangeCode}
                                            maxLength={1}
                                            value={ values.val6}
                                            
                                             />   
                                    </div>
                                </div>
                                </div>
                                {codeValidated === true  ? (
                                    <div>
                                        <div className="mb-3 mt-4">
                                    <input 
                                        className="form-control" 
                                        name="password" 
                                        placeholder="Ingresa tu nueva contraseña" 
                                        aria-label="Default select example" 
                                        value={values.password} 
                                        onChange={handleChange}
                                        />
                                </div>
                                <div className="mb-3 mt-3">
                                    
                                    <input 
                                        className="form-control" 
                                        name="confirmPassword" 
                                        placeholder="Confirma tu contraseña" 
                                        aria-label="Default select example"
                                        value={values.confirmPassword} 
                                        onChange={handleChange}
                                        />
                                </div>
                                <div className="d-flex justify-content-center mt-2 mb-5">
                                    <button className="btn btn-continue-large mt-5" type="submit" >
                                        Continuar
                                    </button>
                                </div>
                                    </div>
                                ) : ''}
                                
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </motion.div>
    )
}
