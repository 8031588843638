import { render } from '@testing-library/react';
import React from 'react';
import avisopng from './avisoimg.png'

export default function AvisoPrivacidadView(){
    return(
        <>
            <div className="sol1-tittle text-center">
                <h1>Aviso de privacidad</h1>
            </div>

            <div style={{paddingRight: '5rem',paddingLeft: '5rem',textAlign: 'justify'}}>
    

            
                <br /><br /> <b> Responsable. </b> Probenso Group, Sociedad Anónima, Sociedad Financiera de Objeto
            Múltiple, Entidad No Regulada (E.N.R.), con domicilio en Ures Sur 137, Colonia Centro,
            Tepic, 63000, Nayarit, México, es el responsable de sus datos personales.

                <br /><br /> <b>Comunicaciones.</b>Cualquier asunto con respecto al presente Aviso de Privacidad, deberá
            ser comunicado vía correo electrónico enviado a la dirección denominada privacidad@probenso.com
            <br /><br />

            <b>Datos Recabados.</b>Te informamos que:
            <br /><br />

                <b> A.  Directamente.</b>  Recabamos directamente los siguientes datos personales: (i) 
                Nombre completo, (ii) Apellido materno, (iii) Apellido paterno, (iv) Género, (v) Fecha
                y lugar de nacimiento, (vi) Domicilio, (vii) Identificación oficial, (viii) Nacionalidad, (ix) 
                Nivel de estudios, (x) Registro Federal de Contribuyentes (RFC), (xi) Cédula Única
                de Registro de Población (CURP), (xii) Correo electrónico, (xiii) Número de teléfono
                celular y número de teléfono fijo, (xiv) Comprobante de ingresos, (xv) Datos 
                laborales, (xvi) Dependientes económicos y (xvii) Geolocalización. 

            <br /><br /> <b>B. Fuentes de Acceso público:</b>Recabamos de fuentes de acceso público si te
            encuentras publicado en las listas: (i) de Prevención de Operaciones con Recursos
            de Procedencia Ilícita y Financiamiento al Terrorismo, Inhabilitados para ejercer el
            comercio, cargo público o participar en Licitaciones, Adjudicaciones e Invitaciones,
            Sujetos a Procesos penales y sentenciados; (ii) de contribuyentes a los que se
            refiere al artículo 69-B del Código Fiscal de la Federación; (iii) publicadas por la
            OFAC (Office of Foreign Asset Control), o; (iv) de personas prohibidas publicada por
            la Comisión Nacional Bancaria y de Valores.
            <br /><br /> <b>C. Datos Recibidos de Terceros:</b> Recabamos tu historial crediticio de Círculo de
            Crédito, S.A de C.V.

            <br /><br /> <b> Finalidades del tratamiento.</b>


            <br /><br /> <b>Finalidades Indispensables.</b> Utilizaremos tus datos personales para los siguientes fines 
            indispensables: (i) Crear tu cuenta de usuario en la plataforma alojada en 
            https://app.percapita.mx/; (ii) Identificarte como usuario de la plataforma alojada en 
            https://app.percapita.mx/; (iii) Generar el expediente de solicitud de tu crédito; (iv) Consultar tu 
            historial y capacidad crediticia; (v) Decidir si se aprueba o rechaza tu solicitud de crédito, 
            y; (vi) Firmar los contratos de crédito que sean aprobados. 
            <br /><br /> <b></b>Estos fines no requieren de tu consentimiento, de conformidad con la fracción IV del artículo
            10 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
            <br /><br /> <b>Finalidades Adicionales.</b> Además, utilizaremos tus datos personales para:
            <br /><br /> <b>A. </b>Mejorar la calidad de nuestros servicios, sistemas informáticos y programas de 
            cómputo. En este caso, tus datos se someterán a un procedimiento previo de 
            disociación (e.g datos agregados y anonimizados). 
            <br /><br /> <b></b>Estos fines no requieren de tu consentimiento, de conformidad con la fracción III de 
            la Ley Federal de Protección de Datos Personales en Posesión de los Particulares. 

            <br /><br /> <b>B.</b> Fines mercadotécnicos, publicitarios o de prospección comercial de nuestros 
            productos o servicios, o de cualquier producto o servicio ofrecido por alguna persona 
            integrante de nuestro grupo comercial.
            <br /><br /> <b></b>Si manifiestas tu consentimiento para que tratemos tus datos personales para estos 
            fines, da click aquí ___. Puedes revocar el consentimiento para que se trate tus 
            datos personales para los fines señalados, enviándonos un correo electrónico a 
            privacidad@probenso.com , en cualquier tiempo. 
            <br /><br />En cualquier momento, podrás limitar el tratamiento que demos a tus datos personales para 
            fines adicionales siguiendo el procedimiento que se establece más adelante en el apartado 
            denominado <b>“Límite al uso y divulgación”. </b>
            <br /><br /> <b>Transferencias a terceros. </b>Transferiremos datos personales a terceros en los siguientes 
            casos y con las siguientes finalidades:
            <br /><br />
            <div className="avisopng">
            <img src={avisopng} alt="" />
            </div>

            <br /><br /> <b></b>Estas transferencias no necesitan de tu consentimiento de acuerdo con las fracciones I y 
            IV del artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de 
            Particulares. 


            <br /><br /> <b>Derechos ARCO. </b>Tú eres el titular de tus datos personales. Por lo tanto, tienes derecho de 
            ejercer, en cualquier tiempo y cuantas veces quieras, tus derechos de Acceso, 
            Rectificación, Cancelación y/u Oposición (en adelante derechos ARCO) al tratamiento de 
            tus datos personales. Puedes ejercer cualquiera de tus derechos ARCO enviando un correo 
            electrónico a privacidad@probenso.com y siguiendo el procedimiento publicado <a href="/derechos-arco">aquí.</a>  
            <br /><br /> <b>Revocación del consentimiento.</b> En todo momento podrás revocar tu consentimiento para 
            que tratemos tus datos personales. Para hacerlo deberás: 1) Enviar un correo electrónico 
            a privacidad@probenso.com y; 2) seguir el procedimiento publicado aquí.
            <br /><br /> <b>Límite al uso y divulgación.</b> Para limitar el uso que hacemos de tus datos personales,
            envíanos un correo electrónico a privacidad@probenso.com También, podrás usar los mecanismos para 
            “desuscribirte” de nuestros correos electrónicos publicitarios que incluiremos en cada correo 
            que te enviemos.
            <br /><br /> <b>Cookies.</b> Nuestros sitios web (páginas de internet) y los correos electrónicos que te 
            enviamos pueden contener tecnologías como “cookies” o “web beacons”. Estas tecnologías 
            nos sirven para recabar datos relacionados con tu comportamiento en línea. Utilizamos 
            esos datos para: (i) Brindarte una experiencia de navegación personalizada a través de 
            nuestras páginas de internet y (ii) Realizar estadísticas internas para conocer el movimiento 
            y flujo de visitantes dentro de nuestros sitios web. Los datos personales que obtenemos de 
            esta forma son: dirección IP, horarios y tiempo de navegación en nuestra página de internet, 
            secciones o micro sitios visitados, sitios web que visitaste antes de acceder al nuestro o 
            ligas o imágenes en las que “diste clic” para entrar en nuestro sitio. No compartimos ninguno 
            de estos datos con persona alguna. Tu puedes deshabilitar en cualquier momento este tipo 
            de tecnologías, para hacerlo puedes revisar la sección de ayuda de tu navegador de 
            internet. De igual forma, puedes eliminar estas tecnologías en cualquier momento, para 
            hacerlo, consulta el manual de usuario del sistema operativo que utilices. Si desactivas o 
            deshabilitas estas tecnologías, es probable que no puedas disfrutar o aprovechar de 
            nuestros recursos, sistemas o contenidos en línea.
            <br /><br /> <b>Modificaciones al aviso de privacidad.</b> Nos reservamos el derecho de modificar el 
            presente aviso de privacidad en cualquier tiempo y sin previo aviso. La versión vigente del 
            aviso de privacidad estará publicada en https://app.percapita.mx/aviso-de-privacidad. En caso de que 
            sea necesario poner a tu disposición un nuevo aviso de privacidad u obtener nuevamente 
            tu consentimiento de conformidad con la legislación y normatividad aplicable en la materia, 
            podremos a tu disposición los medios necesarios para que, de forma ágil, segura y gratuita, 
            puedas manifestar o negar tu consentimiento.
            <br /><br /> <b>Información sobre el INAI.</b>
            Si sientes que hemos violado alguno de tus derechos puedes 
            formular una queja directamente ante el Titular del Departamento de Datos Personales 
            enviando un correo electrónico a privacidad@probenso.com o interponer una 
            denuncia ante el Instituto Nacional de Acceso a la Información y Protección de Datos (INAI), 
            a través del Procedimiento de Protección de Derechos.
            <br /><br /> <b></b>
            <br /><br /> <b></b>




            </div>
        </>
    )
}