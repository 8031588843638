import {
    NEXT_NAV
} from "../types";

const initialState = {
	nav:0,
};

const navReducer = (state = initialState, action) => {
	switch (action.type) {
		case NEXT_NAV:
			return {
				...state,
				nav: action?.payload
			};
		
        default:
			return state;
	}
};

export default navReducer;