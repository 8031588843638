import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarExport, GridToolbarContainer  } from '@mui/x-data-grid';
import Box from '@mui/material/Box';


const  DataTable = (props: any) =>{
  const [width, setWidth]   = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    }
  
  
    React.useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  function screen(width: any) {
    //let height = 0;
    console.log(width)
    let height = width <= 1099 ? 5 : width >= 1100 && width <= 1200 ? 10 : width >= 1201 && width <= 1700 ? 8 : width >= 1701  ? 7 : 0;
    //alert(height)
    // switch(width){
    //   case  true ://width >= 1100 && width <= 1200  : 
    //       height = 10
    //       alert(3)
    //   break;
    //   case width >= 1201  :
    //        height = 8
    //        alert(3)
    //   break;
    //   case width <= 1099  : 
    //       height = 5;
    //       alert(3)
    //   break;
    // }
    // console.log('height', height)
     return height ;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <Box
    sx={{
      '& .super-app-theme--header': {
        backgroundColor: '#005593',
        color: 'white',
        fontWeight: 700
      },
    }}
    >
   
      <DataGrid
        rows={props.rows}
        columns={props.columns}
        pageSize={screen(width)}
        getRowId={(row: any) => row.ID}
        
        autoHeight={true}
        density={'compact'}
      />
    </Box>
      
  );
}

export default DataTable;
