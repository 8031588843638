import React from 'react'
import '../../../css/style.css'
import logo from '../../../img/logo_blanco.png';
import logo2 from '../../../img/logo.png';
import icono_user from '../../../img/icnos-user-blanco.png';
import icono_whats from '../../../img/icnos-whatsblanco.png';
import icon_home from '../../../img/home-icon.png';
import foto_perfil from '../../../img/foto-perfil.png';
import credito_actual from '../../../img/credito_actual.png';

export default function Cuenta2() {
    return (
        <div>
            <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                <header className="navbar sticky-top flex-md-nowrap p-0 shadow nav-cuenta">
                    <a className="navbar-brand navbar-brand-left p-3" href="/"><img src={logo} alt="PRADO'S" className="img-fluid" width="170" /></a>
                    <br></br>
                    <button className="navbar-toggler d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-nav ">
                        <div className="nav-item text-nowrap d-flex justify-content-end">
                            <a className="nav-link nav-text-cuenta" href="#"> <h6 className="text-nav-cuenta">Sobre nosotros</h6></a>
                            <a className="nav-link nav-text-cuenta" href="#"> <h6 className="text-nav-cuenta">Financiamiento</h6></a>
                            <a className="nav-link nav-text-cuenta" href="#"> <h6 className="text-nav-cuenta">Crédito PABS</h6></a>
                            <a className="nav-link nav-text-cuenta" href="#"> <h6 className="text-nav-cuenta">Blog</h6></a>
                            <a className="nav-link nav-text-cuenta" href="#"> <h6 className="text-nav-cuenta"><img src={icono_user} alt="PRADO'S" className="img-fluid" /></h6></a>
                            <a className="nav-link nav-text-cuenta" href="#"> <h6 className="text-nav-cuenta"><img src={icono_whats} alt="PRADO'S" className="img-fluid" /></h6></a>
                            <a className="nav-link nav-text-cuenta" href="#"> <h6 className="text-nav-cuenta">Cerrar sesión</h6></a>
                        </div>

                    </div>
                </header>
            </div>
            <div className="d-block d-sm-block d-md-none p-0 m-0 ">
                <nav className="navbar navbar-light navbar-expand-lg nav-cuenta p-0 m-0 ">
                    <div className="container-fluid p-0 m-0 ">
                        <a className="navbar-brand p-2" href="#"><img src={logo} alt="PRADO'S" className="img-fluid" width="170" /></a>
                        <a className="nav-image-movil" href="#"><img src={icono_user} alt="PRADO'S" className="img-fluid" /></a>
                        <a className="nav-image-movil" href="#"><img src={icono_whats} alt="PRADO'S" className="img-fluid" /></a>
                        <a className="nav-image-movil" href="/"><img src={icon_home} alt="PRADO'S" className="img-fluid" /></a>
                        <button className="navbar-toggler navbar-toggler-cuenta d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <a className="nav-link nav-text" href=""> <h6 className="text-nav">Sobre nosotros</h6></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link nav-text" href="#"> <h6 className="text-nav">Financiamiento</h6></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link nav-text" href="#"> <h6 className="text-nav">Crédito PABS</h6></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link nav-text" href="#"> <h6 className="text-nav">Blog</h6></a>
                                </li>
                                <li className="nav-item nav-credit-movil">
                                    <a className="nav-link nav-text " href="#"> <h6 className="text-credit">Solicitar crédito</h6></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="">
                <div className="row">
                    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block sidebar collapse p-0 m-0" >
                        <div className="card card-slider p-0 m-0 ">
                            <div className="p-3 d-flex justify-content-center">
                                <img src={foto_perfil} alt="PRADO'S" className="img-fluid" width="150" />
                            </div>
                            <div className="text-center container mb-4">
                                <h4 className="text-strong-blue">Angélica Gutiérrez Rulfo</h4>
                                <small className="text-strong-blue ">20 de Noviembre de 2022</small>
                            </div>
                        </div>
                        <div className="bg-blue-slider position-sticky pt-3 container">
                            <ul className="nav flex-column ">
                                <li className="nav-item">
                                    <a className="nav-link text-white" aria-current="page" href="/cuenta/1">
                                        <h6>Inicio</h6>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" aria-current="page" href="/cuenta/2">
                                        <h6>Crédito actual</h6>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" aria-current="page" href="/cuenta/3">
                                        <h6>Estado de cuenta</h6>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" aria-current="page" href="/cuenta/4">
                                        <h6>Mis datos</h6>
                                    </a>
                                </li>
                                <li className="nav-item mb-5">
                                    <a className="nav-link text-white" aria-current="page" href="/cuenta/1">
                                        <h6>Cerrar sesión</h6>
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </nav>
                    <div className="col-md-3 col-lg-10 d-md-block grey-background p-0 m-0">
                        <div className="container mt-5 ">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-flex">
                                    <img src={credito_actual} alt="PRADO'S" className="img-fluid p-2 img-client mb-4" width="80" />
                                    <h3 className="text-strong-blue mt-4">Mi crédito actual</h3>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="d-flex justify-content-center">
                                        <div className="card card-monto-cuenta p-2">
                                            <div className="container text-center">
                                                <h6 className="text-credit">
                                                    Préstamo total:
                                                </h6>
                                                <h2 className="text-light-blue"> $7,200</h2>
                                            </div>
                                        </div>
                                        <div className="card card-monto-cuenta p-2">
                                            <div className="container text-center">
                                                <h6 className="text-credit">
                                                    Pagos pendientes:
                                                </h6>
                                                <h2 className="text-light-blue">20</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 mb-3 text-center">
                                <h4 className="text-strong-blue">Desglose de pagos</h4>
                            </div>
                            <div className="container mb-5 table-responsive">
                                <table className="table table-light table-striped">
                                    <thead>
                                        <tr className="text-center text-strong-blue">
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Número</th>
                                            <th scope="col">Concepto</th>
                                            <th scope="col">Cantidad a pagar</th>
                                            <th scope="col">Monto acumulado</th>
                                            <th scope="col">Estatus</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        <tr>
                                            <td>22108/202</td>
                                            <td>1</td>
                                            <td>(Pago 1 de 20)</td>
                                            <td>434.58</td>
                                            <td>434.58</td>
                                            <td>Pendiente de pago</td>
                                        </tr>
                                        <tr>
                                            <td>22108/202</td>
                                            <td>1</td>
                                            <td>(Pago 1 de 20)</td>
                                            <td>434.58</td>
                                            <td>434.58</td>
                                            <td>Pendiente de pago</td>
                                        </tr>
                                        <tr>
                                            <td>22108/202</td>
                                            <td>1</td>
                                            <td>(Pago 1 de 20)</td>
                                            <td>434.58</td>
                                            <td>434.58</td>
                                            <td>Pendiente de pago</td>
                                        </tr>
                                        <tr>
                                            <td>22108/202</td>
                                            <td>1</td>
                                            <td>(Pago 1 de 20)</td>
                                            <td>434.58</td>
                                            <td>434.58</td>
                                            <td>Pendiente de pago</td>
                                        </tr>
                                        <tr>
                                            <td>22108/202</td>
                                            <td>1</td>
                                            <td>(Pago 1 de 20)</td>
                                            <td>434.58</td>
                                            <td>434.58</td>
                                            <td>Pendiente de pago</td>
                                        </tr>
                                        <tr>
                                            <td>22108/202</td>
                                            <td>1</td>
                                            <td>(Pago 1 de 20)</td>
                                            <td>434.58</td>
                                            <td>434.58</td>
                                            <td>Pendiente de pago</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
