import React, { useState, useEffect } from "react";
import ApiClient from "../../../services/apiclient";
import AlertMessage from "../../alert.component";
import { FileUpload } from "../../../interfaces/fileInterace";
import { AddressData, LaborData, PersonalData } from "../../forms";
import mapIntoRequest from "../../utils";
import { useHistory } from "react-router-dom";
import { TramiteCreditoContext } from "../../../context/TramiteCreditoProvider";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
  nextStep,
  setNomidaId,
} from "../../../store/actions/PrestamosPersonalesActions";
import setStepPersonalCredit from "../../../helpers/setStepPersonalCredit";
import HeaderDashboard from "../../front/NavbarI";
import Footer from "../../front/FooterI";
import PersonalForm from "../diremovil/PersonalForm";
import AddressForm from "../diremovil/AddressForm";
import TermsConditionsCDC from "../circulo-credito/TermConditions";
import SoliciutdConfiguration from "../diremovil/SolicutdConfiguration";
import ReferenceForm from "../diremovil/ReferenceForm";
import EmployeesForm from "../diremovil/EmployeesForm";
import IneForm from "../diremovil/IneForm";

const PrestamoDiremovil = ({ step, nextStep, setNomidaId }) => {
  const apiClient = ApiClient.getInstance();
  const currentUserId = apiClient.getUserId();
  const currentUserToken = apiClient.getUserToken();

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [ineFiles, setIneFiles] = useState<FileUpload[]>();
  const [personalData, setPersonalData] = useState<PersonalData>();
  const [addressData, setAddressData] = useState<AddressData>();
  const [laborData, setLaborData] = useState<LaborData>();
  const [personalDataFiles, setPersonalDataFiles] = useState<FileUpload[]>();
  const [stepLoaded, setStepLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);

  const handleClose = (event, reason) => {
    console.log("handleClose: ");
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  useEffect(() => {
    const getPersonalLoad = async () => {
      let response = await apiClient.personalLoanDiremovil(currentUserId);

      const data = await response.json();

      if (data?.step === null) {
        nextStep("PERSONAL-INFORMATION");
      } else {
        if (data?.step === "FINISHED") {
          history.push("/dashboard");
        }
        setNomidaId(data?.nominaId);
        nextStep(setStepPersonalCredit(data?.step));
      }
      setStepLoaded(true);
    };

    getPersonalLoad();
  }, [step]);
  const checkActiveCredit = async () => {
    const activeCredit = await apiClient.getActiveCredit();
    console.log(activeCredit);

    const credit = await apiClient.getCredit(activeCredit.creditType);
    console.log(credit);
    return activeCredit;
  };
  useEffect(() => {
    checkActiveCredit();
  }, []);

  const [avisoDePrivacidad, setavisoDePrivacidad] = useState(false);
  const handleChange = () => {
    setavisoDePrivacidad(!avisoDePrivacidad);
  };

  return (
    <div>
      <div className="cliente_container">
        {step === "PERSONAL-INFORMATION" ? <PersonalForm /> : null}

        {step === "ADDRESS" ? <AddressForm /> : null}

        {step === "CDC" ? <TermsConditionsCDC /> : null}

        {step === "CONFIG-CREDIT" ? <SoliciutdConfiguration /> : null}

        {step === "LABOR-DATA" ? <EmployeesForm /> : null}

        {step === "PERSONAL-REFERENCES" ? <ReferenceForm /> : null}

        {/* {step === "QUESTION-PEPS" ? <PersonalForm /> : null} */}

        {step === "FINISHED" ? (
          <IneForm
            setMessage={setMessage}
            setOpenMessage={setOpenMessage}
            setSeverity={setSeverity}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({ personalCreditReducer }) => ({
  step: personalCreditReducer?.step ?? 1,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      nextStep,
      setNomidaId,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrestamoDiremovil);
