import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ApiClient from "../../../services/apiclient";
import UploadBox from "../../credit-forms/components/uploadBox";
import { FileUpload } from "../../../interfaces/fileInterace";
import Footer from "../../front/FooterI";
import esfera_logo from "../../../img/esfera_percapita.png";
import flechai from "../../../img/flechai.png";
import flechad from "../../../img/flechaD.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import AlertMessage from "../../alert.component";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import logo_balnco from "../../../img/logo_blanco.png";
import Skeleton from "@mui/material/Skeleton";
import { motion } from "framer-motion";
import Navbar from "../../front/NavbarI";
import { PreTramiteCreditoContext } from "../../../context/PreTramiteCreditoProvider";
const IneFormRenovation = ({ nominaId, setMessage, setOpenMessage, setSeverity }) => {
  const history = useHistory();
  const apiClient = ApiClient.getInstance();

  const [isLoading, setIsLoading] = useState(false);
  const [openMessage, setOpenMessage1] = useState(false);
  const [message, setMessage1] = useState("");
  const [severity, setSeverity1] = useState("success");
  const [ineFile, setIneFile] = useState<FileUpload>(); //Line for upload
  const [ineSelfieFile, setIneSelfieFile] = useState<FileUpload>(); //Line for upload
  const [amount, setAmount] = useState(0);
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  // const [proofAddress, setProofAddress] = useState<FileUpload>();
  // const [proofIncome, setProofIncome] = useState<FileUpload>();

  const [proofAddress, setProofAddress] = useState<FileUpload>();
  const [proofIncome, setProofIncome] = useState<FileUpload>();

  const [error, setError] = useState(false);
  const [incomplete, setIncomplete] = useState(true);

  const onSubmit = () => {
    // if (!ineFile && !ineSelfieFile) {
    //   //Line for upload
    //   setMessage("Las foto de INE son requeridas"); //Line for upload
    //   setSeverity("error"); //Line for upload
    //   setOpenMessage(true); //Line for upload
    //   setError(true); //Line for upload
    //   return; //Line for upload
    // } //Line for upload

    // if (!ineFile || !ineSelfieFile) {
    //   //Line for upload
    //   setMessage("Debes subir ambos archivos."); //Line for upload
    //   setSeverity("error"); //Line for upload
    //   setOpenMessage(true); //Line for upload
    //   setIncomplete(true); //Line for upload
    //   return; //Line for upload
    // } //Line for upload

    if (!proofAddress && !proofIncome) {
      setMessage("Las comprobantes son necesarios");
      setSeverity("error");
      setOpenMessage(true);
      setError(true);
      return;
    }

    if (!proofAddress || !proofIncome) {
      setMessage("Debes subir ambos comprobantes");
      setSeverity("error");
      setOpenMessage(true);
      setIncomplete(true);
      return;
    }

    setError(false);
    setIncomplete(false);

    setIsLoading(true);

    const body = new FormData();

    // body.append("ifeIne", ineFile?.file); //Line for upload
    // body.append("selfieIne", ineSelfieFile?.file); //Line for upload
    body.append("proofAddress", proofAddress?.file);
    body.append("proofIncome", proofIncome?.file);
    console.log(body, nominaId);
    // apiClient.finishedForm(body, nominaId)
    // .then(res => {
    //     console.log(res)
    //     history.push("/credito-config/personal");
    // })
    // .catch(e => {
    //     console.log(e)
    //     setIsLoading(false);
    // })
  };

  const formik = useFormik({
    initialValues: {
      attached1: false,
    },
    validationSchema: Yup.object({
      attached1: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "Field must be checked"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);

      if (!proofAddress ) {
        setMessage1("Las comprobantes son necesarios");
        setSeverity1("error");
        setOpenMessage1(true);
        setError(true);
        setIsLoading(false);
        return;
      }

      if (!proofAddress ) {
        setMessage1("Debes subir ambos comprobantes");
        setSeverity1("error");
        setOpenMessage1(true);
        setIncomplete(true);
        setIsLoading(false);
        return;
      }

      setError(false);
      setIncomplete(false);

      const body = new FormData();

      //   body.append("ifeIne", ineFile?.file); //Line for upload
      //   body.append("selfieIne", ineSelfieFile?.file); //Line for upload
      body.append("proofAddress", proofAddress?.file);
      body.append("proofIncome", proofIncome?.file);

      console.log(body, nominaId, proofAddress, proofIncome);
      apiClient
        .finishedFormRenovation(body, nominaId)
        .then((res) => {
          console.log(res);
          if (localStorage.getItem("preCreditoLocal")) {
            var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
            if (
              credito.idProcessMarketing !== "" &&
              credito.typeLoanMarketing === "Personal"
            ) {
              var dd = {
                processName: "completed",
              };
              updatePreCredito({
                ...preCredito,
                stepMarketing: "completed",
              });
              updateMarketing(credito.idProcessMarketing, dd);
            }
          }
          history.push("/dashboard");
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    },
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage1(false);
  };
  const checkActiveCredit = async () => {
    const activeCredit = await apiClient.getActiveCredit();
    const credit = await apiClient.getCredit(activeCredit.creditType);
    setAmount(credit.amount);
    return activeCredit;
  };
  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };
  useEffect(() => {
    checkActiveCredit();
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "upload-images-init" &&
        credito.typeLoanMarketing === "Personal"
      )
        var data = {
          processName: "upload-images-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "upload-images-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);

  useEffect(() => {
    const checkUserVeriffValidated = async () => {
      const isValidatedRes = await apiClient.checkUserVeriffValidated();
      if (isValidatedRes.veriffValidated !== true) {
        history.push("/veriff?from=prestamo-personal");
        return;
      }
    };
    const timeout = setTimeout(() => {
      checkUserVeriffValidated();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
      className="grey-background"
    >
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <Navbar />
      <br />
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 mb-5"
      >
        <div className="card card-monto">
          <div className="container">
            <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
            <h2 className="text-light-blue text-end">
              {" "}
              {amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h2>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="row"
      >
        <div className="col-3">
          <div className="d-none d-sm-none d-md-block p-0 m-0 ">
            <div className="card card-monto">
              <div className="container">
                <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
                <h2 className="text-light-blue text-end">
                  {" "}
                  {amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="text-center">
            <h3 className="sol1-tittle">Solicitud de préstamo</h3>
            <p>Información extra</p>
          </div>
        </div>
        <div className="col-3"></div>
      </motion.div>
      {/* STEPS */}
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-flex justify-content-center"
      >
        <img src={esfera_logo} alt="PRADO'S" className="img-fluid" width="60" />
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="text-center mt-3    "
      >
        <h6 className="txt-blue-strong2">SUBE TUS DOCUMENTOS </h6>
      </motion.div>
      {/* CARDS */}

      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-none d-sm-none d-md-block d-flex justify-content-center mt-4 container"
      >
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <div className="container">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-2"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <UploadBox
                    propKey="proofAddress"
                    mimeType="image/*,application/pdf"
                    file={proofAddress}
                    setter={setProofAddress}
                    message="comprobante de domicilio"
                    message2="El domicilio del comprobante debe ser igual a la dirección que pusiste previamente."
                  />
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <UploadBox
                    propKey="proofIncome"
                    mimeType="image/*,application/pdf"
                    file={proofIncome}
                    setter={setProofIncome}
                    message="comprobante de ingresos"
                    message2="Tu comprobante de ingresos debe reflejar correctamente tus ingresos mensuales."
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2"></div>
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <UploadBox
                    propKey="ine"
                    mimeType="image/*"
                    file={ineFile}
                    setter={setIneFile}
                    message="Foto de tu INE o IFE *"
                    message2="El domicilio del comprobante debe ser igual a la dirección que pusiste previamente."
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <UploadBox
                    propKey="ineSelfie"
                    mimeType="image/*"
                    file={ineSelfieFile}
                    setter={setIneSelfieFile}
                    message="Selfie con tu INE o IFE *"
                    message2="El domicilio del comprobante debe ser igual a la dirección que pusiste previamente."
                  />
                </div> */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-2"></div>
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Sube la foto de tu <br />
                                            comprobante de domicilio</label>
                                        <div className="fileupload fileupload-new" data-provides="fileupload">
                                            <span className="btn btn-file btn-file"><span className="fileupload-new">Seleccione un archivo</span>
                                                <span className="fileupload-exists">Cambiar</span>
                                                    <input 
                                                        type="file"
                                                    />
                                            </span>
                                            <span className="fileupload-preview"></span>
                                            <a href="#" className="close fileupload-exists" data-dismiss="fileupload" >×</a>
                                        </div>
                                        <p>El domicilio del comprobante <br /> debe ser igual a la dirección que pusiste previamente.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Sube la foto de tu <br />
                                            comprobante de ingresos</label>
                                        <div className="fileupload fileupload-new" data-provides="fileupload">
                                            <span className="btn btn-file btn-file"><span className="fileupload-new">Seleccione un archivo</span>
                                                <span className="fileupload-exists">Cambiar</span> <input type="file" /></span>
                                            <span className="fileupload-preview"></span>
                                            <a href="#" className="close fileupload-exists" data-dismiss="fileupload" >×</a>
                                        </div>
                                        <p>Tu comprobante de ingresos debe <br /> reflejar correctamente tus ingresos <br /> mensuales.</p>
                                    </div>
                                </div> */}
              </div>
              <div className="d-flex justify-content-center mt-2">
                <div className="form-check">
                  <input
                    className={
                      formik.errors.attached1
                        ? "form-check-input checkbox-terms  is-invalid"
                        : "form-check-input checkbox-terms"
                    }
                    type="checkbox"
                    id="attached1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label
                    className="form-check-label text-check"
                    htmlFor="flexCheckDefault"
                  >
                    Certifico que los datos proporcionados son verídicos y que
                    actúo en nombre propio al solicitar este crédito
                  </label>
                  {formik.errors.attached1 && (
                    <div className="invalid-feedback">
                      Debes certificar la veracidad de los documentos
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                  <div className="mb-3">
                    <p>
                      Probenso Group, Sociedad Anónima, Sociedad Financiera de
                      Objeto Multipe, Entidad No Regulada (E.N.R), con domicilio
                      en Ures Sur 137, Colonia Centro, Tepic, 63000, Nayarit.
                      México es el responsable de sus datos personales.
                      Utilizaremos tus datos personales para los siguientes
                      indispensables.
                    </p>
                    <br />
                    <p>
                      (i) Crear tu cuenta de usuario en la plataforma alojada en
                      httos://percapita.mx (ii) Identificarte como usuario de la
                      plataforma alojada en httos://percapita.mx (iii) Generar
                      el expediente de solicitud de tu crédito (iv) Consultar tu
                      historial y capacidad crediticia; (v) Decidir si se
                      aprueba o rechaza tu solicitud de crédito, y (vi) Firmar
                      los contratos de crédito que sean aprobados. Para conocer
                      el Aviso de Privacidad integral, por favor da click aquí.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
              </div>
              <hr className="mt-4 mb-4" />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center mt-5 mb-4">
                    <a href="/prestamo-personal" className="btn btn-shadow-2">
                      <img
                        src={flechai}
                        alt="PRADO'S"
                        className="img-fluid mrirht"
                        width="10"
                      />
                      Regresar
                    </a>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center mt-5 mb-4">
                    <button type="submit" className="btn btn-shadow-2">
                      Continuar
                      <img
                        src={flechad}
                        alt="PRADO'S"
                        className="img-fluid mleft"
                        width="10"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 d-flex justify-content-center mt-4 container"
      >
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <div className="container">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <UploadBox 
                                    propKey="ine" 
                                    mimeType="image/*" 
                                    file={ineFile} 
                                    setter={setIneFile} 
                                    message="Foto de tu INE o IFE *" 
                                    message2="El domicilio del comprobante debe ser igual a la dirección que pusiste previamente." 
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <UploadBox 
                                    propKey="ineSelfie" 
                                    mimeType="image/*" 
                                    file={ineSelfieFile} 
                                    setter={setIneSelfieFile} 
                                    message="Selfie con tu INE o IFE *" 
                                    message2="El domicilio del comprobante debe ser igual a la dirección que pusiste previamente." 
                                />
                            </div> */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <UploadBox
                    propKey="proofAddress"
                    mimeType="image/*"
                    file={proofAddress}
                    setter={setProofAddress}
                    message="comprobante de domicilio"
                    message2="El domicilio del comprobante debe ser igual a la dirección que pusiste previamente."
                  />
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <UploadBox
                    propKey="proofIncome"
                    mimeType="image/*"
                    file={proofIncome}
                    setter={setProofIncome}
                    message="comprobante de ingresos"
                    message2="Tu comprobante de ingresos debe reflejar correctamente tus ingresos mensuales."
                  />
                </div>
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-2"></div> */}

                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Sube la foto de tu <br />
                                            comprobante de domicilio</label>
                                        <div className="fileupload fileupload-new" data-provides="fileupload">
                                            <span className="btn btn-file btn-file"><span className="fileupload-new">Seleccione un archivo</span>
                                                <span className="fileupload-exists">Cambiar</span>
                                                    <input 
                                                        type="file"
                                                    />
                                            </span>
                                            <span className="fileupload-preview"></span>
                                            <a href="#" className="close fileupload-exists" data-dismiss="fileupload" >×</a>
                                        </div>
                                        <p>El domicilio del comprobante <br /> debe ser igual a la dirección que pusiste previamente.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Sube la foto de tu <br />
                                            comprobante de ingresos</label>
                                        <div className="fileupload fileupload-new" data-provides="fileupload">
                                            <span className="btn btn-file btn-file"><span className="fileupload-new">Seleccione un archivo</span>
                                                <span className="fileupload-exists">Cambiar</span> <input type="file" /></span>
                                            <span className="fileupload-preview"></span>
                                            <a href="#" className="close fileupload-exists" data-dismiss="fileupload" >×</a>
                                        </div>
                                        <p>Tu comprobante de ingresos debe <br /> reflejar correctamente tus ingresos <br /> mensuales.</p>
                                    </div>
                                </div> */}
              </div>
              <div className="d-flex justify-content-center mt-2">
                <div className="form-check">
                  <input
                    className={
                      formik.errors.attached1
                        ? "form-check-input checkbox-terms  is-invalid"
                        : "form-check-input checkbox-terms"
                    }
                    type="checkbox"
                    id="attached1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label
                    className="form-check-label text-check"
                    htmlFor="flexCheckDefault"
                  >
                    Certifico que los datos proporcionados son verídicos y que
                    actúo en nombre propio al solicitar este crédito
                  </label>
                  {formik.errors.attached1 && (
                    <div className="invalid-feedback">
                      Debes certificar la veracidad de los documentos
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                  <div className="mb-3">
                    <p>
                      Probenso Group, Sociedad Anónima, Sociedad Financiera de
                      Objeto Multipe, Entidad No Regulada (E.N.R), con domicilio
                      en Ures Sur 137, Colonia Centro, Tepic, 63000, Nayarit.
                      México es el responsable de sus datos personales.
                      Utilizaremos tus datos personales para los siguientes
                      indispensables.
                    </p>
                    <br />
                    <p>
                      (i) Crear tu cuenta de usuario en la plataforma alojada en
                      httos://percapita.mx (ii) Identificarte como usuario de la
                      plataforma alojada en httos://percapita.mx (iii) Generar
                      el expediente de solicitud de tu crédito (iv) Consultar tu
                      historial y capacidad crediticia; (v) Decidir si se
                      aprueba o rechaza tu solicitud de crédito, y (vi) Firmar
                      los contratos de crédito que sean aprobados. Para conocer
                      el Aviso de Privacidad integral, por favor da click aquí.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
              </div>
              <hr className="mt-4 mb-4" />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center mt-5 mb-4">
                    <a
                      href="/solicitud/personal/11"
                      className="btn btn-shadow-2"
                    >
                      <img
                        src={flechai}
                        alt="PRADO'S"
                        className="img-fluid mrirht"
                        width="10"
                      />
                      Regresar
                    </a>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center mt-5 mb-4">
                    <button type="submit" className="btn btn-shadow-2">
                      Continuar
                      <img
                        src={flechad}
                        alt="PRADO'S"
                        className="img-fluid mleft"
                        width="10"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </motion.div>
      <Backdrop
        sx={{ color: "#173A5E", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid  img-client"
          width="300"
        />
        {/* <Skeleton variant="circular" width={15} height={15} sx={{ bgcolor: 'white' }} animation={'wave'}/>
            <Skeleton variant="circular" width={14} height={14} sx={{ bgcolor: 'white' }}/>
            <Skeleton variant="circular" width={13} height={13} sx={{ bgcolor: 'white' }}/>
            <Skeleton variant="circular" width={12} height={12} sx={{ bgcolor: 'white' }}/> */}
        <CircularProgress color="primary" size={80} />
      </Backdrop>
      <Footer />
    </motion.div>
  );
};

const mapStateToProps = ({ personalCreditReducer }) => ({
  nominaId: personalCreditReducer?.nominaId ?? "",
});

export default connect(mapStateToProps)(IneFormRenovation);
