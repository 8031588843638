import "./App.css";
import Home from "./components/front/HomeI";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import DomicioProvider from "./context/DomicilioProvider";
import TramiteCreditoProvider from "./context/TramiteCreditoProvider";
import PreTramiteCreditoProvider from "./context/PreTramiteCreditoProvider";
import UserProvider from "./context/UserProvider";
import UserCambaceoProvider from "./context/UserCambaceoProvider";
import "./css/style.css";
import PrivateRoute from "./components/PrivateRoute";
import RutasPrivadas from "./utils/RutasPrivadas";
import rutasPublicas from "./utils/RutasPublicas";
import "./css/light/css/dx.light.css";
import { useState } from "react";
import { NOMINA_ACTIVE, PABS_ACTIVE, PERSONAL_ACTIVE } from "./utils/config";
import {FloatUser} from './components/float.component'
import UserDiremovilProvider from "./context/UserDiremovilProvider";
import { FloatUserDiremovil } from "./components/float.component-diremovil"

function App() {
  const privateRoutes = RutasPrivadas;
  return (
    <div className="App">
      <UserDiremovilProvider>
        <UserCambaceoProvider>
          <PreTramiteCreditoProvider>
            <UserProvider>
              <DomicioProvider>
                <TramiteCreditoProvider>
                  <FloatUser />
                  <FloatUserDiremovil />
                  <Router>
                    <Switch>
                      <Route exact path="/">
                        <Home />
                      </Route>
                      {privateRoutes.map(({ path, component }) => (
                        <PrivateRoute path={path} component={component} />
                      ))}
                      {rutasPublicas.map(({ path, component }) => (
                        <Route path={path} component={component} />
                      ))}
                      <Route path="*" component={() => <Redirect to="/" />} />
                    </Switch>
                  </Router>
                </TramiteCreditoProvider>
              </DomicioProvider>
            </UserProvider>
          </PreTramiteCreditoProvider>
        </UserCambaceoProvider>
      </UserDiremovilProvider>
    </div>
  );
}

export default App;
