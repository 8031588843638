import React from 'react'
import  Footer  from '../../front/FooterI'
import  Navbar  from '../../front/NavbarI'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {motion} from "framer-motion";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Faq() {
    return (
        <motion.div
        initial={{  opacity:0 }}
          animate={{opacity:1 }}
          exit={{opacity:0}}
          transition={{ ease: "easeInOut", duration: 0.6 }}>
            <Navbar />
            <div class="d-none d-sm-none d-md-block">
        <div class="  py-5">
          <div class="container">
            <div class="row">
              <div class="text-center mb-5 display-6">
                <h1 class="rBlack txt-azul mt-0 display-4">Preguntas Frecuentes</h1>
                <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <motion.div
      initial={{ y:'50%', opacity:0, scale: 0.5 }}
      animate={{y:0, opacity:1, scale:1}}
      exit={{y: "50%", transition:{duration:0.8}}}
      transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4}} >
          <Paper elevation={5}>
          <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{color: '#005593',fontSize:'20px',fontSize:'20px'}} >¿Qué requiero para el préstamo?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Requiere de un paquete funerario completamente liquidado o con
                      un adeudo menor a los 2 mil pesos.
                    </Typography>
                  </AccordionDetails>
          </Accordion>
          </Paper>
          </motion.div>
          
          
        </Grid>

        <Grid item xs={6}>
        <motion.div
      initial={{ y:'50%', opacity:0, scale: 0.5 }}
      animate={{y:0, opacity:1, scale:1}}
      exit={{y: "50%", transition:{duration:1.2}}}
      transition={{ ease: "easeInOut", duration: 1.2, delay: 0.4}} >
        <Paper elevation={5}>
        <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{color: '#005593',fontSize:'20px'}}>¿El título tiene que ser a mi nombre?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Es necesario que el título esté a nombre de la persona que
                      tramite el prestamo, el cual tambien puede cambiar de nombre
                      acudiendo a las oficinas de la funeraria.
                    </Typography>
                  </AccordionDetails>
        </Accordion>
        </ Paper>
        </motion.div>
        
        </Grid>

        <Grid item xs={6}>
        <motion.div
      initial={{ y:'50%', opacity:0, scale: 0.5 }}
      animate={{y:0, opacity:1, scale:1}}
      exit={{y: "50%", transition:{duration:1.6}}}
      transition={{ ease: "easeInOut", duration: 1.6, delay: 0.4}} >
        <Paper elevation={5}>
        <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{color: '#005593',fontSize:'20px'}}>¿Qué cantidad prestan?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Los prestamos que otorgamos son de los 2 mil a los 7 mil
                      quinientos pesos, con diferentes formas de pago cada uno.
                    </Typography>
                  </AccordionDetails>
        </Accordion>
        </ Paper>
          </motion.div>
       
        </Grid>

        <Grid item xs={6}>
        <motion.div
      initial={{ y:'50%', opacity:0, scale: 0.5 }}
      animate={{y:0, opacity:1, scale:1}}
      exit={{y: "50%", transition:{duration:2}}}
      transition={{ ease: "easeInOut", duration: 2, delay: 0.4}} >
        <Paper elevation={5}>
          <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{color: '#005593',fontSize:'20px'}}>¿Qué tasa de interés manejan?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      El interes que manejamos es el más bajo en el mercado con un 3
                      a un 6% mensual esto varia dependiendo la cantidad que
                      solicite y forma de pago.
                    </Typography>
                  </AccordionDetails>
          </Accordion>
        </ Paper>
          </motion.div>
        
        </Grid>

        <Grid item xs={6}>
        <motion.div
      initial={{ y:'50%', opacity:0, scale: 0.5 }}
      animate={{y:0, opacity:1, scale:1}}
      exit={{y: "50%", transition:{duration:2.4}}}
      transition={{ ease: "easeInOut", duration: 2.4, delay: 0.4}} >
        <Paper elevation={5}>
        <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{color: '#005593',fontSize:'20px'}}>¿Qué documentos necesito para el préstamo?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Requiere el título de servicios funerarios el cual se queda
                      con nosotros en original mientras esté vigente el préstamo,
                      identificado vigente, comprobante de domicilio no mayor a los
                      90 días de impreso, comprobante de ingresos (si no cuenta con
                      nóminas fiscales puede realizar una carta de ingresos donde se
                      especifique a qué se dedica y cuánto son sus ingresos
                      mensuales.
                    </Typography>
                  </AccordionDetails>
        </Accordion>
        </ Paper>
          </motion.div>
        
        </Grid>
      </Grid>
    </Box>
                

                

                

                

                
                
              </div>
            </div>
          </div>
        </div>
      </div>
            <Footer />
        </motion.div>
    )
}
