import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import ApiClient from "../../../services/apiclient";
import { nextStep } from "../../../store/actions/PrestamosPersonalesActions";
import actividadesEconomicas from "../../credit-forms/utils/actividadesEconomicas";
import Footer from "../../front/FooterI";
import esfera_logo from "../../../img/esfera_percapita.png";
import flechai from "../../../img/flechai.png";
import flechad from "../../../img/flechaD.png";
import { motion } from "framer-motion";
import Navbar from "../../front/NavbarI";
import { PreTramiteCreditoContext } from "../../../context/PreTramiteCreditoProvider";
import {validateString} from "../../../utils/StringValidator"

const EmployeesForm = ({ nextStep, nominaId }) => {
  const apiClient = ApiClient.getInstance();

  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const [preInfo, setPreInfo] = useState(null);
  const history = useHistory();
  const [amount, setAmount] = useState(0);
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  const onSubmit = (values) => {
    const data = {
      companyName: values?.companyName,
      salary: Number(values?.salary),
      jobPosition: values?.jobPosition,
      antiquity: Number(values?.antiquity),
      antiquityMonth: 0,
      economicActivity: values?.economicActivity,
      phone: values?.phone,
    };

    setIsLoading(true);

    apiClient
      .laborForm(data, nominaId)
      .then((res) => {
        if (res.error?.message?.rechazado) {
          history.push("/RECHAZADO/personal/loan-denied");
        } else {
          if (localStorage.getItem("preCreditoLocal")) {
            var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
            if (
              credito.idProcessMarketing !== "" &&
              credito.typeLoanMarketing === "Personal"
            ) {
              var dd = {
                processName: "labor-data-finish",
                amount: values.amount,
              };
              updatePreCredito({
                ...preCredito,
                stepMarketing: "labor-data-finish",
              });
              updateMarketing(credito.idProcessMarketing, dd);
            }
          }
          nextStep("PERSONAL-REFERENCES"); //("PERSONAL-REFERENCES")
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };
  useEffect(() => {
    apiClient.getUserPreInfo().then((data) => {
      const userPreInfo = data;

      setPreInfo(userPreInfo);
      reset(userPreInfo);
    });
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "labor-data-init" &&
        credito.typeLoanMarketing === "Personal"
      )
        var data = {
          processName: "labor-data-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "labor-data-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);
  // Redirect to /Veriff if user is not verified
  useEffect(() => {
    const checkUserVeriffValidated = async () => {
      const isValidatedRes = await apiClient.checkUserVeriffValidated();
      if (isValidatedRes.veriffValidated !== true) {
        history.push("/veriff?from=prestamo-personal");
        return;
      }
    };
    const timeout = setTimeout(() => {
      checkUserVeriffValidated();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const checkActiveCredit = async () => {
    const activeCredit = await apiClient.getActiveCredit();
    const credit = await apiClient.getCredit(activeCredit.creditType);
    setAmount(credit.amount);
    return activeCredit;
  };
  useEffect(() => {
    checkActiveCredit();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
      className="grey-background"
    >
      <Navbar />
      <br />
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 mb-5"
      >
        <div className="card card-monto">
          <div className="container">
            <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
            <h2 className="text-light-blue text-end">
              {" "}
              {amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h2>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="row"
      >
        <div className="col-3">
          <div className="d-none d-sm-none d-md-block p-0 m-0 ">
            <div className="card card-monto">
              <div className="container">
                <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
                <h2 className="text-light-blue text-end">
                  {" "}
                  {amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="text-center">
            <h3 className="sol1-tittle">Solicitud de préstamo</h3>
            <p>Información extra</p>
          </div>
        </div>
        <div className="col-3"></div>
      </motion.div>
      {/* STEPS */}
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-flex justify-content-center"
      >
        <img src={esfera_logo} alt="PRADO'S" className="img-fluid" width="60" />
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="text-center mt-3    "
      >
        <h6 className="txt-blue-strong2">DATOS LABORALES</h6>
      </motion.div>
      {/* CARDS */}
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-flex justify-content-center mt-4 container"
      >
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <div className="container">
            <p className="txt-blue-strong">
              Por favor, completa la siguiente información
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="companyName"
                      className="form-label sol1-tittle"
                    >
                      Nombre la compañía en la que trabajas
                    </label>
                    <input
                      type="input"
                      {...register("companyName", { required: true })}
                      className={
                        errors.companyName
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      id="companyName"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setValue(
                          "companyName",
                          validateString(value.toUpperCase())
                        );
                      }}
                    />
                    {errors.companyName && (
                      <div className="invalid-feedback">
                        Nombre compania es requerido
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="salary" className="form-label sol1-tittle">
                      Sueldo mensual
                    </label>
                    <input
                      type="number"
                      {...register("salary", { required: true })}
                      className={
                        errors.companyName
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      id="salary"
                      aria-describedby="emailHelp"
                    />
                    {errors.salary && (
                      <div className="invalid-feedback">
                        Salario es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="jobPosition"
                      className="form-label sol1-tittle"
                    >
                      Puesto actual de trabajo
                    </label>
                    <input
                      type="input"
                      {...register("jobPosition", { required: true })}
                      className={
                        errors.companyName
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      id="jobPosition"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setValue(
                          "jobPosition",
                          validateString(value.toUpperCase())
                        );
                      }}
                    />
                    {errors.jobPosition && (
                      <div className="invalid-feedback">
                        Puesto es requerido
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label sol1-tittle">
                      Teléfono de contacto
                    </label>
                    <input
                      type="input"
                      {...register("phone", { required: true })}
                      className={
                        errors.phone
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      id="phone"
                      aria-describedby="emailHelp"
                    />
                    {errors.jobPosition && (
                      <div className="invalid-feedback">
                        Teléfono es requerido
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label sol1-tittle"
                    >
                      ¿Cuánto tiempo llevas trabajando ahí?
                    </label>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                        <select
                          className={
                            errors.antiquity
                              ? "form-select slect-blue text-light is-invalid"
                              : "form-select slect-blue text-light"
                          }
                          aria-label="Default select example"
                          id="antiquity"
                          name="antiquity"
                          {...register("antiquity", {
                            required: true,
                            pattern: /[0-9]+/,
                          })}
                        >
                          <option disabled value="">
                            Selecciona una opción
                          </option>
                          <option value="0">Menos de 6 meses</option>
                          <option value="1">1 año</option>
                          <option value="2">2 años</option>
                          <option value="3">3 años</option>
                          <option value="4">4 años</option>
                          <option value="5">5 años</option>
                          <option value="6">
                            Mas de 6 años
                          </option>
                        </select>
                        {errors.antiquity && (
                          <div className="invalid-feedback">
                            Antiguedad es requerido
                          </div>
                        )}
                      </div>
                      {/* <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label sol1-tittle"
                        >
                          Meses
                        </label>
                        <select
                          className={
                            errors.antiquityMonth
                              ? "form-select slect-blue text-light is-invalid"
                              : "form-select slect-blue text-light"
                          }
                          aria-label="Default select example"
                          id="antiquityMonth"
                          name="antiquityMonth"
                          {...register("antiquityMonth", {
                            required: true,
                            pattern: /[0-9]+/,
                          })}
                        >
                          <option selected disabled value="">
                            Mes
                          </option>
                          <option selected value="0">
                            0
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                        </select>
                        {errors.antiquityMonth && (
                          <div className="invalid-feedback">
                            Antiguedad es requerido
                          </div>
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-4">
                    <label
                      htmlFor="economicActivity"
                      className="form-label sol1-tittle"
                    >
                      Actividad económica
                    </label>
                    <br />
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <select
                        className={
                          errors.economicActivity
                            ? "form-select slect-blue text-light is-invalid"
                            : "form-select slect-blue text-light"
                        }
                        aria-label="Default select example"
                        id="economicActivity"
                        name="economicActivity"
                        {...register("economicActivity", { required: true })}
                      >
                        <option selected disabled value="">
                          Seleccione una opción
                        </option>
                        {actividadesEconomicas.map((actividad: any) => (
                          <option key={actividad} value={actividad}>
                            {actividad}
                          </option>
                        ))}
                      </select>
                      {errors.economicActivity && (
                        <div className="invalid-feedback">
                          Antiguedad económica es requerido
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-4 mb-4" />
              <div className="row">
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div className="d-flex justify-content-center mt-5 mb-4">
                                    <a href='/solicitud/personal/9' className="btn btn-shadow-2">
                                        <img src={flechai} alt="PRADO'S" className="img-fluid mrirht" width="10" />
                                        Regresar
                                    </a>
                                </div>
                            </div> */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center mt-5 mb-4">
                    <button type="submit" className="btn btn-shadow-2">
                      Continuar
                      <img
                        src={flechad}
                        alt="PRADO'S"
                        className="img-fluid mleft"
                        width="10"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </motion.div>
      <Footer />
    </motion.div>
  );
};

const mapStateToProps = ({ personalCreditReducer }) => ({
  user: personalCreditReducer?.user ?? {},
  nominaId: personalCreditReducer?.nominaId ?? "",
});
console.log("DDDDDDDDDDDDDDDDDDDDDDDDD", nextStep);
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      nextStep,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesForm);
