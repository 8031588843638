import  Navbar  from '../../front/NavbarI';
import  Footer  from '../../front/FooterI';
import {
  Link,
  useHistory,
  useParams,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ValidacionPabs } from "./ValidationPabs2";

import React, { useEffect, useState } from "react";
import ApiClient from "../../../services/apiclient";
import Aceptado from "./aceptado/aceptado"
import Informacion from "./informacion/informacion"
import Domicilio from "./domicilio/domicilio"
import DatosLaborales  from './datos_laborales/datos';
import Referencias from "./referencias/referencias"
import Documentos from "./documentos/documentos2"
import AceptadoMessage from './aceptado/aceptadoMessage';
import DocumentsExceptionVeriff from "./documentos/documents-exception-veriff"
export default function Nav() {
  const [loanAmount, setLoanAmount] = useState({
    upper: 0,
    lower: 0,
  });
  const [productAmount, setProductAmount] = useState(0);

  const [productName, setProductName] = useState({
    product: "",
  });

  const apiClient = ApiClient.getInstance();

  const checkActiveCredit = async () => {
    const activeCredit = await apiClient.getActiveCredit();
    return activeCredit;
  };

  useEffect(() => {
    //const creditID = checkActiveCredit();
    //if (creditID) history.push("/productos");
    const getProcessNomina = async () => {
      await apiClient
      .PabsValid()
      .then(async (res) => {
        console.log(res);
        console.log(res.titleNumber);
        if (res.validated === false) {
          console.log("validate false")
          localStorage.setItem("upper", res.upper);
          localStorage.setItem("lower", res.lower);
          //userData1.questionsPeps.titleNumber = res.titleNumber;
          const exist = await apiClient.userHasUserDetails();
          if (exist) {
            setPabsNumber(res.titleNumber);
            setUserData1({ ...userData1 });
            return history.push("/Titulo-data");
          }
          nextStep();
        } else {
          localStorage.setItem("upper", res.upper);
          localStorage.setItem("lower", res.lower);
          if(res.validated && res.to === "amount") {
            setLoanAmount({
              upper: res.upper,
              lower: res.lower
            })
            setPabsNumber(res.titleNumber);
            setUserData1({ ...userData1 });
            setCurrentPage(1)

          }
          if (res.validated === true && res.to === "questionPeps") {
            //userData1.questionsPeps.titleNumber = res.titleNumber;
            setPabsNumber(res.titleNumber);
            setUserData1({ ...userData1 });
            setCurrentPage(5);
          }
          if (res.validated === true && res.to === "documents") {
            setPabsNumber(res.titleNumber);
            //userData1.questionsPeps.titleNumber = res.titleNumber;
            setUserData1({ ...userData1 });
            setCurrentPage(6);
          }
          if (res.validated === true && res.to === "proceso-credito") {
             setPabsNumber(res.titleNumber);
            // //userData1.questionsPeps.titleNumber = res.titleNumber;
             setUserData1({ ...userData1 });
             setCurrentPage(7);
          }
          if (res.validated === true && res.to === "dashboard") {
             setPabsNumber(res.titleNumber);
            //userData1.questionsPeps.titleNumber = res.titleNumber;
             setUserData1({ ...userData1 });
             history.push('/dashboard')
          }
        }
      })
      .catch((err) => {
        localStorage.clear();
        history.push("/");
      });
    }    
    getProcessNomina();
  }, []);

  const [pabsNumber, setPabsNumber] = useState("");

  const [userData, setUserData] = useState({
    user: {
      nationality: "",
      countryOfBirth: "",
      stateOfBirth: "",
      curp: "",
      rfc: "",
    },
    amount: 0,
    personalData: {
      IDType: "",
      identificationNumber: "",
      civilStatus: "",
      educationLevel: "",
      economicDependents: 0,
    },
    address: {
      street: "",
      interiorNumber: "",
      exteriorNumber: "",
      suburb: "",
      postalCode: "",
      city: "",
      state: "",
      country: "",
      homePhone: "",
      antiquity: null,
      antiquityMonth: null,
      propertyType: "",
      address: "",
    },
    laborData: {
      companyName: "",
      salary: 0,
      jobPosition: "",
      antiquity: null,
      antiquityMonth: null,
      economicActivity: "",
      phone: "",
    },
  });

  const [userData1, setUserData1] = useState({
    personalReferences: [
      {
        name: "",
        relationship: "",
        contactNumber: "", 
      },
    ]
    // questionsPeps: {
    //   publicOffice: false,
    //   specifyPublicOffice: "",
    //   conyugeIsPEP: false,
    //   specifyConcuyeIsPEP: "",
    //   thirdWillGetBenefits: false,
    //   specifyThirdWillGetBenefits: "",
    //   thirdProvidesResources: false,
    //   nameWillProvideResources: "",
    //   sourcesOfResources: "INGRESOS PROPIOS DE SU ACTIVIDAD",
    //   destinationOfResources: "PAGO DE DEUDA",
    //   natureOfResources: "CREDITO SIMPLE",
    //   titleNumber: "",
    //   numberCheque: "",
    //   expirationDateINE: "",
    //   expirationDateProofAddress: "",
    //   wayToPay: "EFECTIVO",
    // },
  });

  const history = useHistory();
  const [finishStatus, setfinishStatus] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  const nextStep = () => setCurrentPage((prev) => prev + 1);
  const prevStep = () => setCurrentPage((prev) => prev - 1);

  const formPages: any = [
    <ValidacionPabs
      setLoanAmount={setLoanAmount}
      setPabsNumber={setPabsNumber}
      nextStep={nextStep}
      
    ></ValidacionPabs>,
    <Aceptado
      productName={productName}
      setProductName={setProductName}
      nextStep={nextStep}
      loanAmount={loanAmount}
      userData={userData}
      setUserData={setUserData}
      setProductAmount={setProductAmount}
    ></Aceptado>,
    <Informacion
      userData={userData}
      setUserData={setUserData}
      nextStep={nextStep}
      prevStep={prevStep}
      productAmount={productAmount}
    ></Informacion>,
    <Domicilio
      userData={userData}
      setUserData={setUserData}
      nextStep={nextStep}
      prevStep={prevStep}
    ></Domicilio>,
    <DatosLaborales
      productName={productName}
      userData={userData}
      setUserData={setUserData}
      nextStep={nextStep}
      setCurrentPage={setCurrentPage}
      prevStep={prevStep}
    ></DatosLaborales>,
    <Referencias
      productName={productName}
      userData={userData}
      setUserData={setUserData}
      userData1={userData1}
      setUserData1={setUserData1}
      nextStep={nextStep}
      setCurrentPage={setCurrentPage}
      prevStep={prevStep}
    ></Referencias>,
    <Documentos
    productName={productName}
    setProductName={setProductName}
    userData1={userData1}
    prevStep={prevStep}
    nextStep={nextStep}
  />,
  <AceptadoMessage 
   userData={userData}
  />
  ];

  return (
    <div>
      <Navbar />
        <div >{formPages[currentPage]}</div>
        <Footer />
    </div>
  );
}
