import React, {useState} from 'react'
import Navbar from '../../front/NavbarI';
import Footer from '../../front/FooterI';
import {PreTramiteCreditoContext} from '../../../context/PreTramiteCreditoProvider';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from "formik";
import * as Yup from "yup";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ApiClient, { Prospective } from "../../../services/apiclient";
import AlertMessage from "../../alert.component";
import {  useHistory } from "react-router-dom";
import  '../../../css/style.css';


export default function Solicitud22 () { 
    const { preCredito, updatePreCredito } = React.useContext(PreTramiteCreditoContext);
    const[date,setDate] = useState(null);
    const[isLoading,setIsLoading] = useState(false);
    const [openMessage,setOpenMessage] = useState(false);
    const[message,setMessage] = useState("");
    const[severity,setSeverity] = useState("success");
    const apiClient = ApiClient.getInstance();
    const history = useHistory();
    function handleChangeDate(event: any) {
        console.log(event.target.value);
        let date = event.target.value.split('/');
        formik.setFieldValue('dateOfBirth',date[2]+'-'+date[0]+'-'+date[1]);
    }
    const formik = useFormik({
    
        initialValues: {
          firstName: "",
          secondName: "",
          lastFName: "",
          lastMName: "",
          dateOfBirth: "",
          state: "",
          gender: "",
          email: "",
          phone: "",
          attached1: false,
          attached2: false,
        },
        validationSchema: Yup.object({
          firstName: Yup.string().required("Tienes que ingresar la calle."),
          lastFName: Yup.string().required("Tienes que ingresar la calle."),
          lastMName: Yup.string().required("Tienes que ingresar la calle."),
          dateOfBirth: Yup.string().required("Tienes que ingresar la calle."),
          state: Yup.string().required("Tienes que ingresar la calle."),
          email: Yup.string().required("Tienes que ingresar la calle."),
          phone: Yup.string().required("Tienes que ingresar la calle."),
          attached1: Yup.boolean().required("The terms and conditions must be accepted.").oneOf([true], 'Field must be checked'),
          attached2: Yup.boolean().required("The terms and conditions must be accepted.").oneOf([true], 'Field must be checked')
        }),
        onSubmit: values => {
            var re = /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/;
            let validar = re.test(String(values.phone).toLowerCase());
        
            if (!validar) {
              setOpenMessage(true);
              setMessage('Ingrese un número de teléfono válido. Favor de verificar.');
              setSeverity('error');
              return;
            }
          updatePreCredito({
            ...preCredito,
            firstName:values.firstName,
            secondName:values.secondName,
            lastFName:values.lastFName,
            lastMName:values.lastMName,
            dateOfBirth:values.dateOfBirth,
            state:values.state,
            gender:values.gender,
            email:values.email,
            phone:values.phone.toString(),
            attached1:values.attached1,
            attached2:values.attached2,
          })
          setIsLoading(true);
          console.log(preCredito)
          const data_credit : Prospective = {
            amount:preCredito.amount,
            amount_payment:preCredito.amount_payment,
            attached1:preCredito.attached1,
            attached2:preCredito.attached2,
            dateOfBirth:preCredito.dateOfBirth,
            email:preCredito.email,
            firstName:preCredito.firstName,
            gender:preCredito.gender,
            lastFName:preCredito.lastFName,
            lastMName:preCredito.lastMName,
            phone:preCredito.phone.toString(),
            secondName:preCredito.secondName,
            state:preCredito.state,
            step:preCredito.step,
            term:preCredito.term,
            way_to_payment:preCredito.way_to_payment,
          }
            apiClient
            .setProspect(data_credit)
            .then((res) => res.json())
            .then((data) => {
                console.log(data)
                if (data.error) {
                    setIsLoading(false);
                setOpenMessage(true);
                setMessage(data.error.message);
                setSeverity("error");
                }
                setIsLoading(false);
                localStorage.setItem("prspect",data.id);
                //send code sms
                //return history.push("/solicitud-datos2");
            })
        }
      });
      const handleClose = () => {
        setOpenMessage(false);
      };
    return (
        <div className="grey-background">
            <Navbar />
            <AlertMessage
              open={openMessage}
              message={message}
              handleClose={handleClose}
              severity={severity}
            />
            <br />
            <br />
            <div className="d-block d-sm-block d-md-none p-0 m-0 mb-5">
                <div className="card card-monto">
                    <div className="container">
                        <h6 className="text-credit text-end p-2">
                            Monto solicitado:
                        </h6>
                        <h2 className="text-light-blue text-end"> {preCredito.amount.toLocaleString("en-US", {
                                                                                        style: "currency",
                                                                                        currency: "USD",
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2,
                                                                                    })}
                        </h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                        <div className="card card-monto">
                            <div className="container">
                                <h6 className="text-credit text-end p-2">
                                    Monto solicitado:
                                </h6>
                                <h2 className="text-light-blue text-end"> {preCredito.amount.toLocaleString("en-US", {
                                                                                        style: "currency",
                                                                                        currency: "USD",
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2,
                                                                                    })}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="text-center">
                        <h3 className="sol1-tittle">Solicitud de préstamo</h3>
                        <p>Por favor, rellena los siguientes campos con tu información</p>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
            {/* STEPS */}
            <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                <div className="d-flex justify-content-center mt-4 container">
                    <button className="circle-step-blue">
                        <h5 className="numbre-step">1</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
                    <button className="circle-step">
                        <h5 className="numbre-step">2</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
                    <button className="circle-step">
                        <h5 className="numbre-step">3</h5>
                    </button>
                </div>
            </div>
            <div className="d-block d-sm-block d-md-none p-0 m-0 ">
                <div className="d-flex justify-content-center mt-4 container">
                    <button className="circle-step">
                        <h5 className="numbre-step">1</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaa</div>
                    <button className="circle-step">
                        <h5 className="numbre-step">2</h5>
                    </button>
                    <div className='line'>aaaaaaaaaaaa</div>
                    <button className="circle-step">
                        <h5 className="numbre-step">3</h5>
                    </button>
                </div>
            </div>
            {/* CARDS */}
            <div className="d-flex justify-content-center mt-4 container">
                <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
                    <div className="container">
                        <p className="txt-blue-strong">Rellena los campos vacíos con tus datoklls</p>
                        <form id="pre-info" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="firstName" className="form-label sol1-tittle">Nombre (s)*</label>
                                        <input 
                                            type="input" 
                                            id="firstName" 
                                            aria-describedby="emailHelp" 
                                            onChange={formik.handleChange}
                                            value={formik.values.firstName}
                                            onBlur={formik.handleBlur}
                                            className={formik.errors.firstName ? " form-control is-invalid" : "form-control"}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="secondName" className="form-label sol1-tittle">Segundo nombre *</label>
                                        <input 
                                            type="input" 
                                            className="form-control"
                                            id="secondName"
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.secondName}
                                            onBlur={formik.handleBlur}
                                            />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    
                                    
                                <div className="mb-3">
                                        <label htmlFor="lastFName" className="form-label sol1-tittle">Apellido paterno *</label>
                                        <input 
                                            type="input" 
                                            className={formik.errors.lastFName ? " form-control is-invalid" : "form-control"}
                                            id="lastFName"
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.lastFName}
                                            onBlur={formik.handleBlur}
                                            />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="lastMName" className="form-label sol1-tittle">Apellido materno *</label>
                                        <input 
                                            type="input" 
                                            className={formik.errors.lastMName ? " form-control is-invalid" : "form-control"}
                                            id="lastMName"
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.lastMName}
                                            onBlur={formik.handleBlur}
                                            />
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-4 mb-4" />
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                         <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Fecha de nacimiegfgfnto *</label>
                                        {/*}
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-4">
                                                <select className="form-select slect-blue text-light" aria-label="Default select example">
                                                    <option  disabled>Día</option>
                                                </select>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-4">
                                                <select className="form-select slect-blue text-light" aria-label="Default select example">
                                                    <option selected>Mes</option>
                                                </select>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                                <select className="form-select  text-light" aria-label="Default select example">
                                                    <option selected>Año</option>
                                                </select>
                                            </div>
                                        </div> */}
                                         <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={3}>
                                                <DatePicker 
                                              
                                                disableFuture
                                                
                                                //label="Fecha nacimiento"
                                                openTo="day"
                                                views={[ 'day', 'month', 'year']}
                                                value={date}
                                                onChange= {(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                renderInput={
                                                     (params) => 
                                                        <TextField 
                                                            {...params}
                                                            size="medium"
                                                            color="primary"
                                                            name="dateOfBirth"
                                                            onChange={handleChangeDate}
                                                            value={formik.values.dateOfBirth}
                                                            onBlur={handleChangeDate}
                                              />}
                                                />
                                            </Stack>
                                            </LocalizationProvider>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label sol1-tittle">Correo electrónico *</label>
                                        <input 
                                            type="email" 
                                            className={formik.errors.email ? " form-control is-invalid" : "form-control"}
                                            id="email" 
                                            aria-describedby="emailHelp" 
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-4">
                                        <label htmlFor="gender" className="form-label sol1-tittle">Género *</label>
                                        <br />
                                        <div className="form-check form-check-inline">
                                            <input 
                                            className={formik.errors.gender ? " form-check-input is-invalid" : "form-check-input"}
                                                type="radio" 
                                                name="gender" 
                                                id="gender" 
                                                value="femenino"
                                                checked={formik.values.gender === "femenino" }
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur}
                                                />
                                            <label className="form-check-label" htmlFor="gender">Mujer</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input 
                                                className={formik.errors.gender ? " form-check-input is-invalid" : "form-check-input"}
                                                type="radio" 
                                                name="gender" 
                                                id="gender" 
                                                value="masculino"
                                                checked={formik.values.gender === "masculino" }
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur}
                                                />
                                            <label className="form-check-label" htmlFor="gender">Hombre</label>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="state" className="form-label sol1-tittle">Estado de nacimiento *</label>
                                        <select 
                                            className={formik.errors.state ? " form-select slect-blue text-light is-invalid" : "form-select slect-blue text-light"}
                                            aria-label="Default select example"
                                            name="state" 
                                            id="state"
                                            onChange={formik.handleChange}
                                            value={formik.values.state}
                                            onBlur={formik.handleBlur}
                                        >
                                        <option value="" disabled>Seleccione un estado</option>
                                            <option value="Aguascalientes">Aguascalientes</option>
                                            <option value="Baja California">Baja California</option>
                                            <option value="Baja California Sur">
                                                Baja California Sur
                                            </option>
                                            <option value="Campeche">Campeche</option>
                                            <option value="Chiapas">Chiapas</option>
                                            <option value="Chihuahua">Chihuahua</option>
                                            <option value="Coahuila de Zaragoza">
                                                Coahuila de Zaragoza
                                            </option>
                                            <option value="Colima">Colima</option>
                                            <option value="Colima">Distrito Federal</option>
                                            <option value="Durango">Durango</option>
                                            <option value="Guanajuato">Guanajuato</option>
                                            <option value="Guerrero">Guerrero</option>
                                            <option value="Hidalgo">Hidalgo</option>
                                            <option value="Jalisco">Jalisco</option>
                                            <option value="México">México</option>
                                            <option value="Michoacán de Ocampo">
                                                Michoacán de Ocampo
                                            </option>
                                            <option value="Morelos">Morelos</option>
                                            <option value="Nayarit">Nayarit</option>
                                            <option value="Nuevo León">Nuevo León</option>
                                            <option value="Oaxaca">Oaxaca</option>
                                            <option value="Puebla">Puebla</option>
                                            <option value="Querétaro">Querétaro</option>
                                            <option value="Quintana Roo">Quintana Roo</option>
                                            <option value="San Luis Potosí">San Luis Potosí</option>
                                            <option value="Sinaloa">Sinaloa</option>
                                            <option value="Sonora">Sonora</option>
                                            <option value="Tabasco">Tabasco</option>
                                            <option value="Tamaulipas">Tamaulipas</option>
                                            <option value="Tlaxcala">Tlaxcala</option>
                                            <option value="Veracruz de Ignacio de la Llave">
                                                Veracruz de Ignacio de la Llave
                                            </option>
                                            <option value="Yucatán">Yucatán</option>
                                            <option value="Zacatecas">Zacatecas</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-4 mb-4" />
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label sol1-tittle">Numero de celular *</label>
                                        <input 
                                            type="number" 
                                            className={formik.errors.phone ? "form-control  is-invalid" : "form-control"}
                                            id="phone" 
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange}
                                            value={formik.values.phone}
                                            onBlur={formik.handleBlur}
                                            />
                                    </div>
                                    
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="container">
                                        <h6 className="text-grey">Antes de continuar, debemos validar tu número. <br />
                                            Da click aquí una vez que hayas puesto tu celular. </h6>
                                        <div className="form-check">
                                            <input 
                                                className={formik.errors.attached1 ? "form-check-input checkbox-terms  is-invalid" : "form-check-input checkbox-terms"}
                                                type="checkbox" 
                                                value="" 
                                                id="attached1" 
                                                checked={formik.values.attached1}
                                                onChange={formik.handleChange} />
                                            <label className="form-check-label text-check" htmlFor="attached1">
                                                Acepto el Aviso de Privacidad
                                            </label>
                                            {formik.errors.attached1 && (
                                                <div className="invalid-feedback">
                                                Debes aceptar el aviso de privacidad
                                            </div>
                                            )}
                                            
                                        </div>
                                        <div className="form-check">
                                            <input 
                                                 className={formik.errors.attached2 ? "form-check-input checkbox-terms  is-invalid" : "form-check-input checkbox-terms"}
                                                type="checkbox" 
                                                value="" 
                                                checked={formik.values.attached1}
                                                id="attached2"
                                                onChange={formik.handleChange} />

                                            <label className="form-check-label text-check" htmlFor="attached2">
                                                Acepto las obligaciones del Contrato de licencia
                                            </label>
                                            {formik.errors.attached2 && (
                                                <div className="invalid-feedback">
                                                Debes aceptar el contrato de licencia
                                            </div>
                                            )}
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input checkbox-terms" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label text-check" htmlFor="flexCheckDefault">
                                                Acepto recibir noticias, promociones e información acerca de mi cuenta
                                            </label>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-start mt-5 mb-4">
                                        <button type="submit" className="btn btn-shadow">
                                            Validar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.appBar + 1 }}
                open={isLoading}
                          
            >   
            <CircularProgress color="primary"  />                
            </Backdrop>  
            </div>
            
            <Footer />
        </div>
    )
}
