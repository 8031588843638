import React,{useState, useEffect} from 'react'
import Fab from '@mui/material/Fab';
import PersonIcon from '@mui/icons-material/Person';
import CurtainsClosedIcon from '@mui/icons-material/CurtainsClosed';
import { DiremovilContext } from "../context/UserDiremovilProvider";

export const FloatUserDiremovil = () => {
    const { userDiremovil, updateUserDiremovil } = React.useContext(DiremovilContext);
    function closeSession() {
        updateUserDiremovil({
            ...userDiremovil,
            nombre: null,
            userId: null,
          });
          return  window.location.replace(`https://diremovil.com`);
    }
    return (
    <>
        {userDiremovil.nombre !== null && userDiremovil.nombre !== '' && userDiremovil.nombre !== 'null'?  
              <div className="float">
              <Fab variant="extended" color="secondary" aria-label="add">
                  <PersonIcon sx={{ mr: 1 }} />
                  {userDiremovil.nombre}
              </Fab>
              <Fab variant="extended" color="error" aria-label="add" onClick={closeSession}>
                  <CurtainsClosedIcon sx={{ mr: 1 }} />
                  Salir
              </Fab>
        
              </div>
            : ''}

    </>

    )
}
