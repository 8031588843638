import UploadBox from "./components/uploadBox";
import creditCard from "../../../../img/creditcard.png";
import image from "../../../../img/image.png";
import { useState, useEffect } from "react";
import ApiClient from "../../../../services/apiclient";
import { useHistory } from "react-router-dom";
import React from "react";
import { TramiteCreditoContext } from "../../../../context/TramiteCreditoProvider";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import AlertMessage from "../../../alert.component";
import { useFormik } from "formik";
import * as Yup from "yup";
import flechai from "../../../../img/flechai.png";
import flechad from "../../../../img/flechaD.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import esfera_logo from "../../../../img/esfera_percapita.png";
import logo_balnco from "../../../../img/logo_blanco.png";
import { motion } from "framer-motion";
import { PreTramiteCreditoContext } from "../../../../context/PreTramiteCreditoProvider";

interface UserDocument {
  image: string;
  description: string;
  fileKey: string;
  setter: React.Dispatch<React.SetStateAction<File>>;
}

export default function Documentos({
  userData1,
  productName,
  setProductName,
  prevStep,
  nextStep,
}) {
  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);

  const [complete, setComplete] = useState(false);
  const [titlePABS, setTitlePABS] = useState<File>();
  const [ifeIne, setIfeIne] = useState<File>();
  const [ifeReverso, setIfeReverso] = useState<File>();
  const [selfieIne, setSelfieIne] = useState<File>();
  const [proofAddress, setProofAddress] = useState<File>();
  const [proofIncome, setproofIncome] = useState<File>();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [data, setData] = useState<UserDocument[]>()
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  const [hasException, setHasException] = useState(false);

  /**
   * Iterable data for creating the upload boxes components
   */
  const datae: UserDocument[] = [
    {
      image: image,
      description: "Comprobante de domicilio",
      fileKey: "proofAddress",
      setter: setProofAddress,
    },
    {
      image: image,
      description: "Comprobante de ingresos",
      fileKey: "proofIncome",
      setter: setproofIncome,
    },
    {
      image: image,
      description: "Título PABS",
      fileKey: "titlePABS",
      setter: setTitlePABS,
    },
  ];
  const files = new FormData();

  const apiClient = ApiClient.getInstance();

  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("userLocal")) {
      var userLocal = JSON.parse(localStorage.getItem("userLocal"));
      setAmount(parseInt(userLocal.preAmount));
    }
    const hasException = async() => {
       await apiClient.getExceptionVeriff().then((response: any) => {
        setHasException(response.hasExceptionVeriff);
        console.log("valor has veriff exception", response.hasExceptionVeriff);
      if(response.hasExceptionVeriff) {
        console.log("yes exception")
        setData( 
          [{
            image: creditCard,
            description: "Foto frontal de tu INE o IFE",
            fileKey: "ifeIne",
            setter: setIfeIne
          },
          {
            image: creditCard,
            description: "Foto trasera de tu INE o IFE",
            fileKey: "ifeReverso",
            setter: setIfeReverso
          },
          {
            image: image,
            description: "Selfie con tu INE/IFE",
            fileKey: "selfieIne",
            setter: setSelfieIne
          },
          {
            image: image,
            description: "Comprobante de domicilio",
            fileKey: "proofAddress",
            setter: setProofAddress,
          },
          {
            image: image,
            description: "Comprobante de ingresos",
            fileKey: "proofIncome",
            setter: setproofIncome,
          },
          {
            image: image,
            description: "Título PABS",
            fileKey: "titlePABS",
            setter: setTitlePABS,
          }]
          )
      } 
      if(!response.hasExceptionVeriff) {
        setData(
          [          
          {
            image: image,
            description: "Comprobante de domicilio",
            fileKey: "proofAddress",
            setter: setProofAddress,
          },
          {
            image: image,
            description: "Comprobante de ingresos",
            fileKey: "proofIncome",
            setter: setproofIncome,
          },
          {
            image: image,
            description: "Título PABS",
            fileKey: "titlePABS",
            setter: setTitlePABS,
          }
      ])
      }
       })
      
    }
    hasException();
  }, []);

  /**
   * Callback that sets the complete state as true when all the files
   * were loaded into memory.
   */
  const isComplete = () => {
    let found = 0;
    data.forEach((fileData) => {
      if (files.get(fileData.fileKey)) {
        found++;
      }
    });

    if (found >= 3) {
      setComplete(true);
    }
  };

  /**
   * Function that is executed when the user has uplaoded all files and
   * wants to upload them into the server.
   */
  const onSubmit = () => {
    if (
      proofAddress == undefined ||
      proofIncome == undefined ||
      titlePABS == undefined
    ) {
      setOpenMessage(true);
      setMessage("Completar la informacion para poder continuar.");
      setSeverity("info");
      return;
    }
    if (avisoDePrivacidad == false) {
      setOpenMessage(true);
      setMessage(
        "Para poder continuar necesitas aceptar el aviso de privacidad."
      );
      setSeverity("info");
      return;
    }
    setLoading(true);
    const body = new FormData();
    // body.append('ifeIne', ifeIne);
    // body.append('ifeReverso', ifeReverso);
    // body.append('selfieIne', selfieIne);
    body.append("proofAddress", proofAddress);
    body.append("proofIncome", proofIncome);
    body.append("titlePABS", titlePABS);

    apiClient
      .uploadPabsFiles(body)
      .then(async (_res) => {
        //      history.push('/step5');
        const activeCredit = await apiClient.getActiveCredit();
        const credit = await apiClient.getCredit(activeCredit.creditType);
        console.log("[uploadPabsFiles]: ", activeCredit, credit);
        updateCredito({
          ...credito,
          idPrestamo: credit.id,
          fecha_solicitud: credit.creationDate,
          isDelivered: credit.delivered,
          isApproved: credit.status === "ACCEPTED" ? true : false,
          importe: credit.amount,
          estatusSolicitud:
            credit.status === "IN_PROGRESS"
              ? "revision"
              : credit.status === "ACCEPTED"
              ? "aprobado"
              : credit.status === "DENIED"
              ? "solicitud-no-aprobada"
              : "sin-solicitud",
        });

        history.push("/proceso-credito");
        // handle upload logic
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        // handle error
      });
  };
  const [avisoDePrivacidad, setavisoDePrivacidad] = useState(false);
  const handleChange = () => {
    setavisoDePrivacidad(!avisoDePrivacidad);
  };

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };
  const [isPopupVisible, setPopupVisibility] = useState(false);

  const abrirModal = () => {
    if (avisoDePrivacidad === true) {
      if (
        ifeIne == undefined ||
        ifeReverso == undefined ||
        selfieIne == undefined ||
        proofAddress == undefined ||
        proofIncome == undefined ||
        titlePABS == undefined
      ) {
        setOpenMessage(true);
        setMessage("Completar la informacion para poder continuar.");
        setSeverity("info");
        return;
      }
      setLoading(true);
      const body = new FormData();
      body.append("ifeIne", ifeIne);
      body.append("ifeReverso", ifeReverso);
      body.append("selfieIne", selfieIne);
      body.append("proofAddress", proofAddress);
      body.append("proofIncome", proofIncome);
      body.append("titlePABS", titlePABS);

      apiClient
        .uploadPabsFiles(body)
        .then(async (_res) => {
          //      history.push('/step5');
          const activeCredit = await apiClient.getActiveCredit();
          const credit = await apiClient.getCredit(activeCredit.creditType);
          console.log("[uploadPabsFiles]: ", activeCredit, credit);
          updateCredito({
            ...credito,
            idPrestamo: credit.id,
            fecha_solicitud: credit.creationDate,
            isDelivered: credit.delivered,
            isApproved: credit.status === "ACCEPTED" ? true : false,
            importe: credit.amount,
            estatusSolicitud:
              credit.status === "IN_PROGRESS"
                ? "revision"
                : credit.status === "ACCEPTED"
                ? "aprobado"
                : credit.status === "DENIED"
                ? "solicitud-no-aprobada"
                : "sin-solicitud",
          });

          history.push("/proceso-credito");
          // handle upload logic
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          // handle error
        });
    } else {
      setOpenMessage(true);
      setMessage(
        "Para poder continuar necesitas certificar que los datos proporcionados son verídicos y que actúo en nombre propio al solicitar este crédito."
      );
      setSeverity("info");
    }
  };

  const formik = useFormik({
    initialValues: {
      attached1: false,
    },
    validationSchema: Yup.object({
      attached1: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "Field must be checked"),
    }),
    onSubmit: async (values) => {
      console.log(formik.values.attached1);
      setIsLoading(true);
      if(hasException){
        if (
        proofAddress == undefined ||
        proofIncome == undefined ||
        titlePABS == undefined ||
        ifeIne == undefined ||
        ifeReverso == undefined ||
        selfieIne == undefined
      ) {
        setOpenMessage(true);
        setMessage("Completar la informacion para poder continuar.");
        setSeverity("info");
        setIsLoading(false);
        return;
      }
      setLoading(true);
      const body = new FormData();
      body.append('ifeIne', ifeIne);
      body.append('ifeReverso', ifeReverso);
      body.append('selfieIne', selfieIne);
      body.append("proofAddress", proofAddress);
      body.append("proofIncome", proofIncome);
      body.append("titlePABS", titlePABS);
      console.log(proofAddress, proofIncome, titlePABS);

     await apiClient
        .uploadPabsFilesAll(body)
        .then(async (_res) => {
          const activeCredit = await apiClient.getActiveCredit();
          const credit = await apiClient.getCredit(activeCredit.creditType);
          console.log("[uploadPabsFiles]: ", activeCredit, credit);
          updateCredito({
            ...credito,
            idPrestamo: credit.id,
            fecha_solicitud: credit.creationDate,
            isDelivered: credit.delivered,
            isApproved: credit.status === "ACCEPTED" ? true : false,
            importe: credit.amount,
            estatusSolicitud:
              credit.status === "IN_PROGRESS"
                ? "revision"
                : credit.status === "ACCEPTED"
                ? "aprobado"
                : credit.status === "DENIED"
                ? "solicitud-no-aprobada"
                : "sin-solicitud",
          });
          if (localStorage.getItem("preCreditoLocal")) {
            var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
            if (
              credito.idProcessMarketing !== "" &&
              credito.typeLoanMarketing === "Titulo"
            ) {
              var dd = {
                processName: "upload-images-finished",
              };
              updatePreCredito({
                ...preCredito,
                stepMarketing: "upload-images-finished",
              });
              updateMarketing(credito.idProcessMarketing, dd);
            }
          }
          nextStep();
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
      } else {
        if (
        proofAddress == undefined ||
        proofIncome == undefined ||
        titlePABS == undefined
      ) {
        setOpenMessage(true);
        setMessage("Completar la informacion para poder continuar.");
        setSeverity("info");
        setIsLoading(false);
        return;
      }
      setLoading(true);
      const body = new FormData();
      // body.append('ifeIne', ifeIne);
      // body.append('ifeReverso', ifeReverso);
      // body.append('selfieIne', selfieIne);
      body.append("proofAddress", proofAddress);
      body.append("proofIncome", proofIncome);
      body.append("titlePABS", titlePABS);
      console.log(proofAddress, proofIncome, titlePABS);

     await apiClient
        .uploadPabsFiles(body)
        .then(async (_res) => {
          const activeCredit = await apiClient.getActiveCredit();
          const credit = await apiClient.getCredit(activeCredit.creditType);
          console.log("[uploadPabsFiles]: ", activeCredit, credit);
          updateCredito({
            ...credito,
            idPrestamo: credit.id,
            fecha_solicitud: credit.creationDate,
            isDelivered: credit.delivered,
            isApproved: credit.status === "ACCEPTED" ? true : false,
            importe: credit.amount,
            estatusSolicitud:
              credit.status === "IN_PROGRESS"
                ? "revision"
                : credit.status === "ACCEPTED"
                ? "aprobado"
                : credit.status === "DENIED"
                ? "solicitud-no-aprobada"
                : "sin-solicitud",
          });
          if (localStorage.getItem("preCreditoLocal")) {
            var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
            if (
              credito.idProcessMarketing !== "" &&
              credito.typeLoanMarketing === "Titulo"
            ) {
              var dd = {
                processName: "upload-images-finished",
              };
              updatePreCredito({
                ...preCredito,
                stepMarketing: "upload-images-finished",
              });
              updateMarketing(credito.idProcessMarketing, dd);
            }
          }
          nextStep();
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
      }
      
    },
  });

  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };

  useEffect(() => {
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "upload-images-init" &&
        credito.typeLoanMarketing === "Titulo"
      )
        var data = {
          processName: "upload-images-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "upload-images-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);

  useEffect(() => {
    const checkUserVeriffValidated = async () => {
      const isValidatedRes = await apiClient.checkUserVeriffValidated();
      console.log(isValidatedRes);
      if (isValidatedRes.veriffValidated !== true) {
        history.push("/veriff?from=PABS");
        return;
      }
    };
    const timeout = setTimeout(() => {
      checkUserVeriffValidated();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
      className="solicitud__container"
    >
      <Popup
        visible={isPopupVisible}
        closeOnOutsideClick={false}
        width={1000}
        height={340}
        showTitle={false}
      >
        <AlertMessage
          open={openMessage}
          message={message}
          handleClose={handleClose}
          severity={severity}
        />
        <ScrollView width="100%" height="100%">
          <div className="avisoContent">
            Probenso Group, Sociedad Anónima, Sociedad Financiera de Objeto
            Múltipe, Entidad No Regulada (E.N.R.), con domicilio en Ures Sur
            137, Colonia Centro, Tepic, 63000, Nayarit, México es el responsable
            de sus datos personales.Utilizaremos tus datos personales para los
            siguientes fines indispensables: (i) Crear tu cuenta de usuario en
            la plataforma alojada en https://percapita.mx/; (ii) Identificarte
            como usuario de la plataforma alojada en https://percapita.mx/;
            (iii) Generar el expediente de solicitud de tu crédito; (iv)
            Consultar tu historial y capacidad crediticia; (v) Decidir si se
            aprueba o rechaza tu solicitud de crédito, y; (vi) Firmar los
            contratos de crédito que sean aprobados.Para conocer el aviso de
            privacidad integral, por favor haz{" "}
            <a
              href="/aviso-de-privacidad"
              target="_blank"
              style={{ fontSize: 14, marginLeft: 5 }}
            >
              click aquí.
            </a>
            <div className="solicitud-b__bottom button-center">
              <button
                className="btn blue marginAviso"
                type="submit"
                onClick={() => setPopupVisibility(false)}
                disabled={loading}
              >
                Cerrar
              </button>
              <button
                className="btn blue"
                type="submit"
                disabled={loading}
              ></button>
            </div>
          </div>
        </ScrollView>
      </Popup>
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />

      <div className="d-block d-sm-block d-md-none p-0 m-0 mb-5">
        <motion.div
          initial={{ y: "50%", opacity: 0, scale: 0.5 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: "50%", transition: { duration: 0.8 } }}
          transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
          className="card card-monto"
        >
          <div className="container">
            <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
            <h2 className="text-light-blue text-end">
              {amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h2>
          </div>
        </motion.div>
      </div>

      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="row"
      >
        <div className="col-3">
          <div className="d-none d-sm-none d-md-block p-0 m-0 ">
            <div className="card card-monto">
              <div className="container">
                <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
                <h2 className="text-light-blue text-end">
                  {amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="text-center">
            <h3 className="sol1-tittle">Solicitud de préstamo</h3>
            <p>Información extra</p>
          </div>
        </div>
        <div className="col-3"></div>
      </motion.div>

      {/* STEPS */}
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-flex justify-content-center"
      >
        <img src={esfera_logo} alt="PRADO'S" className="img-fluid" width="60" />
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="text-center mt-3    "
      >
        <h6 className="txt-blue-strong2">SUBE TUS DOCUMENTOS </h6>
      </motion.div>
      {/* CARDS */}
      <div className="d-flex justify-content-center mt-4 container">
        <motion.div
          initial={{ y: "50%", opacity: 0, scale: 0.5 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: "50%", transition: { duration: 0.8 } }}
          transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
          className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body"
        >
          <div className="container">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                {data && data.map((item) => {
                  return (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                      <UploadBox
                        key={item.fileKey}
                        image={item.image}
                        descripton={item.description}
                        fileSetter={item.setter}
                        fileKey={item.fileKey}
                        callback={isComplete}
                      />
                    </div>
                  );
                })}
                <div className="col-12 col-sm-12 col-md-12 col-lg-2"></div>
              </div>
              <div className="d-flex justify-content-center mt-2">
                <div className="form-check">
                  <input
                    className={
                      formik.errors.attached1
                        ? "form-check-input checkbox-terms  is-invalid"
                        : "form-check-input checkbox-terms"
                    }
                    type="checkbox"
                    id="attached1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label
                    className="form-check-label text-check"
                    htmlFor="flexCheckDefault"
                  >
                    Certifico que los datos proporcionados son verídicos y que
                    actúo en nombre propio al solicitar este crédito
                  </label>
                  {formik.errors.attached1 && (
                    <div className="invalid-feedback">
                      Debes certificar la veracidad de los documentos
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                  <div className="mb-3">
                    <p>
                      Probenso Group, Sociedad Anónima, Sociedad Financiera de
                      Objeto Multipe, Entidad No Regulada (E.N.R), con domicilio
                      en Ures Sur 137, Colonia Centro, Tepic, 63000, Nayarit.
                      México es el responsable de sus datos personales.
                      Utilizaremos tus datos personales para los siguientes
                      indispensables.
                    </p>
                    <br />
                    <p>
                      (i) Crear tu cuenta de usuario en la plataforma alojada en
                      httos://percapita.mx (ii) Identificarte como usuario de la
                      plataforma alojada en httos://percapita.mx (iii) Generar
                      el expediente de solicitud de tu crédito (iv) Consultar tu
                      historial y capacidad crediticia; (v) Decidir si se
                      aprueba o rechaza tu solicitud de crédito, y (vi) Firmar
                      los contratos de crédito que sean aprobados. Para conocer
                      el Aviso de Privacidad integral, por favor da click aquí.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
              </div>
              <hr className="mt-4 mb-4" />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  {/* <div className="d-flex justify-content-center mt-5 mb-4">
                    <a
                      href="/solicitud/personal/11"
                      className="btn btn-shadow-2"
                    >
                      <img
                        src={flechai}
                        alt="PRADO'S"
                        className="img-fluid mrirht"
                        width="10"
                      />
                      Regresar
                    </a>
                  </div> */}
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center mt-5 mb-4">
                    <button disabled={!formik.isValid} type="submit" className="btn btn-shadow-2">
                      Continuar
                      <img
                        src={flechad}
                        alt="PRADO'S"
                        className="img-fluid mleft"
                        width="10"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </motion.div>
      </div>
      <Backdrop
        sx={{ color: "#173A5E", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid  img-client"
          width="300"
        />
        {/* <Skeleton variant="circular" width={15} height={15} sx={{ bgcolor: 'white' }} animation={'wave'}/>
<Skeleton variant="circular" width={14} height={14} sx={{ bgcolor: 'white' }}/>
<Skeleton variant="circular" width={13} height={13} sx={{ bgcolor: 'white' }}/>
<Skeleton variant="circular" width={12} height={12} sx={{ bgcolor: 'white' }}/> */}
        <CircularProgress color="primary" size={80} />
      </Backdrop>
    </motion.div>
  );
}
